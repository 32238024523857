import React, { useState, useTransition } from "react";
import { Row, Col, Table, Pagination, Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonToggle,
  Input,
} from "reactstrap";
import { v4 as uuid } from "uuid";

import { TextareaAutosize } from "@material-ui/core";
import { blue } from "../../Globals/colors";
// import "./Products.scss";

import Select from "react-select";
import { useTranslation } from "react-i18next";
import { addProduct } from "../../../api/products/products";
import { getCategoriesList } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

const AddBookModal = ({ toggle }) => {
  const [path, setPath] = useState(null);
  const [imgPaths, setImgPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState([]);
  var o = [];
  var c = 0;
  var [count, setCount] = useState([]);
  var [isDisabled, setIsDisabled] = useState(true);
  var files = [];

  const [prodImages, setProdImages] = useState([]);
  const [prodCategories, setProdCategories] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [sku, setSku] = useState("");
  const [author, setAuthor] = useState("");
  const [qty, setQty] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  // modal types
  const [modalType, setModalType] = useState("addProduct");
  const [productUrl, setProductUrl] = useState("");

  const [cover, setCover] = useState("");

  const [type, setType] = useState("");
  const [book, setBook] = useState("");

  const [catNames, setCatNames] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [cat, setCat] = useState({ value: "", label: "Select..." });

  const [showDeleteText, setShowDeleteText] = useState(false);

  const [isDisabledDelivery, setIsDisabledDelivery] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);

  //using s3 bucket
  const onFileChange = async (e) => {
    setLoading(true);
    const files = e.target.files;
    for (let file of files) {
      try {
        const fileKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
        const result = await Storage.put(fileKey, file, {
          contentType: "image/jpeg", // contentType is optional
        });
        fetchImageUrl(result);
      } catch (error) {
        setLoading(false);
        
      }
    }
    setLoading(false);

    for (let i = 0; i < files.length; i++) {
      const filePath = URL.createObjectURL(files[i]);
      setImgPaths((imgPaths) => [...imgPaths, filePath]);
    }
  };

  const fetchImageUrl = (key) => {
    const Url = `${process.env.REACT_APP_S3_URL}/public/${key.key}`;
    setProdImages((prevArr) => [...prevArr, Url]);
  };

  const onBookUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const fileKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
    try {
      await Storage.put(fileKey, file).then((res) => {
        setBook(res.key);
        setLoading(false);
      });
    } catch (error) {
      
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getCategories("");
    getMethods();
  }, []);

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            const deliveryArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
              deliveryArray.push(item._id);
            });
            setDeliveryMethod(deliveryArray);
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const getCategories = (id) => {
    if (id === "") {
      id = "null";
    }

    getCategoriesList(id)
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabled(false);
            setDisableSelect(true);
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const SaveProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (
      prodImages.length < 1 ||
      title === "" ||
      price === "" ||
      author === "" ||
      (type === "printBook" && deliveryMethod.length === 0) ||
      // qty === "" ||
      type === "" ||
      // shortDescription === "" ||
      longDescription === "" ||
      cover === "" ||
      (type !== "" && type !== "printBook" && book === "") ||
      (type === "printBook" && qty === "") ||
      prodCategories.length < 1
    ) {
      setError(`(*) ${t("fields are mandatory")}.`);
      setLoading(false);
    } else {
      // this is shifting cover image to first index
      let data = prodImages;
      data = data.filter((item) => item !== cover);
      data.unshift(cover);

      const productBody = {
        // Images: prodImages,
        Images: data,
        Name: title,
        Price: parseInt(price),
        Author: author,
        // Qty: parseInt(qty),
        BookType: type,
        // Book: book,
        ShortDescription: shortDescription,
        LongDescription: longDescription,
        StoreId: localStorage.getItem("uid"),
        // deliveryMethod: deliveryMethod,
        CategoryId: prodCategories,
        productType: "book",
      };
      if (type == "eBook") {
        productBody.Book = book;
      }
      if (type === "printBook") {
        productBody.deliveryMethod = deliveryMethod;
      }
      if (type == "printBook") {
        productBody.Qty = parseInt(qty);
      }

      addProduct(productBody)
        .then((res) => {
          setProductUrl(
            `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`
          );
          // update product with the url
          updateProduct(res.data.data.insertedId, {
            url: `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`,
          })
            .then((res) => {
              setLoading(false);
              toggle();
              setModalType("productCreated");
            })
            .catch((err) => {
              setLoading(false);
              
            });
        })
        .catch((err) => {
          
          setLoading(false);
          setError(`${t("*Something went wrong")}.`);
        });
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    setLoading(true);

    try {
      await Storage.remove(file).then((res) => {
        
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      
    }
  };

  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">
            {t("Product images")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </Col>
        </Row>
        {/* <button
          onClick={async () => {
            let key = "Removed Apps.html";
            const res = await Storage.get(key, { download: true });
            // downloadBlob(res.Body);
            // 
          }}
        >
          get book
        </button>
        <button
          onClick={async () => {
            let key = "Removed Apps.html";
            const res = await Storage.remove(key);
            
          }}
        >
          delete book
        </button> */}

        <div className="d-flex py-3 pb-0">
          {prodImages[0] &&
            prodImages.map((path, index) => (
              <div className="d-flex flex-column">
                <img
                  alt=""
                  style={{ marginRight: "10px", marginBottom: "6px" }}
                  key={index}
                  src={path}
                  width={67}
                  height={67}
                  onClick={() => {
                    setCover("");
                    const newImages = prodImages.filter((img) => img != path);
                    setProdImages(newImages);
                    onDeleteImage(path);
                  }}
                  onMouseEnter={() => setShowDeleteText(true)}
                  onMouseLeave={() => setShowDeleteText(false)}
                />
                <input
                  type="radio"
                  value={cover}
                  checked={cover === path}
                  onChange={() => setCover(path)}
                />
              </div>
            ))}
          <input
            multiple
            style={{ display: "none" }}
            id="imageUpload"
            type="file"
            onChange={(e) => onFileChange(e)}
          />
          <label
            style={{ visibility: imgPaths.length >= 3 ? "hidden" : "initial" }}
            htmlFor="imageUpload"
            className="imgUploadLbl"
          >
            +
          </label>
        </div>
        {prodImages.length > 0 && (
          <i style={{ fontSize: "14px" }}>
            {t("choose cover")}
            <i style={{ color: "red", display: "inline" }}> *</i>
          </i>
        )}
        {prodImages.length > 0 && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              visibility: !showDeleteText ? "hidden" : "visible",
            }}
          >
            {`*${t("click to delete")}`}
          </p>
        )}

        <Row className="d-flex justify-content-between mt-1">
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              className="inputField"
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>

          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Price")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              className="inputField"
              onChange={(e) => setPrice(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>

        <Row className="d-flex justify-content-between mt-1">
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Book Type")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <Select
              options={[
                { label: "E-book", value: "eBook" },
                { label: "Print Book", value: "printBook" },
              ]}
              onChange={({ value }) => setType(value)}
            />
          </Col>

          {type === "eBook" && (
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Upload Book")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input type="file" onChange={(e) => onBookUpload(e)} />
            </Col>
          )}
        </Row>

        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Author")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              className="inputField"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Col>

          {type !== "eBook" && (
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Quantity")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                onChange={(e) => setQty(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Short description")}
            </label>

            <Input
              type="textarea"
              maxLength={600}
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Long Description")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <TextareaAutosize
              className="inputTxtarea"
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
              minRows={5}
            />
          </Col>
        </Row>

        <label className="inpLblTxt">{t("Categories")}</label>
        <br />

        {catNames.length > 0 &&
          catNames.map((item, index) => (
            <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
              {index !== 0 && "/ "}
              {item}
            </p>
          ))}

        {/* {count.map((i) => ( */}
        <div style={{ marginBottom: "1px" }}>
          <Select
            onChange={(e) => {
              setProdCategories([...prodCategories, e.value]);
              getCategories(e.value);
              setCatNames((catNames) => [...catNames, e.label]);
              setCat({ label: "Select...", value: e.value });
            }}
            value={cat}
            isDisabled={disableSelect}
            options={options}
            isSearchable
            placeholder="Select Category..."
          />
        </div>
        {catNames.length > 0 && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => {
                getCategories("");
                setCat({ value: "", label: "Select..." });
                setCatNames([]);
                setProdCategories([]);
                setDisableSelect(false);
                setIsDisabled(true);
              }}
            >
              <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
            </button>
          </div>
        )}
        {/* {type !== "eBook" && (
          <Col>
            <Row>
              <label className="inpLblTxt">Delivery Method</label>
            </Row>
            <Row>
              <Select
                isMulti
                isSearchable
                onChange={(e) => {
                  let ids = e.map((a) => a.value);
                  setDeliveryMethod(ids);
                }}
                isDisabled={isDisabledDelivery}
                options={deliveryOptions}
                placeholder="Select Delivery Methods"
              />
            </Row>
          </Col>
        )} */}
        {error && (
          <p style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</p>
        )}

        <Button
          onClick={(e) => SaveProduct(e)}
          disabled={isDisabled || loading || isDisabledDelivery}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              size="sm"
              style={{ color: "white", padding: "9px" }}
            />
          ) : (
            t("Save")
          )}
        </Button>
        {isDisabledDelivery && (
          <Row>
            <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
              {t(
                "Please create a delivery option before creating a payment link"
              )}
              *
            </p>
          </Row>
        )}
      </div>
    );
};

export default AddBookModal;
