import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TransactionDetailsModal = ({ item, toggle }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.transactionId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerPhone}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.shippingAddress ? item.shippingAddress : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Province")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0 .8rem" }}>
            {item.customerProvince ? item.customerProvince : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.totalPrice)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.status?.charAt(0).toUpperCase() +
              item.status?.slice(1).toLowerCase()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "left" }}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ordered Products")}
          </span>
        </Col>
        {item?.products?.map((prod, index) => {
          return (
            <Row key={index} style={{ justifyContent: "left" }}>
              <Col md={2}>
                <img
                  alt={""}
                  style={{ marginRight: "10px" }}
                  src={prod.image}
                  width={67}
                  height={67}
                />
              </Col>
              <Col md={6}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Name")}
                    </span>
                  </Col>
                  <Col>
                    <span>{prod.name}</span>
                  </Col>
                </Row>
                {
                !(item.products[0].productType === "event" || item.products[0].bookType === "eBook") && (
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Delivery Name")}
                      </span>
                    </Col>
                    <Col>
                      <span>{prod.deliveryZone.title}</span>
                    </Col>
                  </Row>
                )
              }

              </Col>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};

export default TransactionDetailsModal;
