import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { TextareaAutosize } from "@material-ui/core";
import Select from "react-select";
import {  AiFillDelete } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { addProduct } from "../../../api/products/products";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

export default function AddContentModal({ toggle }) {
    const [imgPaths, setImgPaths] = useState([]);
    const [province, setProvince] = useState("");
    const { t, i18n } = useTranslation();
    // const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    var o = [];
    var c = 0;
    var [count, setCount] = useState([]);
    var [isDisabled, setIsDisabled] = useState(true);
  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [prodImages, setProdImages] = useState([]);
    const [prodCategories, setProdCategories] = useState([]);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [sku, setSku] = useState("");
    const [qty, setQty] = useState("");
    const [description, setDescription] = useState("");
    const [cover, setCover] = useState("");
    const [brand, setBrand] = useState("");
    const [selectedOption, setSelectedOption] = useState('');
    const [requiredOptions, setRequiredOptions] = useState([
      { value: 'attributes', label: 'Attributes'},
      { value: 'quantity', label: 'Quantity' }
    ]);
    const [quantityAttr,setQuantityAttr]=useState('')
    const [option, setOption] = useState("");
    const [optionPrice, setOptionPrice] = useState(0);
  
    const [attributes, setAttributes] = useState("");
  
    const [catNames, setCatNames] = useState([]);
    const [disableSelect, setDisableSelect] = useState(false);
    const [cat, setCat] = useState({ value: "", label: "Select..." });
  
    const [showDeleteText, setShowDeleteText] = useState(false);
  
    // modal types
    const [modalType, setModalType] = useState("addProduct");
    const [productUrl, setProductUrl] = useState("");
  
    const [isDisabledDelivery, setIsDisabledDelivery] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
  
    //using s3 bucket
    const onFileChange = async (e) => {
      setLoading(true);
      const files = e.target.files;
      for (let file of files) {
        try {
          const fileKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
  
          const result = await Storage.put(fileKey, file, {
            contentType: "image/jpeg",
          });
          fetchImageUrl(result);
          setLoading(false);
        } catch (error) {
          
        }
      }
      for (let i = 0; i < files.length; i++) {
        const filePath = URL.createObjectURL(files[i]);
        setImgPaths((imgPaths) => [...imgPaths, filePath]);
      }
    };
  
    const fetchImageUrl = async (key) => {
      const Url = `${process.env.REACT_APP_S3_URL}/public/${key.key}`;
      setProdImages((oldArr) => [...oldArr, Url]);
    };
  
    React.useEffect(() => {
      getCategories("");
      getMethods();
    }, []);
  
    const getCategories = (id) => {
      if (id === "") {
        id = "null";
      }
  
      // const domain = localStorage.getItem("domain");
      const catDomain = "Shopping";
      getCategoriesListbyDomain(id, catDomain)
        .then((res) => {
          const { success, message, data } = res.data;
  
          if (success) {
            if (data.length === 0) {
              setDisableSelect(true);
              setIsDisabled(false);
            } else {
              data.map((item) => {
                o.push({ value: item._id, label: item.title });
              });
              setOptions(o);
              c++;
              setCount([...count, c]);
            }
  
            if (data.length === 1) {
              setAttributes(data[0].attributes);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    const getMethods = () => {
      getDeliveryMethods()
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setIsDisabledDelivery(true);
            } else {
              const tempArray = [];
              const deliveryArray = [];
              data.map((item) => {
                tempArray.push({ value: item._id, label: item.title });
                deliveryArray.push(item._id);
              });
              setDeliveryMethod(deliveryArray);
              setDeliveryOptions(tempArray);
              setIsDisabledDelivery(false);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    const SaveProduct = async (e) => {
      e.preventDefault();
      setError("");
      setLoading(true);
  
      if (
        title === "" ||
        prodImages.length < 1 ||
        description === "" ||
        deliveryMethod.length === 0 ||
        // sku === "" ||
        // brand === "" ||
        cover === "" ||
        // province === "" ||
        prodCategories.length < 1 ||
        price === ""
        // ||
        // qty === ""
      ) {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        // this is shifting cover image to first index
        let data = prodImages;
        data = data.filter((item) => item !== cover);
        data.unshift(cover);
  
        const productBody = {
          Name: title,
          Images: data,
          Description: description,
          deliveryMethod: deliveryMethod,
          SKU: sku,
          Brand: brand,
          StoreId: localStorage.getItem("uid"),
          CategoryId: prodCategories,
          Price: parseInt(price),
          Province: province,
          productType: "shopping",
        };
        if(selectedOption==="quantity"){
          productBody.stockType= "QUANTITY"
          productBody.Qty=quantity
        }
        else if(selectedOption==="attributes"){
          productBody.stockType= "ATTRIBUTES"
          productBody.attributes=attrs
        }
        
  
        addProduct(productBody)
          .then((res) => {
            
            setProductUrl(
              `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`
            );
            // update product with the url
            updateProduct(res.data.data.insertedId, {
              url: `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`,
            })
              .then((res) => {
                
                setModalType("productCreated");
                setLoading(false);
                toggle();
              })
              .catch((err) => {});
          })
          .catch((err) => {
            setLoading(false);
            setError(t("*Something went wrong."));
            
          });
      }
    };
  
    // custom attributes list
    const [attrs, setAttrs] = useState([]);
    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [quantity, setQuantity] = useState("");
    const [copied, setCopied] = useState(false);
  
    const onDeleteImage = async (file) => {
      file = file.split("public/")[1];
      
      setLoading(true);
  
      try {
        await Storage.remove(file).then((res) => {
          
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
        
      }
    };
  
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      
    };
  
    
      return (
        <div style={{ padding: "0px 30px" }}>
          <Row className="d-flex flex-column">
            <Col className="col-12 inpLblTxt">
              {t("Product images")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </Col>
          </Row>
          <div className="d-flex py-3 pb-0">
            {prodImages[0] &&
              prodImages.map((path, index) => (
                <div className="d-flex flex-column">
                  <img
                    alt=""
                    style={{
                      marginRight: "10px",
                      marginBottom: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCover("");
                      const newImages = prodImages.filter((img) => img !== path);
                      setProdImages(newImages);
                      onDeleteImage(path);
                    }}
                    onMouseEnter={() => setShowDeleteText(true)}
                    onMouseLeave={() => setShowDeleteText(false)}
                    key={index}
                    src={path}
                    width={67}
                    height={67}
                  />
                  <input
                    type="radio"
                    value={cover}
                    checked={cover === path}
                    onChange={() => setCover(path)}
                  />
                </div>
              ))}
  
            <input
              multiple
              style={{ display: "none" }}
              id="imageUpload"
              type="file"
              onChange={(e) => onFileChange(e)}
            />
            <label
              style={{
                visibility: prodImages.length >= 3 ? "hidden" : "initial",
              }}
              htmlFor="imageUpload"
              className="imgUploadLbl"
            >
              +
            </label>
          </div>
          {prodImages.length > 0 && (
            <i style={{ fontSize: "14px" }}>
              {t("choose cover")}
              <i style={{ color: "red", display: "inline" }}> *</i>
            </i>
          )}
          {prodImages.length > 0 && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              {t("*click to delete")}
            </p>
          )}
          <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Title")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id="Title"
              />
            </Col>
  
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Price")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          </Row>
          {/* <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">
                SKU<p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style= {{ width: "100%" }}
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                id="Title"
              />
            </Col>
  
            <Col className="col-6">
              <label className="inpLblTxt">
                Quantity<p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                id="Title"
                type="number"
              />
            </Col>
            <Col className="col-6">
              <label className="inpLblTxt">
                Province<p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <Select
                options={provinces}
                onChange={({ value }) => setProvince(value)}
              />
            </Col>
          </Row> */}
          <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">{t("Brand")}</label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                id="Title"
              />
            </Col>
          </Row>
          {/* 
          {attributes &&
            !isDisabled &&
            attributes.map((item) => (
              <p>{`Title: ${item.title}, Type: ${item.inputType}`}</p>
            ))} */}
          <Row style={{ marginTop: "2%" }}>
            <Col md={12}>
              <label htmlFor="lastName" className="inpLblTxt">
                {t("Description")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <TextareaAutosize
                className="inputTxtarea"
                onChange={(e) => setDescription(e.target.value)}
                minRows={3}
              />
            </Col>
          </Row>
          {attrs.length > 0 &&
            attrs.map((item) => (
              <div
                style={{
                  backgroundColor: "lightgray",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <p
                  style={{
                    display: "inline",
                    marginBottom: "0",
                    marginLeft: "10px",
                  }}
                >
                  {item.size && `${t("size")}: ${item.size},`}{" "}
                  {item.color && `${t("color")}: ${item.color},`}{" "}
                  {item.quantity && `${t("qty")}: ${item.quantity}`}
                  {/* {item.quantity && `${t("qty")}: ${item.quantity}`} */}
                </p>
                <AiFillDelete
                  onClick={() => {
                    let filteredAttrs = attrs.filter((curr) => curr !== item);
                    setAttrs(filteredAttrs);
                  }}
                  style={{
                    color: "red",
                    cursor: "pointer",
                    marginRight: "6px",
                  }}
                  size={"1.2rem"}
                />
              </div>
            ))}
            <select className="form-select" id="dropdown" value={selectedOption} onChange={handleOptionChange} style={{
              width:"100%", backgroundImage:"none"}}>
              <option className="" value="">Select...</option>
              {requiredOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {t(option.label)}
                </option>
              ))}
          </select>
  
        {selectedOption === 'attributes'
         && 
          <div style={{gap:"7px", backgroundColor: "#f0f0f0",marginTop: "12px",
          paddingBottom: "10px" }}>
            <div style={{ width: "90%",margin:"auto"}}>
            <Row>
            <Col className="col-6">
              <label className="inpLblTxt">{t("Size")}</label>
              <input
                style={{ width: "100%" }}
                value={size}
                onChange={(e) => setSize(e.target.value)}
                className="inputField"
                id="Title"
                type="text"
              />
            </Col>
           
            <Col className="col-6">
              <label className="inpLblTxt">{t("Color")}</label>
              <p style={{ color: "red", display: "inline" }}> *</p>
              <input
                style={{ width: "100%" }}
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="inputField"
                id="Title"
                type="text"
              />
            </Col>
            </Row>
            <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">{t("Quantity")}</label>
              <p style={{ color: "red", display: "inline" }}> *</p>
              <input
                style={{ width: "100%" }}
                value={quantityAttr}
                onChange={(e) => setQuantityAttr(e.target.value)}
                className="inputField"
                id="Title"
                type="number"
              />
            </Col>
            <Col className="text-end mt-4">
              <button
                disabled={!size || !quantityAttr || !color}
                onClick={() => {
                  setAttrs((prevData) => [
                    ...prevData,
                    { size,color:color,quantity:quantityAttr }
                  ]);
                  
                  setSize(0);
                  setColor("");
                  setQuantityAttr(0);
                }}
              >
                {t("Add")}
              </button>
            </Col>
            </Row>
            </div>
  
            
            </div>
            }
            {selectedOption === 'quantity'
         && 
          // <div style={{display:"flex",gap:"7px", backgroundColor: "#f0f0f0",marginTop: "12px",
          // paddingBottom: "10px" }}>
         
            <Col className="col-12">
              <label style={{marginLeft:"21px"}} className="inpLblTxt">{t("Quantity")}</label>
              <input
                style={{ width: "90%",display:"flex",margin:"auto" }}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="inputField"
                id="Title"
                type="number"
              />
  
              {/* <Col className="text-center  mt-4">
                <button
                  
                  onClick={() => {
                    setAttrs((prevData) => [
                      ...prevData,
                      { quantity:quantity }
                    ]);
  
                    setQuantity(e.target.value);
                  }}
                >
                  {t("Add")}
                </button>
              </Col> */}
            </Col>
          
            // </div>
            }
          {/* <Row
            className="d-flex justify-content-between pb-3"
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <Col className="col-6">
              <label className="inpLblTxt">{t("Size")}</label>
              <input
                value={size}
                style={{ width: "100%" }}
                onChange={(e) => setSize(e.target.value)}
                className="inputField"
                id="Title"
              />
            </Col>
  
            <Col className="col-6">
              <label className="inpLblTxt">{t("Color")}</label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                id="Title"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </Col>
            <Col className="col-6">
              <label className="inpLblTxt">{t("Quantity")}</label>
              <input
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="inputField"
                style={{ width: "100%" }}
                id="Title"
              />
            </Col>
  
            <Col className="col-6 d-flex justify-content-end mt-4">
              <button
                disabled={!quantity}
                onClick={() => {
                  setAttrs((prevData) => [
                    ...prevData,
                    { size, color, quantity },
                  ]);
                  setSize("");
                  setColor("");
                  setQuantity("");
                }}
              >
                {t("Add")}
              </button>
            </Col>
          </Row> */}
          <label className="inpLblTxt">{t("Categories")}</label>
          <br />
          {catNames.length > 0 &&
            catNames.map((item, index) => (
              <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
                {index !== 0 && "/ "}
                {item}
              </p>
            ))}
          {/* {count.map((i) => ( */}
          <div style={{ marginBottom: "1px" }}>
            <Select
              onChange={(e) => {
                setProdCategories([...prodCategories, e.value]);
                getCategories(e.value);
                setCatNames((catNames) => [...catNames, e.label]);
                setCat({ label: "Select...", value: e.value });
              }}
              value={cat}
              isDisabled={disableSelect}
              options={options}
              isSearchable
              placeholder="Select Category..."
            />
          </div>
          {catNames.length > 0 && (
            <div style={{ width: "100%", textAlign: "end" }}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() => {
                  getCategories("");
                  setCat({ value: "", label: "Select..." });
                  setCatNames([]);
                  setProdCategories([]);
                  setDisableSelect(false);
                  setIsDisabled(true);
                }}
              >
                <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
              </button>
            </div>
          )}
          {/* <Col>
            <Row>
              <label className="inpLblTxt">Delivery Method</label>
            </Row>
            <Row>
              <Select
                isMulti
                isSearchable
                onChange={(e) => {
                  let ids = e.map((a) => a.value);
                  setDeliveryMethod(ids);
                }}
                isDisabled={isDisabledDelivery}
                options={deliveryOptions}
                placeholder="Select Delivery Methods"
              />
            </Row>
          </Col> */}
          {error && (
            <p style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</p>
          )}
          <Button
            onClick={(e) => SaveProduct(e)}
            disabled={isDisabled || loading || isDisabledDelivery}
            style={{
              margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Save")
            )}
          </Button>
          {isDisabledDelivery && (
            <Row>
              <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
                {t(
                  "Please create a delivery option before creating a payment link*"
                )}
              </p>
            </Row>
          )}
        </div>
      );
   
  
}


