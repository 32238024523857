import React, { useState } from "react";
import { Row, Col, Table, Pagination, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonToggle,
} from "reactstrap";
import { AiFillCloseCircle, AiOutlineCloudDownload } from "react-icons/ai";

import { blue } from "../../Globals/colors";
// import "./Products.scss";
import AddBookModal from "./AddBookModal";
import BookDetailModal from "./BookDetailModal";
import Pages from "../../Components/Pagination/Pagination";
import noDataFound from "../../Assets/images/nodata.png";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import { deleteProduct,getProducts } from "../../../api/products/products";
import DeleteModal from "../Categories/DeleteModalToggle";

export default function Products() {
  const [tableData, setTableData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [currItem, setCurrItem] = useState(null);
  const [show, setShow] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [dobouncedSearchName] = useDebounce(searchName, 1000);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t, i18n } = useTranslation();

  function handleShow() {
    setShow(true);
  }
  // delte modal related
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const [deleteProductId, setDeleteProductId] = useState("");

  const [limit, setLimit] = useState(10);
  // pagination
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);

  const addToggle = () => {
    setAddModal(!addModal);
    loadProducts();
  };

  React.useEffect(() => {
    loadProducts();
  }, [startDate, endDate, activePage, limit, dobouncedSearchName]);

  const loadProducts = () => {
    const sid = localStorage.getItem("uid");
    setLoading(true);

    // this gets data
    getProducts(limit, activePage, startDate, endDate, searchName, sid)
      .then((res) => {
        setTableData(res.data.data);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((error) => {
        
        setLoading(false);
      });
  };

  const downloadBook = (signedURL) => {
    const a = document.createElement("a");
    a.href = signedURL;
    a.download = "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(signedURL);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  return (
    <div>
      {/* header */}
      <div
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={4}
            lg={4}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Books")}
              </span>
            </div>
          </Col>
          {/* search by name */}
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Search by Name")}
            </span>
            <input
              type="search"
              class="form-control "
              placeholder={t("Search")}
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <Button
              style={{
                backgroundColor: blue,
                border: "none",
                marginTop: "24px",
              }}
              type="button"
              onClick={() => setAddModal(true)}
            >
              {t("Add")}
            </Button>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          {" "}
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Title")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Image")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Price")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("QTY")}
                </th>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("Description")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Author")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Type")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setCurrItem(item);
                      toggle();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>{item.Name}</td>
                    <td style={{ padding: "20px" }}>
                      <img
                        alt=""
                        src={item.Images[0]}
                        style={{ height: "45px", width: "45px" }}
                      />
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.Price)} AKZ
                    </td>

                    <td style={{ padding: "20px" }}>
                      {item.Qty ? item.Qty : "NaN"}
                    </td>
                    <td style={{ padding: "20px", textAlign: "center" }}>
                      {item.ShortDescription ? item.ShortDescription : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>{item.Author}</td>
                    <td style={{ padding: "20px" }}>
                      {item.BookType === "printBook"
                        ? "Print Book"
                        : item.BookType === "eBook"
                        ? "E-Book"
                        : item.BookType}
                    </td>
                    {item.BookType === "eBook" ? (
                      <td style={{ padding: "20px" }}>
                        <AiOutlineCloudDownload
                          onClick={async (e) => {
                            e.stopPropagation();
                            const signedURL = await Storage.get(item.Book);
                            downloadBook(signedURL);
                          }}
                          style={{
                            color: "#172B4D",
                            cursor: "pointer",
                          }}
                          size={"1.5rem"}
                        />
                      </td>
                    ) : (
                      <td style={{ padding: "20px" }}>...</td>
                    )}
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteProductId(item._id);
                        toggleDeleteModal();
                      }}
                      style={{
                        padding: "20px",
                        textDecoration: "underline",
                        color: "red",
                      }}
                    >
                      {t("delete")}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div className="noDataContainer">
                      <img alt="no data" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            marginTop: "1px",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </>
      )}
      {/* delete product modal */}
      <Modal size="sm" centered isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          {t("Delete Product")}?
        </ModalHeader>
        <ModalBody>
          <DeleteModal
            id={deleteProductId}
            toggle={toggleDeleteModal}
            loadProducts={loadProducts}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* product detail modal */}
      <Modal isOpen={modal} toggle={toggle}>
        <AiFillCloseCircle
          onClick={toggle}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />
        <ModalHeader toggle={toggle}>{t("Product Details")}</ModalHeader>
        <ModalBody>
          <BookDetailModal
            item={currItem}
            loadProducts={loadProducts}
            toggle={toggle}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* add book modal */}
      <Modal isOpen={addModal} toggle={addToggle}>
        <ModalBody>
          <div className="header-addProduct">
            <h4>{t("Add Product")}</h4>
            <AiFillCloseCircle
              onClick={addToggle}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "red",
                cursor: "pointer",
              }}
              size={"1.5rem"}
            />
          </div>
          <hr />
          <AddBookModal toggle={addToggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
