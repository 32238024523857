import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { TextareaAutosize } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getCategoriesList } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

const ProductDetailModal = ({ toggle, item, loadProducts }) => {
  // 
  const [path, setPath] = useState(null);
  const [imgPaths, setImgPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  // const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  var o = [];
  var c = 0;
  var [count, setCount] = useState([]);
  var [isDisabled, setIsDisabled] = useState(true);
  var files = [];

  const [prodImages, setProdImages] = useState([]);
  const [prodCategories, setProdCategories] = useState([]);
  const [title, setTitle] = useState(item.Name);
  const [price, setPrice] = useState(item.Price);
  const [sku, setSku] = useState(item.SKU);
  const [author, setAuthor] = useState(item.Author);
  const [qty, setQty] = useState(item.Qty);
  const [description, setDescription] = useState(item.Description);
  // modal types
  const [modalType, setModalType] = useState("addProduct");
  const [productUrl, setProductUrl] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState("");
  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [selectDeliveryValues, setSelectDeliveryValues] = useState([]);

  const [error, setError] = useState("");

  React.useEffect(() => {
    getCategories("");
    getMethods();
    setDeliveryValues();
  }, []);

  const setDeliveryValues = () => {
    const tempArray = [];

    item.deliveryData.map((item) => {
      tempArray.push({ value: item._id, label: item.title });
    });

    setSelectDeliveryValues(tempArray);
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
            });
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const getCategories = (id) => {
    if (id === "") {
      id = "null";
    }

    getCategoriesList()
      .then((res) => {
        const { success, message, data } = res.data;
        // 
        if (success) {
          // 
          if (data.length === 0) {
            setIsDisabled(false);
            // 
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
            // 
          }
        }
      })
      .catch((ex) => {
        // alert('error');
        
      });
  };

  // checks if olderImages and images are same or not
  const checkImgArrays = () => {
    const oldArr = olderImages.sort();
    const newArr = images.sort();

    return (
      Array.isArray(oldArr) &&
      Array.isArray(newArr) &&
      oldArr.length === newArr.length &&
      oldArr.every((item, index) => item === newArr[index])
    );
  };

  const checkDelivery = () => {
    if (deliveryMethod.length > 0) {
      const oldDelivery = item.deliveryMethod?.sort();
      const newDelivery = deliveryMethod.sort();

      return (
        Array.isArray(oldDelivery) &&
        Array.isArray(newDelivery) &&
        oldDelivery.length === newDelivery.length &&
        oldDelivery.every((item, index) => item === newDelivery[index])
      );
    } else return true;
  };

  const updateProductMethod = async (e) => {
    setLoading(true);
    setError("");
    if (
      title === item.Name &&
      price === item.Price &&
      sku === item.SKU &&
      qty === item.Qty &&
      description === item.Description &&
      checkDelivery() &&
      checkImgArrays()
    ) {
      setError(t("*Change any field to update record."));
      setLoading(false);
    } else if (images.length < 1) {
      setError(t("*Add atleast one image"));
      setLoading(false);
    } else {
      let body = {};
      if (title !== item.Name) {
        body.Name = title;
      }
      if (price !== item.Price) {
        body.Price = price;
      }

      if (sku !== item.SKU) {
        body.SKU = sku;
      }
      if (qty !== item.Qty) {
        body.Qty = qty;
      }
      if (description !== item.Description) {
        body.Description = description;
      }
      if (!checkImgArrays()) {
        body.Images = images;
      }
      if (!checkDelivery()) {
        body.deliveryMethod = deliveryMethod;
      }

      // since categories are not handled here so sending as it is
      body.CategoryId = item.CategoryId;
      updateProduct(item._id, body)
        .then((res) => {
          setLoading(false);
          toggle();
          loadProducts();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };

  // older images to compare b4 updating
  const [olderImages] = useState(item.Images);
  const [images, setImages] = useState(item.Images);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [copied, setCopied] = useState(false);

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    // setLoading(true)

    try {
      await Storage.remove(file).then((res) => {
        
        // setLoading(false)
      });
    } catch (e) {
      // setLoading(false)
      
    }
  };

  //using s3 bucket
  const onFileChange = async (e) => {
    setLoading(true);

    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImages((oldUrls) => [
            ...oldUrls,
            `${process.env.REACT_APP_S3_URL}/public/${res.key}`,
          ]);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">{t("Product images")}</Col>
        </Row>

        <Row className="align-items-start flex-row">
          <Col style={{ display: "flex", flexDirection: "row" }}>
            {images.map((item) => (
              <img
                alt={""}
                src={item}
                width={"65px"}
                height={"65px"}
                style={{ marginRight: "5px" }}
                onClick={() => {
                  const newImages = images.filter((img) => img != item);
                  setImages(newImages);
                  onDeleteImage(item);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
            ))}
            {images.length < 3 && (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            )}
          </Col>
          {images.length > 0 && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              {t("*click to delete")}
            </p>
          )}
        </Row>

        {/* {path && <img src={path} width="400px" />}

        <div className="d-flex py-3">
          {imgPaths[0] &&
            imgPaths.map((path, index) => (
              <div className="d-flex flex-column">
                <img
                  style={{ marginRight: "10px" }}
                  key={index}
                  src={path}
                  width={67}
                  height={67}
                />
              </div>
            ))}
          <input
            style={{ display: "none" }}
            id="imageUpload"
            type="file"
            onChange={(e) => onFileChange(e)}
          />
          <label
            style={{ visibility: imgPaths.length >= 3 ? "hidden" : "initial" }}
            htmlFor="imageUpload"
            className="imgUploadLbl"
          >
            +
          </label>
        </div> */}
        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Title")}</label>
            <input
              className="inputField"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>

          <Col className="col-6">
            <label className="inpLblTxt">{t("Price")}</label>
            <input
              className="inputField"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          {" "}
          //TODO: Remove this
          <Col className="col-6">
            <label className="inpLblTxt">SKU</label>
            <input
              className="inputField"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            />
          </Col>
          <Col className="col-6">
            <label className="inpLblTxt">{t("Quantity")}</label>
            <input
              className="inputField"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Description")}
            </label>
            <TextareaAutosize
              className="inputTxtarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minRows={3}
            />
          </Col>
        </Row>

        {/* {count.map((i) => (
          <div style={{ marginBottom: "10px" }}>
            <Select
              // isDisabled={true}
              onChange={(e) => {
                setProdCategories([...prodCategories, e.value]);
                getCategories(e.value);
              }}
              options={options}
              // isClearable
              isSearchable
              placeholder="Select Category..."
            />
          </div>
        ))} */}

        {/* <Col>
          <Row>
            <label className="inpLblTxt">Delivery Method</label>
          </Row>
          <Row>
            <Select
              isMulti
              isSearchable
              onChange={(e) => {
                setSelectDeliveryValues(e);
                let ids = e.map((a) => a.value);
                setDeliveryMethod(ids);
              }}
              isDisabled={isDisabledDelivery}
              options={deliveryOptions}
              value={selectDeliveryValues}
              placeholder="Select Delivery Methods"
            />
          </Row>
        </Col> */}

        {error && (
          <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
            {t(`${error}`)}
          </p>
        )}
        <Button
          onClick={(e) => updateProductMethod(e)}
          disabled={loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
  
};

export default ProductDetailModal;
