import React from "react";
import { Spinner, Container, Col, Row, Button } from "react-bootstrap";
import { PinInput } from "react-input-pin-code";
import ForgotPinModal from "./ForgotPinModal";
import { useTranslation } from "react-i18next";
import { changePin } from "../../../api/sellers/sellers";

const Security = () => {
  const { t, i18n } = useTranslation();
  const [currentPinValues, setCurrentPinValues] = React.useState([
    "",
    "",
    "",
    "",
  ]);
  const [currentPin, setCurrentPin] = React.useState(undefined);
  const [newPinValues, setNewPinValues] = React.useState(["", "", "", ""]);
  const [newPin, setNewPin] = React.useState(undefined);
  const [confirmPinValues, setConfirmPinValues] = React.useState([
    "",
    "",
    "",
    "",
  ]);
  const [confirmPin, setConfirmPin] = React.useState(undefined);
  const [changePinLoading, setChangePinLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(undefined);
  const [successMessage, setSuccessMessage] = React.useState(undefined);

  const [forgotPinModal, setForgotPinModal] = React.useState(false);

  const toggle = () => {
    setForgotPinModal(!forgotPinModal);
  };

  const handlePinCodeOnChange = (values, setPin) => {
    let value = values.join("");

    if (value === "") {
      value = undefined;
    }

    setPin(value);
  };

  const handleConfirmOnChange = (newPINCode, confirm) => {
    if (JSON.stringify(newPINCode) !== JSON.stringify(confirm)) {
      setErrorMsg("Pin Code does not match!");
    } else {
      setErrorMsg(undefined);
    }
  };

  const changePinCode = () => {
    const sid = localStorage.getItem("uid");
    if (
      currentPin === undefined ||
      currentPin === undefined ||
      confirmPin === undefined
    ) {
      setErrorMsg("All Fields are required");
      return;
    }

    if (confirmPin !== newPin) {
      setErrorMsg("Pin Code does not match!");
      return;
    }

    setChangePinLoading(true);
    changePin({
      sellerId: sid,
      oldPin: parseInt(currentPin),
      newPin: parseInt(newPin),
    })
      .then((res) => {
        setChangePinLoading(false);
        if (res.data.status === 200) {
          setSuccessMessage("Pin Code changed Successfully!");
        } else {
          setErrorMsg(res.data.message);
        }
        
      })
      .catch((error) => {
        
        setErrorMsg("Error while changing PIN");
        setChangePinLoading(false);
      });
  };

  return (
    <>
      <Container fluid>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={8}>
            <h5>{t("Account Security")}</h5>
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={8}>
            <h6>{t("Change PIN")}</h6>
          </Col>
        </Row>
        <Row sm={8}>
          <Col sm={3}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Current PIN")}
            </label>
            <PinInput
              mask
              length={4}
              values={currentPinValues}
              validBorderColor={"rgb(204,204,204)"}
              onChange={(value, index, values) => {
                setCurrentPinValues(values);
                handlePinCodeOnChange(values, setCurrentPin);
              }}
            />
          </Col>
        </Row>
        <Row sm={8}>
          <Col sm={3}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("New PIN")}
            </label>
            <PinInput
              mask
              length={4}
              values={newPinValues}
              validBorderColor={"rgb(204,204,204)"}
              onChange={(value, index, values) => {
                setNewPinValues(values);
                handlePinCodeOnChange(values, setNewPin);
              }}
            />
          </Col>
        </Row>
        <Row sm={8}>
          <Col sm={3}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Confirm PIN")}
            </label>
            <PinInput
              mask
              length={4}
              values={confirmPinValues}
              validBorderColor={"rgb(204,204,204)"}
              onChange={(value, index, values) => {
                setConfirmPinValues(values);
                handlePinCodeOnChange(values, setConfirmPin);
                handleConfirmOnChange(newPinValues, values);
              }}
            />
          </Col>
        </Row>
        <Row sm={8}>
          <Col sm={3}>
            {errorMsg && (
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {t(`${errorMsg}`)}
              </p>
            )}
          </Col>
        </Row>

        <Row sm={8}>
          <Col sm={3}>
            {successMessage && (
              <p
                style={{
                  color: "green",
                  fontSize: "13px",
                }}
              >
                {t(`${successMessage}`)}
              </p>
            )}
          </Col>
        </Row>

        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <Button
              onClick={(e) => changePinCode(e)}
              disabled={changePinLoading}
              style={{
                // margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {changePinLoading ? (
                <Spinner
                  animation="border"
                  style={{ color: "white", padding: "9px" }}
                  size="sm"
                />
              ) : (
                t("Update")
              )}
            </Button>
            <Button variant="link" onClick={(e) => toggle()}>
              {t("Forgot PIN")}
            </Button>
          </Col>
        </Row>
      </Container>
      {forgotPinModal === true && (
        <ForgotPinModal modal={forgotPinModal} toggle={toggle} />
      )}
    </>
  );
};

export default Security;
