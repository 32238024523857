import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../Helpers/helper";
import TransactionInvoice from "./TransactionInvoice";
import { updateTransaction } from "../../../api/orders/orders";
import { Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const QrCatalogDetails = ({ item, toggle }) => {
  const [deliveryCode, setDeliveryCode] = useState("");
  const [status, setStatus] = useState(item.status);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();

  const updateOrder = () => {
    const body = {};

    if (deliveryCode !== "") {
      body.deliveryCode = deliveryCode;
    }

    if (status !== item.status) {
      body.status = status;
    }

    setLoading(true);
    updateTransaction(item, body)
      .then((res) => {
        setLoading(false);
        
        if (res.data.success === false) {
          setError(res.data.message);
        } else {
          toggle(true);
          setError("");
        }
      })
      .catch((err) => {
        
        setLoading(false);
      });
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.transactionId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerDetail?.firstName} ${item.customerDetail?.surName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.amount)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {/* <UncontrolledDropdown style={{ width: "50%" }}>
              <DropdownToggle
                style={{
                  marginTop: "4px",
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("PENDING")}>Pending</DropdownItem>
                <DropdownItem onClick={() => setStatus("CONFIRM")}>Confirm</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Description")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.description}
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "left" }}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ordered Products")}
          </span>
        </Col>
        {item.products.map((prod, index) => {
          return (
            <Row key={index} style={{ justifyContent: "left" }}>
              <Col md={2}>
                <img
                  alt={""}
                  style={{ marginRight: "10px" }}
                  src={prod.image}
                  width={67}
                  height={67}
                />
              </Col>
              <Col md={6}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Name")}
                    </span>
                  </Col>
                  <Col>
                    <span>{prod.name}</span>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Quantity")}
                    </span>
                  </Col>
                  <Col>
                    <span>{prod.qty}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Row>
      <Row>
        <Col md={6}>
          <TransactionInvoice item={item} />
        </Col>
      </Row>
      {/* <Row >
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner animation="border" size="sm" style={{ color: "white", padding: "9px" }} />
            ) : (
              "Update"
            )}
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default QrCatalogDetails;
