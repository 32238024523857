import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateDeliveryZone } from "../../../api/deliveryZones/deliveryZones";

export default function UpdateZoneModal({ toggle, item }) {
    const [title, setTitle] = useState(item.title);
    const [status, setStatus] = useState(item.status);
    const [cost, setCost] = useState(item.price);
    const [est, setEst] = useState(item.est);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
  
    const getEst = (val) => {
      const retValues = [
        { value: "0", label: t("Same day") },
        { value: "1", label: `1 ${t("day")}` },
        { value: "2", label: `2 ${t("days")}` },
        { value: "3", label: `3 ${t("days")}` },
        { value: "4", label: `4 ${t("days")}` },
        { value: "5", label: `5 ${t("days")}` },
      ];
  
      return retValues[val];
    };
  
    const editZone = () => {
      setLoading(true);
      if (
        title !== item.title ||
        status !== item.status ||
        cost !== item.cost ||
        est !== item.est
      ) {
        if (title !== "") {
          updateDeliveryZone(item, {
            title: title,
            price: cost,
            est: est,
            status: status,
          })
            .then((res) => {
              
              toggle(true);
              setLoading(false);
            })
            .catch((res) => {
              
              setLoading(false);
            });
        } else {
          setError(t("(*) fields are mandatory."));
          setLoading(false);
        }
      } else {
        setError(t("Change Values to Update"));
        setLoading(false);
      }
    };
  
    return (
      <div>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t("Title")}
              className="inputField"
              id="Title"
            />
          </Col>
        </Row>
        <Row>
          <Col className="sm-col-12 md-col-6">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Price")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder="0.00 AKZ"
              className="inputField"
              id="Cost"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Delivery time (EST)")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <Select
              value={getEst(est)}
              options={[
                { value: "0", label: t("Same day") },
                { value: "1", label: `1 ${t("day")}` },
                { value: "2", label: `2 ${t("days")}` },
                { value: "3", label: `3 ${t("days")}` },
                { value: "4", label: `4 ${t("days")}` },
                { value: "5", label: `5 ${t("days")}` },
              ]}
              onChange={({ value }) => setEst(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Status")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0px",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status
                    ? status === "enable" ||
                      status === "Enable" ||
                      status === "Active" ||
                      status === "approved"
                      ? t("Active")
                      : status === "disable" ||
                        status === "Disable" ||
                        status === "Inactive" ||
                        status === "pending"
                      ? t("Inactive")
                      : status
                    : t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("approved")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("pending")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {error && (
          <span style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</span>
        )}
        <ButtonToggle
          onClick={editZone}
          disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "#fff", margin: "0px" }}>
            {loading ? <Spinner size="sm" animation="border" /> : t("Update")}{" "}
          </p>
        </ButtonToggle>
      </div>
    );
}

