/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6969246b-4e5e-4247-bcee-0927d895238c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_hPfH2v8FG",
    "aws_user_pools_web_client_id": "1dhffe0omf0r41ph0len05cfer",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bayqisellerstorage163356-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
