import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalLayout() {
    const { t, i18n } = useTranslation();
    <div>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Order Number")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>#5162</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Date/time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>26-Dec-2020</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Order Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{t("Complete")}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Full Name")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>Florencia</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>+8612616712</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Delivery Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            456 Rasheed Pararirie
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            disabled={true}
            style={{ fontSize: "0.8rem" }}
            type="checkbox"
            label="Check me out"
          />
        </Form.Group>
      </Row>
    </div>;
}

