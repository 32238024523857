import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonToggle
} from "reactstrap";
import Select from "react-select";
import "./Categories.scss";
import { Storage } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
// import { addCategory, getCategoriesListbyDomain, getCategoriesListbyName, getSellerData, getSignedInUserData } from "../../../api/services/sellerServices";
import {
  addCategory,
  getCategoriesListbyDomain,
} from "../../../api/categories/categories";
import {
  getSignedInUserData,
} from "../../../api/sellers/sellers";

export default function AddCategoryModal({ toggle }) {
    const [path, setPath] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const { t, i18n } = useTranslation();
  
    // all attributes data
    const [attrData, setAttrData] = useState([]);
    const onFileChange = async (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
  
      try {
        const file = e.target.files[0];
        const imageKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
        await Storage.put(imageKey, file).then((res) => {
          
          setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
        });
      } catch (error) {
        
      }
    };
  
    const [title, setTitle] = useState("");
    // categories related
    const o = [];
    const c = 0;
    const [count, setCount] = useState([]);
    const [options, setOptions] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [prodCategories, setProdCategories] = useState([]);
    const [currCatId, setCurrCatId] = useState(null);
  
    const [catNames, setCatNames] = useState([]);
    const [disableSelect, setDisableSelect] = useState(false);
    const [cat, setCat] = useState({ value: "", label: "Select..." });
  
    // cat api loading boolean
    const [creatingCategory, setCreatingCategory] = useState(false);
    // cat api error message
    const [apiError, setApiError] = useState("");
  
    const [sellerDomain, setSellerDomain] = useState(null);
  
    React.useEffect(() => {
      getCategories("");
    }, []);
  
    const getCategories = (id) => {
      const sellerDomain = localStorage.getItem("domain");
      
      
      if (id === "") {
        id = "null";
      }
      let domain = "";
      if (sellerDomain === "Book") {
        domain = "Library";
      } else if (
        sellerDomain == "Fast Food" ||
        sellerDomain == "Bar & Restaurant"
      ) {
        domain = "Food and Restaurant";
      } else if (sellerDomain == "Cantinas" || sellerDomain == "Supermarket") {
        domain = "Supermarket and Cantinas";
      } else if (sellerDomain == "Shopping") {
        domain = "Shopping";
      } else {
        domain = sellerDomain;
      }
  
      
       
  
      getCategoriesListbyDomain(id, domain)
        .then((res) => {
          const { success, message, data } = res.data;
          
          if (success) {
            if (data.length === 0) {
              setDisableSelect(true);
              setIsDisabled(false);
            } else {
              data.map((item) => {
                o.push({ value: item._id, label: item.title });
              });
              setOptions(o);
              c++;
              setCount([...count, c]);
              
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    React.useEffect(() => {
      getSignedInUserData(localStorage.getItem("uid"))
        .then((res) => setSellerDomain(res.data.data[0].businessCategory))
        .catch((err) => 
        {});
    });
  
    const deleteImage = async () => {
      await Storage.remove(imageUrl.split("public/")[1]);
      setPath(null);
      setImageUrl("");
    };
  
    const addCategoryMethod = () => {
      setApiError("");
      setCreatingCategory(true);
  
      if (imageUrl === "" || title === "") {
        setApiError(`(*) ${t("fields are mandatory")}.`);
        setCreatingCategory(false);
      } else {
        let body = {
          icon: imageUrl,
          title,
          parentId: currCatId,
          attributes: attrData,
          isApproved: "approved",
        };
  
        if (sellerDomain === "Book") {
          body.businessDomain = "Library";
        } else if (
          sellerDomain == "Fast Food" ||
          sellerDomain == "Bar & Restaurant"
        ) {
          body.businessDomain = "Food and Restaurant";
        } else if (sellerDomain == "Cantinas" || sellerDomain == "Supermarket") {
          body.businessDomain = "Supermarket and Cantinas";
        } else if (sellerDomain == "Shopping") {
          body.businessDomain = "Shopping";
        } else {
          body.businessDomain = sellerDomain;
        }
  
        
        addCategory(body)
          .then((res) => {
            setCreatingCategory(false);
            
            toggle(true);
          })
          .catch((err) => {
            setCreatingCategory(false);
            setApiError(err.message);
            
          });
      }
    };
    return (
      <>
        <Row>
          <Col className="col-4">
            {!path ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>
                    {t("Add Icon")}
                    <p
                      style={{
                        color: "red",
                        display: "inline",
                      }}
                    >
                      {" "}
                      *
                    </p>
                  </p>
                </label>
              </>
            ) : (
              <>
                <div
                  style={{
                    position: "relative",
                    border: "1px solid rgba(0, 0, 0, .2)",
                    borderRadius: "10px",
                  }}
                >
                  <CloseIcon
                    style={{
                      position: "absolute",
                      right: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      deleteImage();
                    }}
                  />
                  <img
                    alt=""
                    htmlFor="imgUpload"
                    src={imageUrl}
                    width={65}
                    height={65}
                    style={{ borderRadius: "8px" }}
                  />
                </div>
                {/* TODO: show an edit btn beside the img to edit the current uploaded image */}
              </>
            )}
          </Col>
          <Col>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "4px 8px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            {t("Choose parent category (if any)")}
          </label>
        </Row>
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {catNames.length > 0 &&
              catNames.map((item, index) => (
                <p
                  style={{
                    display: "inline",
                    color: "gray",
                    fontSize: "12px",
                    marginBottom: "0",
                  }}
                >
                  {index !== 0 && "/ "}
                  {item}
                </p>
              ))}
          </div>
          <div style={{ marginBottom: "1px" }}>
            <Select
              onChange={(e) => {
                setProdCategories([...prodCategories, e.value]);
                getCategories(e.value);
                setCurrCatId(e.value);
                setCatNames((catNames) => [...catNames, e.label]);
                setCat(e);
              }}
              value={cat}
              isDisabled={disableSelect}
              options={options}
              isSearchable
              placeholder="Select Category..."
            />
          </div>
          {catNames.length > 0 && (
            <div style={{ width: "100%", textAlign: "end" }}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() => {
                  getCategories("");
                  setCat({ value: "", label: "Select..." });
                  setCatNames([]);
                  setProdCategories([]);
                  setDisableSelect(false);
                  setIsDisabled(true);
                }}
              >
                <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
              </button>
            </div>
          )}
        </Row>
  
        {apiError && (
          <p style={{ color: "red", fontSize: "12px" }}>{t(`${apiError}`)}</p>
        )}
  
        <ButtonToggle
          onClick={addCategoryMethod}
          className="tglBtn"
          disabled={
            creatingCategory
            // || (!disableSelect && catNames.length > 0)
          }
        >
          <p style={{ color: "#fff", margin: "0px" }}>
            {creatingCategory ? (
              <Spinner animation="border" style={{ color: "#fff" }} size="sm" />
            ) : (
              t("Save")
            )}
          </p>
        </ButtonToggle>
      </>
    );
}


