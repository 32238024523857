import axios from "./axiosInterceptor";

export const deleteDeliveryZone = (currZoneId) =>
  axios.delete(`/delivery-zones/${currZoneId}`);

  export const updateDeliveryZone = (item, body) =>
  axios.put(`/delivery-zones/${item._id}`, body);



export const addDeliveryZone = (body) =>
  axios.post(`/delivery-zones`, body);

  export const getDeliveryZones = (limit, activePage) =>
  axios.get(
    `/delivery-zones?sellerId=${localStorage.getItem("uid")}&limit=${limit}&page=${activePage}`
  );


  export const getDeliveryMethods = () =>
  axios.get(
    `/delivery-zones?sellerId=${localStorage.getItem(
      "uid"
    )}&status=approved`
  );