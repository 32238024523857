import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ImagesLoader from "../../utils/ImageLoader";
import Register from "../Auth/Register";

export default function Header() {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const createAccount = () => {
      setOpen(true);
    };
    return (
      <div className="header">
        <div className="header-top">
          <div className="header-logo">
            <img src={`${ImagesLoader("logo")}`} height="80px" width="130px" />
          </div>
          <div
            className="header-nav"
            style={{ justifyContent: "flex-end", cursor: "default" }}
          >
            {/* <h6 onClick={createAccount}>Create Account</h6> */}
  
            {/* <button
              onClick={createAccount}
              style={{
                width: "150px",
                background: "#049EFF",
                borderColor: "#049EFF",
              }}
              className="btn btn-primary btn-block"
            >
              Create Account
            </button> */}
            {open && <Register open={open} setOpen={setOpen} />}
  
            {/* <Link to="/"> */}
            <button
              onClick={createAccount}
              style={{
                width: "150px",
                background: "#049EFF",
                borderColor: "#049EFF",
                cursor: "pointer",
              }}
              className="btn btn-primary btn-block"
            >
              {t("Signup/Signin")}
            </button>
            {/* <Link to="/login">
              <button
                style={{
                  // marginTop: "2%",
                  background: "#049EFF",
                  borderColor: "#049EFF",
                  // boxShadow: "0 1px 16px 0 rgba(0,0,0,0.3)",
                }}
                className="btn btn-primary btn-block"
              >
                Log in
              </button>
            </Link> */}
            {/* </Link> */}
          </div>
        </div>
      </div>
    );
}
