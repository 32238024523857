import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import StatsCard from "../Finances/StatsCard";
import {getTotalSales, getTotalOrders, getTotalPayTransactions, getSellerCaptiveBalance} from "../../../api/kpis/kpis"
import StatsCard from "../../Components/StatsCard/StatsCard";
import "./Stats.scss";
import LatestTransactions from "./LatestTransactions";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const [timePeriod, setTimePeriod] = React.useState("Today");
  const [loading, setLoading] = React.useState(true);
  const [cards, setCards] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const { t, i18n } = useTranslation();

  const totalSales = () => {
    try {
      return getTotalSales(startDate,
        endDate)
        .then((res) => {
          return {
            data: {
              message: t("Total Sale Balance"),
              amount: res.data.amount,
              currency: true,

            },
          };
        });
    } catch (e) {
      
    }
  };

  const totalOrders = () => {
    try {
      return getTotalOrders(startDate,
      endDate)
        .then((res) => {
          return {
            data: {
              message: t("Total Orders"),
              amount: res.data.totalRecord,
              currency: false,
            },
          };
        });
    } catch (e) {
      
    }
  };

  const totalPayTransactions = () => {
    try {
      return getTotalPayTransactions(startDate,
        endDate)
        .then((res) => {
          return {
            data: {
              message: t("Total Payment Transactions"),
              amount: res.data.totalRecord,
              currency: false,
            },
          };
        });
    } catch (e) {
      
    }
  };

  const sellerCaptiveBalance = () => {
    try {
      return getSellerCaptiveBalance(startDate,
        endDate)
        .then((res) => {
          return {
            data: {
              message: t("Captive Balance"),
              amount: res.data.captivegBalance,
              currency: true,
            },
          };
        });
    } catch (e) {
      
    }
  };

  React.useEffect(() => {
    Promise.all([
      totalSales(),
      totalPayTransactions(),
      totalOrders(),
      sellerCaptiveBalance(),
    ]).then((results) => {
      
      const temp = results.map((item) => {
        return {
          title: item.data.message,
          amount: item.data.totalRecord || item.data.amount,
          currency: item.data.currency,
        };
      });
      setCards(temp);
    });
  }, [startDate, endDate]);

  // if (loading) {
  //   return <span className="spinnerContainer">
  //     <Spinner animation="border" variant="primary" />
  //   </span>
  // }
  return (
    <div>
      <div className="financesHeader pt-2">
        <Row style={{ width: "100%", justifyContent: "end" }}>
          {/* <Col className="col-12 col-sm-6 col-md-3">
            <p className="rangeTitle">{t("Range")}</p>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">{t(`${timePeriod}`)}</span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setTimePeriod("Today")}>
                  {t("Today")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Week")}>
                  {t("Last Week")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Month")}>
                  {t("Last Month")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Year")}>
                  {t("Last Year")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col> */}
           <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {"End Date"}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={endDate}
              onChange={(e) => {
                
                setEndDate(e.target.value);
              }}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>
        </Row>
      </div>

      {/* Stats Cards */}
      <Col>
        <Row>
          <div className="mt-4">
            {cards.map((card) => (
              <StatsCard {...card} />
            ))}
          </div>
        </Row>
      </Col>
      <LatestTransactions />
    </div>
  );
};

export default Stats;
