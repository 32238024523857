import React, { useState } from "react";
import { ButtonToggle } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { deleteGenerateLink } from "../../../api/generateLInks/generateLinks";
import { useTranslation } from "react-i18next";

const DeleteLinkModal = ({ toggle, deleteLinkId }) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const deleteLink = () => {
    setLoading(true);
    deleteGenerateLink(deleteLinkId)
      .then((res) => {
        setLoading(false);
        toggle(true);
        
      })
      .catch((err) => {
        setLoading(false);
        
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonToggle
        onClick={deleteLink}
        disabled={loading ? true : false}
        style={{
          margin: "20px 0px",
          backgroundColor: "#E65B65",
          borderWidth: 0,
          marginRight: "10px",
        }}
      >
        {loading ? (
          <Spinner
            animation="border"
            style={{ color: "white", padding: "9px" }}
            size="sm"
          />
        ) : (
          t("Yes")
        )}
      </ButtonToggle>

      <ButtonToggle
        onClick={toggle}
        disabled={loading ? true : false}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
        }}
      >
        {t("No")}
      </ButtonToggle>
    </div>
  );
};

export default DeleteLinkModal;
