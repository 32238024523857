import React, { useState } from "react";
import NavBar from "../Components/NavBar/NavBar";
import SideDrawer from "../Components/SideDrawer/SideDrawer";
import "../Components/SideDrawer/SideDrawer.css";
import Landing from "../Pages/Landing/Landing";
import { useAppSelector } from "../Redux/hooks";

function HeaderWithDrawer({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const toggleDrawer = () => setIsOpen(!isOpen);

  const containUser = useAppSelector((state) => state.auth.isAuthenticated);

  if (!containUser) return <Landing />;
  return (
    <div>
      <NavBar toggleDrawer={toggleDrawer} />
      <SideDrawer isOpen={isOpen}>{children}</SideDrawer>
    </div>
  );
}

export default HeaderWithDrawer;
