import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import {
  addGenerateLink
} from "../../../api/generateLInks/generateLinks";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { useTranslation } from "react-i18next";

export default function AddContentModal({ toggle, updateData }) {
    const [path, setPath] = useState(null);
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [qty, setQty] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [type, setType] = useState("product");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
  
    const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
    const [deliveryMethod, setDeliveryMethod] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
  
    React.useEffect(() => {
      getMethods();
    }, []);
  
    //using s3 bucket
    const onFileChange = async (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
  
      try {
        await Storage.put(e.target.files[0].name, e.target.files[0]).then(
          (res) => {
            
            setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
          }
        );
      } catch (error) {
        
      }
    };
  
    const getMethods = () => {
      getDeliveryMethods()
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setIsDisabledDelivery(true);
            } else {
              const tempArray = [];
              const deliveryArray = [];
              data.map((item) => {
                tempArray.push({ value: item._id, label: item.title });
                deliveryArray.push(item._id);
              });
              setDeliveryMethod(deliveryArray);
              setDeliveryOptions(tempArray);
              setIsDisabledDelivery(false);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    // add link api
    const addLink = () => {
      setError("");
      setLoading(true);
      let body;
      const sellerId = localStorage.getItem("uid");
      if (
        // type === "" ||
        title === "" ||
        price === "" ||
        imageUrl === ""
        // (type === "product" && qty === "") ||
        // (type === "product" && deliveryMethod.length === 0
        // )
      ) {
        setError(t("*All fields are mandatory."));
        setLoading(false);
      } else {
        if (type === "product") {
          body = {
            type,
            name: title,
            price,
            Qty: qty,
            image: imageUrl,
            deliveryMethods: deliveryMethod,
            sellerId,
          };
        } else {
          body = {
            type,
            name: title,
            price,
            image: imageUrl,
            deliveryMethods: deliveryMethod,
            sellerId,
          };
        }
        // body = {
         
        //   name: title,
        //   price,
        //   image: imageUrl,
        //   deliveryMethods: deliveryMethod,
        //   sellerId,
        // };
        addGenerateLink(body)
          .then((res) => {
            setLoading(false);
            updateData();
            toggle();
            
          })
          .catch((error) => {
            setError(error.message);
            setLoading(false);
            
          });
      }
    };
  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">
            {t("Image")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </Col>
        </Row>
  
        {path && (
          <img
            alt=""
            src={path}
            width="80px"
            height="80px"
            style={{ borderRadius: "8px" }}
          />
        )}
        {!path && (
          <div className="d-flex py-3">
            <input
              style={{ display: "none" }}
              id="imageUpload"
              type="file"
              onChange={(e) => onFileChange(e)}
            />
            <label htmlFor="imageUpload" className="imgUploadLbl">
              +
            </label>
          </div>
        )}
        <Row className="d-flex justify-content-between">
          <Col className="col-6 ">
            <label className="inpLblTxt">
              {t("Type")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            {/* <Select
              style={{ width: "100%" }}
              options={[
                { value: "product", label: t("Product") },
                { value: "service", label: t("Service") },
              ]}
              onChange={({ value }) => {
                if (value === "service") {
                  setQty("");
                }
                setType(value);
              }}
            /> */}
           
            <input  style={{ paddingLeft:"5px", width: "100%" }} className="inputField" type="text" placeholder="Product" disabled />
          </Col>
          {type === "product" && (
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Quantity")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                style={{ width: "100%" }}
                className="inputField"
                onChange={(e) => setQty(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          )}
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{ width: "100%" }}
              className="inputField"
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Price")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{ width: "100%" }}
              className="inputField"
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              id="Price"
            />
          </Col>
        </Row>
        {/* <Row>
          <label className="inpLblTxt">
            Delivery Method<p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <Select
            isMulti
            isSearchable
            onChange={(e) => {
              let ids = e.map((a) => a.value);
              setDeliveryMethod(ids);
            }}
            isDisabled={isDisabledDelivery}
            options={deliveryOptions}
            value={deliveryMethod}
            placeholder="Select Delivery Methods"
          />
        </Row> */}
  
        {<p style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</p>}
        <Button
          onClick={addLink}
          disabled={imageUrl === "" || loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              size="sm"
              style={{ color: "white", margin: "3px" }}
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
}

