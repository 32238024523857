import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PinInput } from "react-input-pin-code";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getSellerData } from "../../../api/sellers/sellers";
import { forgotPin, resetPin } from "../../../api/sellers/sellers";
// import { resetPin } from "../../../api/sellers/sellers";



const ForgotPinModal = ({ modal, toggle }) => {
  const [formValues, setFormValues] = useState({
    verificationCode: ["", "", "", ""],
    newPin: ["", "", "", ""],
    confirmPin: ["", "", "", ""],
  });
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [pinError, setPinError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation();
  const [sellerPhone, setSellerPhone] = useState();

  useEffect(() => {
    let timeout;
    if (timeLeft > 0 && verificationCodeSent) {
      timeout = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else {
      setVerificationCodeSent(false);
      setTimeLeft(30); 
    }
    return () => clearTimeout(timeout);
  }, [timeLeft, verificationCodeSent]);

  useEffect(() => {
    if (modal) { 
      const fetchData = async () => {
        try {
          const response = await getSellerData();
          setSellerPhone(response.data.data[0].businessInfo.phoneNumber);
        } catch (error) {
          console.error("Failed to fetch seller data", error);
        }
      };
      fetchData();
    }
  }, [modal]);

  useEffect(() => {
    if (sellerPhone && modal) { 
      handleResendVerificationCode();
    }
  }, [sellerPhone, modal]);
  const handleResendVerificationCode = async () => {
    try {
      await forgotPin({ phoneNumber: sellerPhone });
      setVerificationCodeSent(true);
    } catch (error) {
      console.error("Error resending verification code", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.newPin.join('') !== formValues.confirmPin.join('')) {
      setPinError(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await resetPin({
        code: parseInt(formValues.verificationCode.join("")),
        newPin: parseInt(formValues.newPin.join("")),
        confirmPin: parseInt(formValues.confirmPin.join("")),
      });
      console.log(sellerPhone, "number successfully updated");
    } catch (error) {
      console.error("Failed to reset pin", error);
    } finally {
      setFormValues({
        verificationCode: ["", "", "", ""],
        newPin: ["", "", "", ""],
        confirmPin: ["", "", "", ""],
      });
      setPinError(false);
      setIsSubmitting(false);
    }
  };
  // const getData = () => {
  //   getSellerData()
  //     .then((response) => {
  //       sellerPhone = response.data.data[0].businessInfo.phoneNumber || undefined;
  //       console.log(sellerPhone,"data")
  //     })
  //     .catch((error) => {
        
  //     });
  // };

 

  const handleChange = (values, name) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: values,
    }));
  };

  

  return (
    <Modal centered size="sm" isOpen={modal} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>{t("Reset Pin Code")}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="verificationCode">
            <Form.Label>{t("Verification Code")}</Form.Label>
            <PinInput
              name="verificationCode"
              length={4}
              values={formValues.verificationCode}
              onChange={(value, index, values) =>
                handleChange(values, "verificationCode")
              }
              required
            />
          </Form.Group>

          <Form.Group controlId="newPin">
            <Form.Label>{t("New Pin")}</Form.Label>
            <PinInput
              name="newPin"
              length={4}
              mask
              values={formValues.newPin}
              onChange={(value, index, values) =>
                handleChange(values, "newPin")
              }
              required
            />
          </Form.Group>

          <Form.Group controlId="confirmPin">
            <Form.Label>{t("Confirm Pin")}</Form.Label>
            <PinInput
              name="confirmPin"
              length={4}
              mask
              values={formValues.confirmPin}
              onChange={(value, index, values) =>
                handleChange(values, "confirmPin")
              }
              required
            />
          </Form.Group>

          <Button type="submit" disabled={isSubmitting}>
            {t("Reset Pin")}
          </Button>

          <Button
            variant="outline-secondary"
            disabled={verificationCodeSent}
            onClick={handleResendVerificationCode}
          >
            {t("Resend Verification Code")} {timeLeft > 0 && `(${timeLeft}s)`}
          </Button>

          {pinError && (
            <Alert variant="danger">
              {t("New pin and confirm pin do not match.")}
            </Alert>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ForgotPinModal;
