import React, { useState } from "react";
import { Row, Col,  Spinner } from "react-bootstrap";
import {
  ButtonToggle,
} from "reactstrap";

import "../Categories/Categories.scss";
import { Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { addCategory } from "../../../api/categories/categories";

export default function AddEventTypeModal({ toggle, getCategories }) {
    const [path, setPath] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const { t, i18n } = useTranslation();
    const onFileChange = async (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
  
      try {
        await Storage.put(e.target.files[0].name, e.target.files[0]).then(
          (res) => {
            
            setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
          }
        );
      } catch (error) {
        
      }
    };
  
    const [title, setTitle] = useState("");
    const [creatingCategory, setCreatingCategory] = useState(false);
    const [loading, setLoading] = useState(false);
    // cat api error message
    const [apiError, setApiError] = useState("");
  
    const addCategoryMethod = () => {
      setApiError("");
      setLoading(true);
      
  
      // if (imageUrl === "") {
      //   setApiError("No image uploaded.");
      //   setLoading(false);
      // } else
      if (title === "") {
        setApiError(`${t("Title can not be empty")}.`);
        setLoading(false);
      } else {
        addCategory({
          icon: "",
          title,
          businessDomain: "Event",
          parentId: null,
          attributes: "",
          isApproved: "approved",
        })
          .then((res) => {
            setCreatingCategory(false);
            
            toggle();
            setLoading(false);
            getCategories();
          })
          .catch((err) => {
            setLoading(false);
            setApiError(err.message);
            
          })
          .finally(() => {
            setTitle("");
          });
      }
    };
    return (
      <>
        <Row>
          {/* <Col className="col-4">
            {!path ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>Add Icon</p>
                </label>
              </>
            ) : (
              <>
                <img
                  htmlFor="imgUpload"
                  src={path}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px" }}
                />
              </>
            )}
          </Col> */}
          <Col>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "5px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
        {/* <Row>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            Choose business domain
          </label>
          <Select
            options={categories}
            onChange={({ value }) => setDomain(value)}
          />
        </Row> */}
        {/* <Row>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            Choose parent category (if any)
          </label>
        </Row> */}
        {/* <Row>
          {count.map((i) => (
            <div style={{ marginBottom: "10px" }}>
              <Select
                onChange={(e) => {
                  setProdCategories([...prodCategories, e.value]);
                  getCategories(e.value);
                  setCurrCatId(e.value);
                }}
                options={options}
                // isClearable
                isSearchable
                placeholder="Select Category..."
              />
            </div>
          ))}
        </Row> */}
        {apiError && (
          <p style={{ color: "red", fontSize: "12px" }}>{t(`${apiError}`)}</p>
        )}
  
        {/* <Row className="addAtrTxt mt-5 mx-3 py-1">
          <Col>+ Add Attribute</Col>
        </Row> */}
  
        <ButtonToggle
          onClick={addCategoryMethod}
          className="tglBtn"
          disabled={loading}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </ButtonToggle>
      </>
    );
}

