import axios from "./axiosInterceptor";

export const deleteEvent = (id) => axios.delete(`/sellers/events/${id}`);

export const deleteVenue = (id) => axios.delete(`/sellers/events/places/${id}`);

export const updateEvent = (id, body) =>
  axios.put(`/sellers/events/${id}`, body);

export const updateVenue = (id, body) =>
  axios.put(`/sellers/events/places/${id}`, body);

export const addEvent = (body) => axios.post(`/sellers/events`, body);

export const addVenue = (body) => axios.post(`/sellers/events/places`, body);

export const getSellerEvents = (limit,page) =>
  axios.get(
    `/sellers/events?limit=${limit}&page=${page}&sellerId=${localStorage.getItem("uid")}`
  );

export const getSellerEventsByActivePage = (activeEventPage,limit) =>
  axios.get(
    `/sellers/events?limit=${limit}&page=${activeEventPage}&searchBy=&searchValue=&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );

export const getEventVenues = (limit = "", activePage = "") =>
  axios.get(`/sellers/events/places?limit=${limit}&page=${activePage}`);
