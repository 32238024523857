import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Logo from "../../Assets/images/logo.png";
import { Auth } from "aws-amplify";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const SignInUser = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const res = await Auth.signIn(email, password).then((response) =>
        {}
      );
      //   axio
      //     .get(`${baseUrl}/stores?sellerId=${res.attributes.sub}`)
      //     .then((response) => {
      //       const { success, message, data } = response.data;
      //       // 
      //       alert(res);
      //       if (success) {
      //         
      //         localStorage.setItem("sid", data[0]._id);
      //         // 
      //         setLoading(false);
      //         props.history.push("/");
      //       } else {
      //         setLoading(true);
      //       }
      //     })
      //     .catch((e) => {
      //       
      //     });
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          marginTop: "7%",
          width: "28%",
          backgroundColor: "#fff",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 16px 0 rgba(0,0,0,0.1)",
        }}
      >
        <div
          style={{
            width: "80%",
            height: "100%",
          }}
        >
          <form>
            <Image style={{ width: "80%", height: "80%" }} src={Logo} />
            <div style={{ width: "100%" }} className="form-group">
              <label>{t("Email address")}</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </div>

            <div className="form-group">
              <label>{t("Password")}</label>
              <input
                type="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </div>

            {error !== "" && (
              <p style={{ color: "red", fontSize: "14px" }}>*{t(`${error}`)}</p>
            )}

            <div
              style={{ marginTop: "2%", marginBottom: "2%", textAlign: "end" }}
            >
              <a
                style={{ fontSize: "14px", color: "#049EFF" }}
                href="#"
                className="forgot-password text-right"
              >
                {t("Forgot password?")}
              </a>
            </div>

            <Button
              disabled={loading}
              onClick={(e) => SignInUser(e)}
              style={{
                marginTop: "2%",
                marginBottom: "8%",
                background: "#049EFF",
                borderColor: "#049EFF",
                boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
              }}
              className="btn btn-primary btn-block"
            >
              {loading && (
                <div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </div>
              )}
              {!loading && <span>Login</span>}
            </Button>
            <p style={{ textAlign: "center", color: "gray" }}>
              Don't have an account? <a href="/">Signup</a>
            </p>
          </form>
        </div>
      </div>
      <span style={{ marginTop: "4%", color: "#333", fontSize: "13px" }}>
        Copyright © All rights reserved. © BayQi 2021.
      </span>
    </div>
  );
}
