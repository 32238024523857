import axios from "./axiosInterceptor";

export const doBankTransfer = (body) =>
  axios.post(`/sellers/withdraw/payment`, body);

export const getBankTransferHistory = (limit, activePage, startDate, endDate) =>
  axios.get(
    `/sellers/withdraw/payment?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );
