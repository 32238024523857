import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { updateSellerData } from "../../../api/sellers/sellers";
import { PinInput } from "react-input-pin-code";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

const OtpModal = ({ toggle, modal, body }) => {
  const [pinCodeValues, setPinCodeValues] = useState(["", "", "", "", "", ""]);
  const [otp, setOtp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [error, setError] = useState("");
  const [counter, setCounter] = React.useState(60);
  const [intervalId, setIntervalId] = React.useState(null);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setCounter(60);
    setIntervalId(null);
    timer();
  }, []);

  const timer = () => {
    if (counter > 0) {
      const id = setInterval(() => setCounter((counter) => counter - 1), 1000);
      setIntervalId(id);
    }
  };

  const updateSeller = () => {
    updateSellerData(body)
      .then((res) => {
        
      })
      .get((error) => {
        
      });
  };

  const verifyPinCode = () => {
    setLoading(true);
    Auth.verifyCurrentUserAttributeSubmit("phone_number", otp)
      .then(() => {
        updateSeller();
        
        setLoading(false);
        toggle(true);
      })
      .catch((e) => {
        
        setError("Please enter the correct Verification Code");
        setLoading(false);
      });
  };

  const resendCode = () => {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {
        
        setCounter(60);
        setIntervalId(null);
        timer();
      })
      .catch((e) => {
        
      });
  };

  const handlePinCodeOnChange = (values) => {
    const value = values.join("");

    setOtp(value);
  };

  return (
    <div>
      <Modal centered size="md" isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>
          {t("Phone Verification Code")}
        </ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col md="7">
              <PinInput
                mask
                length={6}
                values={pinCodeValues}
                validBorderColor={"rgb(204,204,204)"}
                onChange={(value, index, values) => {
                  setPinCodeValues(values);
                  handlePinCodeOnChange(values);
                }}
              />
              {error !== "" && (
                <p
                  style={{ color: "red", fontSize: "14px", textAlign: "left" }}
                >
                  {t(`*${error}`)}
                </p>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md="6">
              <Button
                onClick={(e) => {
                  resendCode(e);
                }}
                disabled={resendLoading || counter > 0}
                style={{
                  // margin: "20px 0px",
                  marginTop: "20px",
                  backgroundColor: "#049EFF",
                  borderWidth: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {resendLoading ? (
                  <Spinner
                    animation="border"
                    style={{ color: "white", padding: "9px" }}
                    size="sm"
                  />
                ) : counter > 0 ? (
                  t(`Resend in ${counter}s`)
                ) : (
                  t("Resend")
                )}
              </Button>
            </Col>
            <Col md="6">
              <Button
                onClick={(e) => {
                  verifyPinCode(e);
                }}
                disabled={loading}
                style={{
                  // margin: "20px 0px",
                  marginTop: "20px",
                  backgroundColor: "#049EFF",
                  borderWidth: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ color: "white", padding: "9px" }}
                    size="sm"
                  />
                ) : (
                  t("Confirm")
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
        {/* <div style={{ height: "5px", width: "100%" }} /> */}
      </Modal>
    </div>
  );
};

export default OtpModal;
