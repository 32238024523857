import React, { useEffect, useState } from "react";
import { baseUrl } from "../../Helpers/helper";
import { Spinner, Tabs, Tab } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import PaymentQRDoc from "./PaymentQRDoc";
import MenuQRDoc from "./MenuQRDoc";
import { useTranslation } from "react-i18next";
import { getSellerData } from "../../../api/sellers/sellers";

const QRCode = () => {
  const [activeTab, setActiveTab] = useState("payment");
  const [paymentQR, setPaymentQR] = useState(null);
  const [productQR, setProductQR] = useState(null);
  const [sellerType, setSellerType] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [sellerName, setSellerName] = useState(null);
  const [downloadQR, setDownloadQR] = useState(null);

  const [paymentDownloadModal, setPaymentDownloadModal] = useState(false);
  const paymentModalToggle = () =>
    setPaymentDownloadModal(!paymentDownloadModal);

  const [menuDownloadModal, setMenuDownloadModal] = useState(false);
  const menuModalToggle = () => setMenuDownloadModal(!menuDownloadModal);

  useEffect(() => {
    setLoading(true);
    getSellerData()
      .then((res) => {
        setLoading(false);
        setPaymentQR(res.data.data[0].paymentQRCode);
        setProductQR(res.data.data[0].productQRCode);
        setSellerType(res.data.data[0].businessCategory);
        setSellerName(res.data.data[0].businessInfo.storeName);
      })
      .catch((err) => {
        setLoading(false);
        
      });
  }, []);

  return (
    <>
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          {!paymentQR && !productQR ? (
            <div style={{ textAlign: "center" }}>
              <img src={noDataFound} width="200px" />
              <p>{t("No QR Found")}</p>
            </div>
          ) : (
            <>
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
              >
                {paymentQR && (
                  <Tab eventKey="payment" title={t("Payment QR")}>
                    <div className="financesHeader">
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "space-between",
                          paddingTop: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <Col
                          xl={3}
                          lg={3}
                          style={{
                            padding: "0px",
                            paddingLeft: "24px",
                            margin: "0px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {t("Payment QR")}
                            </span>
                          </div>
                        </Col>
                        {/* col to fill space */}
                        <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
                          <div class="input-group rounded">
                            <input
                              type="search"
                              class="form-control rounded"
                              placeholder={t("Search")}
                              aria-label="Search"
                              aria-describedby="search-addon"
                            />
                          </div>
                        </Col>
                        <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
                          <div class="input-group rounded">
                            <input
                              type="search"
                              class="form-control rounded"
                              placeholder={t("Search")}
                              aria-label="Search"
                              aria-describedby="search-addon"
                            />
                          </div>
                        </Col>
                        {/* search by name */}
                        <Col xl={3} lg={3} style={{ visibility: "hidden" }}>
                          <input
                            type="search"
                            class="form-control "
                            placeholder={t("Search")}
                            aria-label="Search"
                            aria-describedby="search-addon"
                          />
                        </Col>

                        {/* add cat button */}
                        <Col xl={2} lg={2}>
                          <Button
                            onClick={() => {
                              setDownloadQR(paymentQR);
                              paymentModalToggle();
                            }}
                            type="button"
                            style={{
                              backgroundColor: `${blue}`,
                              color: "#fff",
                              border: "none",
                              marginTop: "0px",
                            }}
                          >
                            {t("Download")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <img style={{ paddingTop: "5px" }} src={paymentQR} />
                  </Tab>
                )}
                {productQR &&
                  (sellerType === "Fast Food" ||
                    sellerType === "Bar & Restaurant") && (
                    <Tab eventKey="menu" title={t("Menu QR")}>
                      <div className="financesHeader">
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                            paddingTop: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          <Col
                            xl={3}
                            lg={3}
                            style={{
                              padding: "0px",
                              paddingLeft: "24px",
                              margin: "0px",
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {t("Menu QR")}
                              </span>
                            </div>
                          </Col>
                          {/* col to fill space */}
                          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
                            <div class="input-group rounded">
                              <input
                                type="search"
                                class="form-control rounded"
                                placeholder={t("Search")}
                                aria-label="Search"
                                aria-describedby="search-addon"
                              />
                            </div>
                          </Col>
                          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
                            <div class="input-group rounded">
                              <input
                                type="search"
                                class="form-control rounded"
                                placeholder={t("Search")}
                                aria-label="Search"
                                aria-describedby="search-addon"
                              />
                            </div>
                          </Col>
                          {/* search by name */}
                          <Col xl={3} lg={3} style={{ visibility: "hidden" }}>
                            <input
                              type="search"
                              class="form-control "
                              placeholder={t("Search")}
                              aria-label="Search"
                              aria-describedby="search-addon"
                            />
                          </Col>

                          {/* add cat button */}
                          <Col xl={2} lg={2}>
                            <Button
                              onClick={() => {
                                setDownloadQR(productQR);
                                menuModalToggle();
                              }}
                              type="button"
                              style={{
                                backgroundColor: `${blue}`,
                                color: "#fff",
                                border: "none",
                                marginTop: "0px",
                              }}
                            >
                              {t("Download")}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <img style={{ paddingTop: "5px" }} src={productQR} />
                    </Tab>
                  )}
              </Tabs>

              <PaymentQRDoc
                qr={downloadQR}
                sellerName={sellerName}
                toggle={paymentModalToggle}
                modal={paymentDownloadModal}
              />

              <MenuQRDoc
                qr={downloadQR}
                sellerName={sellerName}
                toggle={menuModalToggle}
                modal={menuDownloadModal}
              />
            </>
          )}
        </>
      )}
    </>

    // <div>
    //   {!paymentQR && !productQR && <p>No QR found</p>}
    //   {paymentQR && (
    //     <>
    //       <p>Payment QR</p>
    //       <img src={paymentQR} />
    //     </>
    //   )}
    //   {productQR && sellerType !== "Pay" && (
    //     <>
    //       <p>Menu QR</p>
    //       <img src={productQR} />
    //     </>
    //   )}
    // </div>
  );
};

export default QRCode;
