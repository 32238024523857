import React, { useState } from "react";
import { Row, Col, Table, Modal, Spinner, Pagination } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import "./Categories.scss";
import { Storage } from "aws-amplify";
import { baseUrl } from "../../Helpers/helper";
import { useAppSelector } from "../../Redux/hooks";
import { v4 as uuid } from "uuid";
import { useDebouncedCallback } from "use-debounce";
import Pages from "../../Components/Pagination/Pagination";
import UpdateCategoryModal from "./UpdateCategory";
import DeleteModal from "./DeleteModalToggle";
import { useTranslation } from "react-i18next";
// import { addCategory, getCategoriesListbyDomain, getCategoriesListbyName, getSellerData, getSignedInUserData } from "../../../api/services/sellerServices";
import {
  addCategory,
  getCategoriesListbyDomain,
  getCategoriesListbyName,
} from "../../../api/categories/categories";
import {
  getSellerData,
  getSignedInUserData,
} from "../../../api/sellers/sellers";
import AddCategoryModal from "./AddCategoryModal";


const allowedSellerTypes = [
  "Fast Food",
  "Bar & Restaurant",
  "Cantinas",
  "Book",
  "Shopping",
  "Supermarket",
  "Shop Center",
];

const Categories = () => {
  const sellerType = useAppSelector((state) => state.auth.sellerType);
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [businessCategory, setBusinessCategory] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [search, setSearch] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh) {
      getCategories();
    }
  };

  const [updateModal, setUpdateModal] = useState(false);
  const [updateCategory, setUpdateCategory] = useState(null);
  const updateModalToggle = (isRefresh = false) => {
    setUpdateModal(!updateModal);
    if (isRefresh) {
      getCategories();
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const deleteModalToggle = (isRefresh = false) => {
    setDeleteModal(!deleteModal);
    if (isRefresh) {
      getCategories();
    }
  };

  //pagination related
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const debounced = useDebouncedCallback((value) => {
    setDebounceSearch(value);
    setActivePage(1);
  }, 1000);
  // const [debounceSearch] = useDebounce(search, 1000);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      alert(row.title);
    },
  };

  React.useEffect(() => {
    setNoOfPages([]);
    getSellerData()
      .then((res) => {
        setBusinessCategory(res.data.data[0].businessCategory);
      })
      .catch((err) => {
        
      });
}, []);

  React.useEffect(() => {
    getCategories();
  }, [activePage, limit, debounceSearch]);

  const getCategories = () => {
    setNoOfPages([]);
    setLoading(true);
    let type = "";
    if (sellerType == "Book") {
      type = "Library";
    } else if (sellerType == "Fast Food" || sellerType == "Bar & Restaurant") {
      type = "Food and Restaurant";
    } else if (sellerType == "Cantinas" || sellerType == "Supermarket") {
      type = "Supermarket and Cantinas";
    } else if (sellerType == "Shopping") {
      type = "Shopping";
    }

    getCategoriesListbyName(debounceSearch, limit, type, activePage)
      .then((response) => {
        setTableData(response.data.data);
        let num = response.data.count / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        
      });
  };
  
  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{
              padding: "0px",
              paddingLeft: "24px",
              margin: "0px",
            }}
          >
            <div>
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                {t("Categories")}
              </span>
            </div>
          </Col>
          {/* col to fill space */}
          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder={t("Search")}
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder={t("Search")}
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          {/* search by name */}
          <Col xl={3} lg={3}>
            <input
              type="search"
              class="form-control "
              placeholder={t("Search")}
              aria-label="Search"
              aria-describedby="search-addon"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounced(e.target.value);
              }}
            />
          </Col>

          {/* add cat button */}
          <Col xl={2} lg={2}>
            {allowedSellerTypes.includes(businessCategory) && (
              <Button
                onClick={toggle}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                  marginTop: "0px",
                }}
              >
                {t("Add")}
              </Button>
            )}
          </Col>
        </Row>
      </div>

      {/* Table Content */}

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                  }}
                >
                  {t("ID")}
                </th>
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>Domain</th> */}
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                  }}
                >
                  {t("Category Name")}
                </th>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                  }}
                >
                  {t("Type")}
                </th>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                  }}
                  >                 
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setUpdateCategory(item);
                    updateModalToggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {item.publicID && item.publicID}
                  </td>
                  {/* <td style={{ padding: "20px" }}>
                                        {item.businessDomain && item.businessDomain}
                                    </td> */}
                  <td style={{ padding: "20px" }}>
                    {item.title && item.title}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.parentId ? "Sub-Category" : "Parent-Category"}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteId(item._id);
                      deleteModalToggle();
                    }}
                    style={{
                      padding: "20px",
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    {t("Delete")}
                  </td>
                  {/* <td style={{ padding: "20px" }}>
                                        {item.isApproved && item.isApproved}
                                    </td> */}

                  {/* <td style={{ padding: "20px" }}>
                                        <button
                                          style={{
                                            backgroundColor: `${blue}`,
                                            color: "#fff",
                                            padding: "4px",
                                            border: "none",
                                            borderRadius: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            alert("button");
                                          }}
                                        >
                                            Show
                                        </button>
                                    </td> */}
                </tr>
              ))}
            </tbody>
          </Table>

          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Pagination */}
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  {/* No of products on page toggler */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          marginTop: "0",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem
                          onClick={() => {
                            setLimit(1);
                            setActivePage(1);
                          }}
                        >
                          1
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(5);
                            setActivePage(1);
                          }}
                        >
                          5
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(10);
                            setActivePage(1);
                          }}
                        >
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {/* <div>
        {tableData !== [] ? (
          <TableViewTemplate
            keyField={"_id"}
            data={tableData}
            columns={categoryColumns}
            event={true}
            rowEvents={rowEvents}
          />
        ) : null}
      </div> */}

      <Modal show={modal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Add Category")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <AddCategoryModal toggle={toggle} />
        </Modal.Body>
      </Modal>

      <Modal show={updateModal} onHide={updateModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Update Category")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <UpdateCategoryModal
            item={updateCategory}
            toggle={updateModalToggle}
          />
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} onHide={deleteModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Update Category")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DeleteModal id={deleteId} toggle={deleteModalToggle} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Categories;
