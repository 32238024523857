import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { MdOutlineClose } from "react-icons/md";
import { CgCloseO } from "react-icons/cg";
import { CheckCircleOutline } from "@material-ui/icons";
import { Modal, Button } from "reactstrap";
import CustomizedSteppers from "../../Components/Stepper/Stepper";
import { Auth } from "aws-amplify";
import ReactCodeInput from "react-verification-code-input";
import { provinces, citiesOfProvinces } from "../../DummyData";
import { useHistory } from "react-router-dom";
// import { MDBFileInput } from "mdbreact";
import "./Register.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";
import { Spinner } from "react-bootstrap";
import { CountryDropdown } from "react-country-region-selector";
import { PinInput } from "react-input-pin-code";
// redux store related imports
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { signin } from "../../Redux/auth/authSlice";
import { useTranslation } from "react-i18next";
import { getDomainsList } from "../../../api/services/services";
import { addSeller, getSignedInUserData } from "../../../api/sellers/sellers";
import { getShoppingCentres } from "../../../api/shoppingCenters/shoppingCenters";
// import { addSeller, getDomainsList, getShoppingCentres, getSignedInUserData } from "../../../api/services/sellerServices";

// 
// 
// 
// 

const AWS = require("aws-sdk");
AWS.config.region = process.env.REACT_APP_AWS_REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
});

const cognito = new AWS.CognitoIdentityServiceProvider();

cognito.config.region = process.env.REACT_APP_AWS_REGION;
cognito.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
});

cognito.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
const options = [
  { value: "BANCO BNI", label: "Banco BNI" },
  { value: "Banco BFA", label: "Banco BFA" },
  { value: "Banco BAI", label: "Banco BAI" },
  { value: "BANCO BCA", label: "BANCO BCA" },
  { value: "Banco BCI", label: "Banco BCI" },
  { value: "Banco BAI", label: "Banco BAI" },
  { value: "Banco BIR", label: "Banco BIR" },
  { value: "Banco YETU", label: "Banco YETU" },
  { value: "Banco BPC", label: "Banco BPC" },
  { value: "Banco KEVE", label: "Banco KEVE" },
  { value: "Banco SOL", label: "Banco SOL" },
  { value: "Banco VALOR", label: "Banco VALOR" },
  { value: "Banco DA CHINA", label: "Banco DA CHINA" },
  { value: "STANDARD BANK", label: "STANDARD BANK" },

  { value: "Banco VTB ÁFRICA", label: "Banco VTB ÁFRICA" },
  { value: "Banco MILLENNIUM ATLÂNTICO", label: "Banco MILLENNIUM ATLÂNTICO" },
  { value: "Banco CAIXA GERAL ANGOLA", label: "Banco CAIXA GERAL ANGOLA" },
  { value: "BCS – BANCO DE CRÉDITO DO SUL", label: "BCS – BANCO DE CRÉDITO DO SUL" },


  {
    value: "BANCO COMERCIAL DO HUAMBO ",
    label: "BANCO COMERCIAL DO HUAMBO",
  },
];

const Register = ({ open, setOpen }) => {
  // store
  const store = useAppSelector((state) => state.auth.isAuthenticated);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  // store end
  // const history = useHistory();
  const [modal, setModal] = useState(open);
  const toggle = () => setModal(!modal);
  const [status, setStatus] = useState(true);
  const [checked, setChecked] = useState("");
  const [checkerror, setCheckerror] = useState("");
  const [isContinue, setIsContinue] = useState(false);

  // toggle between signup and signin modal
  const [modalType, setModalType] = useState("signup");
  function giveBorder(id, n) {
    document.getElementById(id).style.border = "2px solid #0d6efd";
    document.getElementById(n).style.border = "none";
  }

  // sign in related
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eyeBtntoogle, seteyeBtntoogle] = useState(false);
  const [passwordShown, setpasswordShown] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [signinError, setSigninError] = useState("");
  // toggle between signin and forgot password modal
  const [signinModalType, setSigninModalType] = useState("signin");
  // till here

  // Reset email states
  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState("");
  const [resetVerification, setResetVerification] = useState("");
  const [resetPass, setResetPass] = useState("");
  const [resetPassConfirm, setResetPassConfirm] = useState("");



  



  // Forgot password handler
  const forgotPassword = () => {
    setLoading(true);
    // Send confirmation code to user's email
    setResetEmailError("");
    Auth.forgotPassword(`+${resetEmail}`)
      .then((data) => {
        setLoading(false);
        setSigninModalType("resetPassword");
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === "Username cannot be empty") {
          setResetEmailError(t("Phone Number cannot be empty"));
        } else if (
          err.message === "Username/client id combination not found."
        ) {
          setResetEmailError(t("Phone Number not found"));
        } else setResetEmailError(err.message);
      });
  };

  const resetPassword = () => {
    setLoading(true);
    if (
      resetEmail === "" ||
      resetVerification === "" ||
      resetPass === "" ||
      resetPassConfirm === ""
    ) {
      setResetEmailError(t("All fields are mandatory"));
      setLoading(false);
      return;
    } else if (resetPass !== resetPassConfirm) {
      setResetEmailError(t("Passwords do not match"));
      setLoading(false);
      return;
    }

    Auth.forgotPasswordSubmit(`+${resetEmail}`, resetVerification, resetPass)
      .then((data) => {
        
        setLoading(false);
        toggle();
      })
      .catch((err) => {
        setLoading(false);
        
      });
  };

  const SetStatus = () => {
    setStatus(true);
  };

  const togglePasswordVisiblity = () => {
    if (passwordShown === true) {
      setpasswordShown(false);
      seteyeBtntoogle(false);
    } else {
      setpasswordShown(true);
      seteyeBtntoogle(true);
    }
  };
 
  // signin
  const signinUser = async (e) => {
    try {
      e.preventDefault();
      setSigninError("");
      setLoading(true);
      const user = await Auth.signIn(`+${email}`, password);
      
      localStorage.setItem("uid", user.attributes.sub);
      getSignedInUserData(user.attributes.sub)
        .then((response) => {
          
          
          const { success, message, data } = response.data;
          if (success) {
            localStorage.setItem("domain", data[0].businessCategory);
            localStorage.setItem("accountType", data[0].account);
            localStorage.setItem("province", data[0].businessAddress.province);
            if (data[0].status === "ACTIVE") {
              // alert("SignIn Successfully");
              if (data[0].businessCategory === "Shop Center") {
                dispatch(signin(data[0].shoppingCenter.domain));
              } else {
                dispatch(signin(data[0].businessCategory));
              }
              setLoading(false);
            } else {
              // alert("Seller not approved");
              setSigninError(t("Seller Approval Pending."));
              Auth.signOut();
              setLoading(false);
            }
          } else {
            setLoading(true);
          }
        })
        .catch((e) => {
          
        });
    } catch (error) {
      setLoading(false);
      if (error.message === "User is not confirmed.") {
        resendConfirmationCode();
        setSigninModalType("verification");
        // setSigninError("*User is not confirmed");
      }

      if (error.message === "Incorrect username or password.") {
        setSigninError(t("Incorrect phone number or password."));
      } else {
        setSigninError(error.message);
      }
    }
  };

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(`+${email}`);
      
    } catch (err) {
      
    }
  }

  async function confirmSignUp() {
    setLoading(true);
    try {
      await Auth.confirmSignUp(`+${email}`, resetVerification);
      setLoading(false);
      toggle();
    } catch (error) {
      setLoading(false);
      
    }
  }

  if (modalType === "signup") {
    return (
      <Modal
        backdrop={"static"}
        isOpen={modal}
        onClosed={() => setOpen(false)}
        centered
        toggle={toggle}
        size="lg"
      >
        {/* <button
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={toggle}
        >
          close
        </button> */}
        <CgCloseO
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={toggle}
          color={"red"}
          size={"26"}
        />

        <div>
          {/* code starts here, select account type(individual/company) */}
          {!isContinue ? (
            <div className="open-register" style={{ padding: "15px" }}>
              {/* <Header /> */}
              <h4 style={{ textAlign: "center", paddingTop: "20px" }}>
                {t("Choose account type")}
              </h4>
              <div className="choose-container" style={{ cursor: "pointer" }}>
                <div
                  className="account-type"
                  id="1"
                  onClick={() => {
                    setChecked("individual");
                    setCheckerror("");
                    giveBorder(1, 2);
                  }}
                >
                  {checked === "individual" ? (
                    <div className="is-checked">
                      {" "}
                      <CheckCircleOutline />
                    </div>
                  ) : null}
                  <h4>{t("Individual Account")}</h4>
                  <p>{t("Sell and receive online payments as an individual entrepreneur")}</p>
                  <p>{t("Sign Up now to avail 50% Discount")}</p>
                  <h5>1,140AKZ</h5>
                  <h5
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                    }}
                  >
                    2,280AKZ
                  </h5>
                </div>
                <div
                  className="account-type"
                  id="2"
                  onClick={() => {
                    setChecked("company");
                    giveBorder(2, 1);
                    setCheckerror("");
                  }}
                >
                  {checked === "company" ? (
                    <div className="is-checked">
                      <CheckCircleOutline />
                    </div>
                  ) : null}
                  <h4>{t("Company Account")}</h4>
                  <p>{t("For companies with a commercial establishment and commercial registrations")}</p>
                  <p>{t("Sign Up now to avail 50% Discount")}</p>
                  <h5>5,700AKZ</h5>
                  <h5
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                    }}
                  >
                    11,400AKZ
                  </h5>
                </div>
              </div>{" "}
              <p style={{ color: "red" }}>{checkerror}</p>
              <div style={{ width: "20%", marginBottom: "12px" }}>
                <button
                  type="onClick"
                  onClick={() => {
                    if (checked !== "") setIsContinue(true);
                    else setCheckerror(t("*Select one type"));
                  }}
                  class="btn btn-primary continue-btn"
                >
                  {t("Next")}
                </button>
              </div>
              <p>
                {t("Already have an account?")} {/* <Link to="/login"> */}
                <b
                  style={{ color: "#0d6efd", cursor: "pointer" }}
                  onClick={() => setModalType("signin")}
                >
                  {t("Login")}
                </b>
                {/* </Link> */}
              </p>
            </div>
          ) : (
            <BusinessInfo accountType={checked} toggle={toggle} />
          )}
        </div>
        {/* ) */}

        {/* } */}
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={modal}
      onClosed={() => setOpen(false)}
      centered
      toggle={toggle}
      // size="sm"
    >
      {signinModalType === "signin" ? (
        <form>
          <div style={{ padding: "30px" }}>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "25px",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              {t("LogIn to BayQi")}
            </div>
            <div className="form-group">
              <span>
                {t("Phone")} <label className="red">*</label>
              </span>
              <div>
                <PhoneInput
                  // disableDropdown
                  inputStyle={{ width: "100%" }}
                  country="ao"
                  placeholder="+923001234567"
                  value={email}
                  onChange={setEmail}
                />
              </div>
              {/* <input
                type="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter phone number"
              /> */}
            </div>

            <div className="form-group">
              <span>
                {t("Password")} <label className="red">*</label>
              </span>
              <span className="form-control">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="signin-input"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("Enter password")}
                />
                {eyeBtntoogle === true ? (
                  <i
                    onClick={() => togglePasswordVisiblity()}
                    className="eye-btn"
                  >
                    <AiFillEye
                      className="eye"
                      color={"lightgray"}
                      size={"24"}
                    />
                  </i>
                ) : (
                  <i
                    onClick={() => togglePasswordVisiblity()}
                    className="eye-btn"
                  >
                    <AiFillEyeInvisible
                      className="eye"
                      color={"lightgray"}
                      size={"24"}
                    />
                  </i>
                )}
              </span>
            </div>
            {error !== "" && (
              <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
                {t(`*${error}`)}
              </p>
            )}

            <div
              style={{
                marginTop: "2%",
                marginBottom: "2%",
                textAlign: "end",
              }}
            >
              <a
                style={{
                  fontSize: "14px",
                  color: "#049EFF",
                  cursor: "pointer",
                }}
                // href="#"
                onClick={() => setSigninModalType("forgotpassword")}
                className="forgot-password text-right"
              >
                {t("Forgot password?")}
              </a>
            </div>

            {signinError && (
              <p style={{ color: "red", fontSize: "13px" }}>
                {t(`*${signinError}`)}
              </p>
            )}

            <div style={{ padding: "0 3rem" }}>
              <Button
                disabled={loading}
                onClick={(e) => signinUser(e)}
                style={{
                  marginLeft: "35%",
                  background: "#049EFF",
                  borderColor: "#049EFF",
                  boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
                  width: "30%",
                }}
                className="btn btn-primary"
              >
                {loading && (
                  <div>
                    <div
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></div>
                    <div
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></div>
                    <div
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></div>
                  </div>
                )}
                {!loading && (
                  <p style={{ color: "#fff", marginBottom: "0" }}>
                    {t("Login")}
                  </p>
                )}
              </Button>
            </div>
            <p style={{ textAlign: "center", color: "gray" }}>
              {t("Don't have an account?")}{" "}
              <p
                style={{
                  display: "inline",
                  cursor: "pointer",
                  color: "#22A9FF",
                }}
                onClick={() => setModalType("signup")}
              >
                {t("Sign up")}
              </p>
            </p>
          </div>
        </form>
      ) : signinModalType === "forgotpassword" ? (
        <div style={{ padding: "50px" }}>
          <h3 style={{ textAlign: "center", marginTop: "10%" }}>
            {t("Forget Password")}
          </h3>
          <span>
            {t("Phone Number")} <label className="red">*</label>
          </span>

          <div style={{ width: "100%" }} className="form-group">
            <PhoneInput
              // disableDropdown
              inputStyle={{ width: "100%" }}
              country="ao"
              placeholder="+923001234567"
              value={resetEmail}
              onChange={setResetEmail}
            />
          </div>

          {resetEmailError && (
            <p style={{ color: "red", fontSize: "13px" }}>
              {t(`*${resetEmailError}`)}
            </p>
          )}

          <div style={{ padding: "0 3rem" }}>
            <Button
              disabled={loading}
              onClick={(e) => forgotPassword(e)}
              style={{
                marginLeft: "20%",
                marginTop: "2%",
                marginBottom: "8%",
                background: "#049EFF",
                borderColor: "#049EFF",
                boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
                width: "60%",
              }}
              className="btn btn-primary"
            >
              {loading && (
                <div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </div>
              )}
              {!loading && (
                <p style={{ color: "#fff", marginBottom: "0" }}>
                  {t("Confirm")}
                </p>
              )}
            </Button>
            <p style={{ color: "gray", marginLeft: "16%" }}>
              {t("Remember Password?")}{" "}
              <a
                onClick={() => setSigninModalType("signin")}
                style={{ cursor: "pointer", color: "#007bff" }}
              >
                {t("Sign in")}
              </a>
            </p>
          </div>
        </div>
      ) : signinModalType === "resetPassword" ? (
        <div style={{ padding: "50px" }}>
          <h3 style={{ textAlign: "center", marginTop: "10%" }}>
            {t("Forget Password")}
          </h3>

          <p style={{ color: "gray", marginLeft: "16%" }}>
            {t("Please enter the Verification Code sent on your Phone Number")}
          </p>

          <span>
            {t("Verification Code")} <label className="red">*</label>
          </span>
          <div style={{ width: "100%" }} className="form-group">
            <ReactCodeInput onChange={(e) => setResetVerification(e)} />
          </div>

          <span>
            {t("New Password")} <label className="red">*</label>
          </span>
          <div style={{ width: "100%" }} className="form-group">
            {/* <ReactCodeInput onChange={(e) => setResetPass(e)} /> */}
            <input
              type="password"
              required
              className="form-control"
              value={resetPass}
              onChange={(e) => {
                setResetPass(e.target.value);
              }}
            />
          </div>

          <span>
            {t("Confirm New Password")} <label className="red">*</label>
          </span>
          <div style={{ width: "100%" }} className="form-group">
            {/* <ReactCodeInput onChange={(e) => setResetPassConfirm(e)} /> */}
            <input
              type="password"
              required
              className="form-control"
              value={resetPassConfirm}
              onChange={(e) => {
                setResetPassConfirm(e.target.value);
              }}
            />
          </div>

          {resetEmailError && (
            <p style={{ color: "red", fontSize: "13px" }}>
              {t(`*${resetEmailError}`)}
            </p>
          )}

          <div style={{ padding: "0 3rem" }}>
            <Button
              disabled={loading}
              onClick={(e) => resetPassword(e)}
              style={{
                marginLeft: "20%",
                marginTop: "2%",
                marginBottom: "8%",
                background: "#049EFF",
                borderColor: "#049EFF",
                boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
                width: "60%",
              }}
              className="btn btn-primary"
            >
              {loading && (
                <div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </div>
              )}
              {!loading && (
                <p style={{ color: "#fff", marginBottom: "0" }}>
                  {t("Confirm")}
                </p>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ padding: "50px" }}>
          <h3 style={{ textAlign: "center", marginTop: "10%" }}>
            {t("Verification")}
          </h3>

          <p style={{ color: "gray", textAlign: "center" }}>
            {t("Please enter the Verification Code sent on your Phone Number")}
          </p>

          <span>
            {t("Verification Code")} <label className="red">*</label>
          </span>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            className="form-group"
          >
            <ReactCodeInput onChange={(e) => setResetVerification(e)} />
          </div>

          {resetEmailError && (
            <p style={{ color: "red", fontSize: "13px" }}>
              {t(`*${resetEmailError}`)}
            </p>
          )}

          <div style={{ padding: "0 3rem" }}>
            <Button
              disabled={loading}
              onClick={(e) => confirmSignUp(e)}
              style={{
                marginLeft: "20%",
                marginTop: "2%",
                marginBottom: "8%",
                background: "#049EFF",
                borderColor: "#049EFF",
                boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
                width: "60%",
              }}
              className="btn btn-primary"
            >
              {loading && (
                <div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </div>
              )}
              {!loading && (
                <p style={{ color: "#fff", marginBottom: "0" }}>
                  {t("Confirm")}
                </p>
              )}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const BusinessInfo = ({ toggle, accountType }) => {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("1");
  const [bankSelected, setBankSelected] = useState({
    value: "",
    isvalid: true,
  });
  const { t, i18n } = useTranslation();
  const [shopCenterDomains, setShopCenterDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [domainGridLoading, setDomainGridLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  React.useEffect(() => {
    getDomains();
  }, []);

  React.useEffect(() => {
    let tempArray = domains.map((item) => {
      
      if (
        item.name !== "Event" &&
        item.name !== "Shop Center" &&
        item.name !== "Cantinas"
      ) {
        return { label: item.name, value: item.name };
      }
    });

    let newArray = tempArray.filter((item) => item !== undefined);
    
    setShopCenterDomains(newArray);
  }, [domains]);

  const getDomains = () => {
    setDomainGridLoading(true);
    getDomainsList()
      .then((res) => {
        setDomainGridLoading(false);
        
        setDomains(res.data.data);
      })
      .catch((err) => {
        setDomainGridLoading(false);
        
      });
  };
  const [selectedDomain, setSelectedDomain] = useState("");
  const [businessDomain, setBusinessDomain] = useState("");

  const [nif, setNif] = useState({ value: "", isvalid: true });
  const [businessName, setBusinessName] = useState({
    value: "",
    isvalid: true,
  });

  const [emptyFieldError, setEmptyFieldError] = useState(null);

  const [name, setName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [nationality, setNationality] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [newNif, setNewNif] = useState("");
  const [newIban, setNewIban] = useState("");
  const [documentNumber, setDocNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [id, setId] = useState("");

  const [checked, setChecked] = useState("");

  const [currProvince, setCurrProvince] = useState("");
  const [currCities, setCurrCities] = useState();
  const [currCity, setCity] = useState();
  const [address, setAddress] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pinCodeValues, setPinCodeValues] = useState(["", "", "", ""]);
  const [confirmPinCodeValues, setConfirmPinCodeValues] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [pinCode, setPinCode] = useState(null);
  const [confirmPinCode, setConfirmPinCode] = useState(null);
  const [checkerror, setCheckerror] = useState("select");
  const [updatedocs, setupdatedocs] = useState("");
  const [expressPhno, setexpressPhno] = useState(null);
  const [verificationcode, setverificationcode] = useState(0);
  const [logoImage, setLogoImage] = useState("");
  const [logo, setLogo] = useState("");
  const [document, setDocument] = useState("");

  const [closingTime, setClosingTime] = useState("");
  const [openingTime, setOpeningTime] = useState("");

  const [showDeleteText, setShowDeleteText] = useState(false);

  const [doc, setDoc] = useState("");

  const validateFields = () => {
    setEmptyFieldError(null);
    setStage("3");
  };

  const [shopCenters, setShopCenters] = useState([]);
  const [shopCenter, setShopCenter] = useState("");
  const [currDomain, setCurrDomain] = useState("");
  const [regNo, setRegNo] = useState("");
  const [bank, setBank] = useState("");
  // fetch list of shop-centers, created by admin
  React.useEffect(() => {
    getShoppingCentres()
      .then((res) => {
        res.data.data.map((item) => {
          setShopCenters((shopCenters) => [
            ...shopCenters,
            { label: item.name, value: item._id },
          ]);
        });
      })
      .catch((err) => {
        
      });
}, []);

  const CreateStore = async (e) => {
    setEmptyFieldError("");
    setLoading(true);
    e.preventDefault();
    // 1. aws-amplify auth call
    Auth.signUp({
      username: `+${contactNumber}`,
      password: password,
      attributes: {
        email: "temp_mail@mail.com",
      },
    })
      // 2. create reference api
      .then((authResponse) => {
        
        // axios
        //   .post(
        //     `https://qdhdidmxcf.execute-api.eu-west-1.amazonaws.com/dev/payment/createReference`,
        //     {}
        //   )
        //   .then((res) => {
        //     
    
        const sellerBody = {
          payment: {
            amount: accountType === "individual" ? "1000" : "5000",
            orderOrigin: "MOBILE",
            paymentInfo: {
              mobile: {
                phoneNumber: contactNumber,
              },
            },
            paymentMethod:paymentMethod
          },
          sellerData: {
            seller: {
              id: authResponse.userSub,
              gender: gender,
              nationality: nationality,
              dob,
              account: accountType,
              pincode: pinCode,
              businessInfo: {
                storeLogo: logoImage,
                openingTime: openingTime,
                closingTime: closingTime,
                nif: newNif,
                storeName,
                ownerName: name,
                phoneNumber: contactNumber,
              },
              businessAddress: {
                address,
                province: currProvince,
                city: currCity,
              },
              bankDetails: {
                bankName: bank,
                iban: `AO06${newIban}`,
              },
              documents: {
                bankName: selectedBank,
                idType: id,
                documentNumber,
                documentImage: document,
              },
              businessCategory: businessDomain,
            },
            payment: {
              method: selectedBank,
              paymentMethod:paymentMethod,
              accountNumber: newIban,
            },
          },
        };

        console.log(sellerBody.sellerData,"testing the data")

        if (businessDomain == "Shop Center") {
          const shop = {
            regNumber: regNo,
            shoppingCenterId: shopCenter,
            domain: currDomain,
            address,
            description: "",
            name: storeName,
          };
          sellerBody.shoppingCenter = shop;
        }
        

        // 4. backend api to save all the data
        addSeller(sellerBody)
          .then((res) => {
            const { success, message, data } = res.data;
            
            if (success) {
              setLoading(false);
              setStage("46");
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            deleteLinkedProviderUser(authResponse.userSub);
            setLoading(false);
            
            setEmptyFieldError(t("*Something went wrong."));
          });
        //     })
        //     .catch((err) => {
        //       deleteLinkedProviderUser(authResponse.userSub);
        //       setLoading(false);
        //       setEmptyFieldError(t("*Something went wrong."));
        //       
        //     });
      })
      .catch((e) => {
        setEmptyFieldError(`*${e.message}`);
        setLoading(false);
        
      });

    
  };

  const deleteLinkedProviderUser = async (user) => {
    const params = {
      Username: user,
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    };
    const result = await new Promise((resolve, reject) => {
      cognito.adminDeleteUser(params, (err, data) => {
        if (err) {
          
          reject(err);
          return;
        }
        
        resolve(data);
      });
    });
    return result;
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    // setLoading(true)

    try {
      await Storage.remove(file).then((res) => {
        
        // setLoading(false)
      });
    } catch (e) {
      // setLoading(false)
      
    }
  };

  const onFileChange = async (e) => {
    setLoading(true);
    const files = e.target.files;
    

    try {
      const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
      const result = await Storage.put(fileKey, files[0], {
        contentType: "image/jpeg", // contentType is optional
      });
      
      fetchImageUrl(result);
    } catch (error) {
      setLoading(false);
      
    }

    setLoading(false);

    const filePath = URL.createObjectURL(files[0]);
    setLogo(filePath);
    // for (let i = 0; i < files.length; i++) {
    //   const filePath = URL.createObjectURL(files[i]);
    //   setImgPaths((imgPaths) => [...imgPaths, filePath]);
    // }
  };

  const fetchImageUrl = async (key) => {
    const Url = `${process.env.REACT_APP_S3_URL}/public/${key.key}`;
    setLogoImage(Url);
  };

  const uploadDocument = async (e) => {
    setLoading(true);
    const files = e.target.files;
    let Url = "";

    try {
      const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
      const result = await Storage.put(fileKey, files[0]);
      
      Url = `${process.env.REACT_APP_S3_URL}/public/${result.key}`;
    } catch (error) {
      setLoading(false);
      
    }

    setLoading(false);
    return Url;
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleUploadDocument = async (e) => {
    const imageLink = await uploadDocument(e);
    setDocument(imageLink);
    setupdatedocs("updated");
    setDoc(e.target.files[0]);
    
  };

  const checkIBANLength = (number) => {
    const length = number.toString().length;

    if (length === 21) {
      return true;
    } else {
      return false;
    }
  };

  const handlePinCodeOnChange = (values, source) => {
    const value = parseInt(values.join(""));

    if (source === "confirm") {
      setConfirmPinCode(value);
    } else if (source === "pin") {
      setPinCode(value);
    }
  };

  return (
    <div className="col-form-all">
      <div className="form-progress-indicator">
        <div style={{ width: "100%" }}>
          <CustomizedSteppers stage={stage} />
        </div>
      </div>
      <div className="show-form">
        {stage === "2" ? (
          // stage 2 (information)
          <div className="info-form-form" style={{ paddingLeft: "30px" }}>
            <div className="form">
              <h5>
                {accountType === "individual" ? t("Store") : t("Business")}{" "}
                {t("Info")}
              </h5>
              <div className="grid-container-form">
                {/* owner name */}
                <div>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Store Logo")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  {logoImage && (
                    <>
                      <div className="d-flex flex-column">
                        <img
                          alt=""
                          style={{
                            marginRight: "10px",
                            // marginBottom: "10px",
                            marginTop: "10px",
                          }}
                          src={logoImage}
                          width={67}
                          height={67}
                          onClick={() => {
                            setLogoImage("");
                            onDeleteImage(logoImage);
                          }}
                          onMouseEnter={() => setShowDeleteText(true)}
                          onMouseLeave={() => setShowDeleteText(false)}
                        />
                      </div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          visibility: !showDeleteText ? "hidden" : "visible",
                        }}
                      >
                        {t("*click to delete")}
                      </p>
                    </>
                  )}
                  <input
                    style={{ display: "none" }}
                    id="imageUpload"
                    type="file"
                    onChange={(e) => onFileChange(e)}
                  />
                  <label
                    style={{
                      visibility: logoImage ? "hidden" : "initial",
                    }}
                    htmlFor="imageUpload"
                    className="imgUploadLbl"
                  >
                    +
                  </label>
                </div>
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Owner name")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="eg: Andy Robert"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                {/* store name */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {accountType === "individual"
                      ? t("Store name")
                      : t("Business Name")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    value={storeName}
                    onChange={(e) => {
                      setStoreName(e.target.value);
                    }}
                  />
                </div>
                {/* owner phone */}
                <div
                  className={`grid-item form-group business-input phone-number`}
                >
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {accountType === "individual"
                      ? t("Phone number")
                      : t("Business Phone number")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <PhoneInput
                    // disableDropdown
                    country="ao"
                    placeholder="+923001234567"
                    value={contactNumber}
                    onChange={setContactNumber}
                  />
                </div>
                {(businessDomain === "Bar & Restaurant" ||
                  businessDomain === "Fast Food") && (
                  <>
                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Opening Time")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <input
                        type="time"
                        required
                        className="form-control"
                        placeholder="eg: Andy Robert"
                        value={openingTime}
                        onChange={(e) => {
                          setOpeningTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Closing Time")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <input
                        type="time"
                        required
                        className="form-control"
                        placeholder="eg: Andy Robert"
                        value={closingTime}
                        onChange={(e) => {
                          setClosingTime(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Password")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <input
                    type="password"
                    required
                    className="form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                {/* retype password */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Confirm Password")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <input
                    type="password"
                    required
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                {businessDomain === "Shop Center" && (
                  <>
                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Choose shop center")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <Select
                        onChange={({ value }) => setShopCenter(value)}
                        options={shopCenters}
                      />
                    </div>
                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Registration number")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <input
                        value={regNo}
                        onChange={(e) => setRegNo(e.target.value)}
                        type="text"
                        required
                        className="form-control"
                      />
                    </div>

                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Domain")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <br />
                      <Select
                        options={shopCenterDomains}
                        onChange={({ value }) => setCurrDomain(value)}
                      />
                    </div>
                    <br />
                  </>
                )}
                {/* <input
                    type="number"
                    required
                    className="form-control"
                    placeholder="+923331234567"
                    value={contactNumber}
                    onChange={(e) => {
                      setContactNumber(e.target.value);
                    }}
                  /> */}

                {/* nationality */}
                {accountType === "individual" ? (
                  <>
                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Nationality")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      {/* <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="eg: African"
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    /> */}
                      <CountryDropdown
                        style={{ width: "100%", height: "60%" }}
                        className="input-box"
                        value={nationality}
                        onChange={(e) => setNationality(e)}
                      />
                    </div>

                    {/* dob */}

                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Date of birth")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <input
                        type="date"
                        required
                        max={"9999-12-31"}
                        className="form-control"
                        placeholder="dd/mm/yyyy"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>

                    {/* gender */}

                    <div className={`grid-item form-group business-input`}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {t("Gender")}
                        <p style={{ color: "red", display: "inline" }}> *</p>
                      </label>
                      <Select
                        isSearchable={false}
                        options={[
                          { label: t("Male"), value: "Male" },
                          { label: t("Female"), value: "Female" },
                        ]}
                        onChange={({ value }) => setGender(value)}
                      />
                    </div>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                <h5>{t("Bank Details")}</h5>
                <br />
                {/* NIF */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {accountType === "individual" ? "NIF" : t("Business NIF")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <input
                    type="text"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    required
                    className="form-control"
                    value={newNif}
                    maxLength={35}
                    onChange={(e) => {
                      if (e.target.value.length <= 35) {
                        setNewNif(e.target.value);
                      }
                    }}
                  />
                </div>
                {/* Iban */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {accountType === "individual" ? "IBAN" : t("Business IBAN")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  {/* <input
                    type="number"
                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    required
                    className="form-control"
                    value={newIban}
                    onChange={(e) => se tNewIban(e.target.value)}
                  /> */}
                  <div className="input-box">
                    <span className="prefix">AO06</span>
                    <input
                      required
                      maxLength="21"
                      onInput={maxLengthCheck}
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={newIban}
                      onChange={(e) => setNewIban(e.target.value)}
                      placeholder="XXXXXXXXXXXXXXXXXXXXX"
                    />
                  </div>
                </div>
                <div className={`grid-item form-group business-input`}>
            <label
              htmlFor="lastName"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Select Bank")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <Select
              options={options}
              
              onChange={(value) => setBank(value.value)}
            />
            {bank !== "" && (
              <span
                style={{
                  position: "absolute",
                  color: "rgb(153, 153, 153)",
                  fontSize: "12px",
                }}
              >
                {bank === options[0].value
                  ? `${t("Commission")} 250 AKZ, IVA 35 AKZ`
                  : `${t("Commission")} 650 AKZ, IVA 91 AKZ`}
              </span>
            )}
          </div>
          <br/>
          <br/>
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Pin Code")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <PinInput
                    mask
                    length={4}
                    values={pinCodeValues}
                    validBorderColor={"rgb(204,204,204)"}
                    onChange={(value, index, values) => {
                      // 
                      setPinCodeValues(values);
                      handlePinCodeOnChange(values, "pin");
                    }}
                  />
                </div>
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Confirm Pin Code")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <PinInput
                    mask
                    length={4}
                    values={confirmPinCodeValues}
                    validBorderColor={"rgb(204,204,204)"}
                    onChange={(value, index, values) => {
                      setConfirmPinCodeValues(values);
                      handlePinCodeOnChange(values, "confirm");
                    }}
                  />
                </div>

                {/* ID(radio buttons) */}
                <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  ID
                  {/* <p style={{ color: "red", display: "inline" }}> *</p> */}
                </label>
                <br />
                {accountType === "individual" ? (
                  <>
                    <div>
                      <input
                        type="radio"
                        id="Bilhete de Identidade"
                        name="id"
                        value="Bilhete de Identidade"
                        checked={id === "Bilhete de Identidade"}
                        onClick={() => setId("Bilhete de Identidade")}
                      />
                      <label
                        style={{ marginLeft: "10px" }}
                        for="Bilhete de Identidade"
                      >
                        Bilhete de Identidade
                      </label>
                    </div>
                    <br />
                    <div>
                      <input
                        type="radio"
                        id="Passaporte"
                        name="id"
                        value="Passaporte"
                        checked={id === "Passaporte"}
                        onClick={() => setId("Passaporte")}
                      />
                      <label style={{ marginLeft: "10px" }} for="Passaporte">
                        Passaporte
                      </label>
                    </div>
                    <br />
                    <div>
                      <input
                        type="radio"
                        id="Cartão Residente"
                        name="id"
                        value="Cartão Residente"
                        checked={id === "Cartão Residente"}
                        onClick={() => setId("Cartão Residente")}
                      />
                      <label
                        style={{ marginLeft: "10px" }}
                        for="Cartão Residente"
                      >
                        Cartão Residente
                      </label>
                    </div>
                    <br />
                    <div>
                      <input
                        type="radio"
                        id="Carta de Condução"
                        name="id"
                        value="Carta de Condução"
                        checked={id === "Carta de Condução"}
                        onClick={() => setId("Carta de Condução")}
                      />
                      <label
                        style={{ marginLeft: "10px" }}
                        for="Carta de Condução"
                      >
                        Carta de Condução
                      </label>
                    </div>
                    <br />
                  </>
                ) : (
                  <>
                    <div>
                      <input
                        type="radio"
                        id="Certidão Comercial"
                        name="id"
                        value="Certidão Comercial"
                        checked={id === "Certidão Comercial"}
                        onClick={() => setId("Certidão Comercial")}
                      />
                      <label
                        style={{ marginLeft: "10px" }}
                        for="Certidão Comercial"
                      >
                        Certidão Comercial
                      </label>
                    </div>
                    <br />
                    <div>
                      <input
                        type="radio"
                        id="Alvará"
                        name="id"
                        value="Alvará"
                        checked={id === "Alvará"}
                        onClick={() => setId("Alvará")}
                      />
                      <label style={{ marginLeft: "10px" }} for="Alvará">
                        Alvará
                      </label>
                    </div>
                    <br />
                  </>
                )}
                <h5>{t("Document")}</h5>
                <br />
                {/* enter number */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Document Number")}
                    {/* <p style={{ color: "red", display: "inline" }}> *</p> */}
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    value={documentNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                  />
                </div>
                {/* upload pdf */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Attachment")}
                    {/* <p style={{ color: "red", display: "inline" }}> *</p> ( */}
                    {t("file/photo")})
                  </label>
                  <input
                    type="file"
                    required
                    accept="application/pdf, image/*"
                    className="form-control"
                    onChange={(e) => handleUploadDocument(e)}
                  />
                </div>
              </div>
              {/* errors */}
              {emptyFieldError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {t(`${emptyFieldError}`)}
                </p>
              )}
              {/* <p style={{color:"red"}}>{checkerror}</p> */}
              {/* button */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  margin: "auto",
                  marginTop: "12px",
                  width: "30%",
                }}
              >
                <button
                  onClick={() => {
                    setEmptyFieldError("");
                    setStage("1");
                  }}
                  class="btn btn-primary"
                  style={{ width: "99%", margin: "10px" }}
                >
                  {t("Back")}
                </button>
                <button
                  onClick={() => {
                    if (
                      logoImage.length < 1 ||
                      name === "" ||
                      storeName === "" ||
                      contactNumber === "" ||
                      password === "" ||
                      confirmPassword === "" ||
                      newNif === "" ||
                      !checkIBANLength(newIban) ||
                      (businessDomain === "Shop Center" && currDomain === "") ||
                      (businessDomain === "Shop Center" && regNo === "") ||
                      (businessDomain === "Shop Center" && shopCenter === "") ||
                      pinCode === "" ||
                      confirmPinCode === ""
                    ) {
                      password === confirmPassword
                        ? setEmptyFieldError(t("*All fields are mandatory"))
                        : setEmptyFieldError(
                            t("*Password and confirm password does not match.")
                          );

                      pinCode === confirmPinCode
                        ? setEmptyFieldError(t("*All fields are mandatory"))
                        : setEmptyFieldError(
                            t("*Pin Code and Confirm Pin Code does not match.")
                          );
                    } else {
                      if (accountType === "individual") {
                        if (nationality === "" || dob === "" || gender === "") {
                          setEmptyFieldError(t("*All fields are mandatory"));
                        } else {
                          if (password === confirmPassword) {
                            {
                              password.length < 8 &&
                                setEmptyFieldError(
                                  t("*Password must be 8 characters long.")
                                );
                              // : validateFields();
                            }
                          } else {
                            setEmptyFieldError(
                              t(
                                "*Password and confirm password does not match."
                              )
                            );
                          }

                          if (pinCode !== confirmPinCode) {
                            setEmptyFieldError(
                              t("*Pin Code and Confirm Pin Code do not match.")
                            );
                          } else {
                            validateFields();
                          }
                        }
                      } else {
                        if (password === confirmPassword) {
                          {
                            password.length < 8 &&
                              setEmptyFieldError(
                                t("*Password must be 8 characters long.")
                              );
                            // : validateFields();
                          }
                        } else {
                          setEmptyFieldError(
                            t("*Password and confirm password does not match.")
                          );
                        }

                        if (pinCode !== confirmPinCode) {
                          setEmptyFieldError(
                            t("*Pin Code and Confirm Pin Code do not match.")
                          );
                        } else {
                          validateFields();
                        }
                      }
                    }
                  }}
                  class="btn btn-primary"
                  disabled={loading}
                  style={{ width: "99%", margin: "10px" }}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          </div>
        ) : stage === "1" ? (
          // stage 1 (categories)
          <div
            className="type-form"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <h3 style={{ marginTop: "20px" }}>
                {t("Pick your Business Domain")}
              </h3>
              <small>{t("Note! this cannot be changed later")}</small>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
            </div>
            <div
              className="type-select text-center"
              style={{ marginTop: "10px" }}
            >
              {domainGridLoading ? (
                // <span className="spinnerContainer">
                <Spinner
                  animation="border"
                  // size="sm"
                  style={{ color: "#0d6efd" }}
                />
              ) : (
                // </span>
                <div className="grid-container">
                  {domains.map((d) => (
                    <div
                      className="item"
                      id={d.id}
                      onClick={() => {
                        setSelectedDomain(`${d._id}`);
                        setBusinessDomain(`${d.name}`);
                        setCheckerror("");
                      }}
                    >
                      {selectedDomain == d._id ? (
                        <CheckCircleOutline
                          style={{
                            position: "absolute",
                            color: "#0d6efd",
                            height: "30px",
                            width: "30px",
                            right: "10px",
                            top: "10px",
                          }}
                        />
                      ) : null}
                      <h4>
                        {
                          d.name === "Pay"
                          ? t("Pay")
                          :d.name === "Shopping"
                          ? t("Shopping")
                          :d.name === "Book"
                          ? t("Book")
                          :d.name === "Fast Food"
                          ? t("Fast Food")
                          :d.name === "Bar & Restaurant"
                          ? t("Bar & Restaurant")
                          : t("Event")
                        }
                        </h4>
                      <p>{d.description}</p>
                    </div>
                  ))}
                </div>
              )}
              <p style={{ marginTop: "20px", color: "red" }}>
                {checkerror === "select" ? "" : checkerror}
              </p>
              <button
                onClick={() => {
                  {
                    checkerror === ""
                      ? setStage("2")
                      : setCheckerror(t("*Select one type"));
                  }
                }}
                class="btn btn-primary item"
                style={{ marginTop: "2%", width: "25%" }}
              >
                {t("Next")}
              </button>
            </div>
          </div>
        ) : stage === "46" ? (
          // stage 4 (choose bank type)
          <div className="open-register" style={{ padding: "15px" }}>
            {/* <Header /> */}
            <h4 style={{ textAlign: "center", paddingTop: "20px" }}>
              {t("Enter verification code sent on your phone number")}
            </h4>
            <ReactCodeInput onChange={(e) => setverificationcode(e)} />
            <button
              onClick={(e) => {
                e.preventDefault();
                Auth.confirmSignUp(`+${contactNumber}`, verificationcode)
                  .then((res) => {
                    toggle();
                    // const notify = () =>
                    //   toast.success("User Verified Successfully", {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 5000,
                    //     hideProgressBar: true,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //   });

                    // notify();
                    // alert("user verified");
                  })
                  .catch((e) => {
                    // const notify = () =>
                    //   toast.error("Enter the correct verification code", {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 5000,
                    //     hideProgressBar: true,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //   });
                    // notify();
                    // alert("error in confirmation");
                  });
              }}
              class="btn btn-primary item"
              style={{ marginTop: "2%", width: "25%" }}
            >
              Ok
            </button>
            {/* <ToastContainer /> */}
          </div>
        ) : stage === "4" ? (
          // stage 4 (choose bank type)
          <div className="open-register" style={{ padding: "15px" }}>
            {/* <Header /> */}
            <h4 style={{ textAlign: "center", paddingTop: "20px" }}>
              {t("Choose Payment method")}
            </h4>
            <div className="choose-container">
              {/* <div
                className="account-type"
                style={{ width: "50%" }}
                id="1"
                onClick={() => {
                  setEmptyFieldError("");
                  setPaymentMethod("EXPRESS");
                  setChecked("Multicaixia Express");
                  setSelectedBank("EXPRESS");
                }}
              >
                {checked === "Multicaixia Express" ? (
                  <div className="is-checked">
                    {" "}
                    <CheckCircleOutline />
                  </div>
                ) : null}
                <p style={{ cursor: "pointer" }}>{t("Multicaixia Express")}</p>
              </div> */}
              <div
                className="account-type"
                style={{ width: "50%" }}
                id="2"
                onClick={() => {
                  setPaymentMethod("BANK_TRANSFER");
                  setEmptyFieldError("");
                  setChecked("Bank Transfer");
                  setSelectedBank("Bank Transfer");
                }}
              >
                {checked === "Bank Transfer" ? (
                  <div className="is-checked">
                    <CheckCircleOutline />
                  </div>
                ) : null}
                <p style={{ cursor: "pointer" }}>{t("Bank Transfer")}</p>
              </div>
              <div
                className="account-type"
                style={{ width: "50%" }}
                id="2"
                onClick={() => {
                  setPaymentMethod("BEW");
                  setEmptyFieldError("");
                  setChecked("BEW");
                  setSelectedBank("BAYQI E-WALLET");
                }}
              >
                {checked === "BEW" ? (
                  <div className="is-checked">
                    <CheckCircleOutline />
                  </div>
                ) : null}
                <p style={{ cursor: "pointer" }}>BAYQI E-WALLET</p>
              </div>
            </div>
            <p style={{ color: "red", fontSize: "13px" }}>{emptyFieldError}</p>
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                type="onClick"
                onClick={() => {
                  setEmptyFieldError("");

                  setStage("3");
                }}
                className="btn btn-primary continue-btn"
                style={{
                  margin: "15px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {t("Back")}
              </button>
              <button
                type="onClick"
                onClick={() => {
                  checked === ""
                    ? setEmptyFieldError(t("*select one type"))
                    : setStage("5");
                }}
                className="btn btn-primary continue-btn"
                style={{
                  margin: "15px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {t("Next")}
              </button>
            </div>
            <div style={{ width: "20%", marginBottom: "12px" }}></div>
          </div>
        ) : stage === "5" ? (
          // stage 5 (payment)
          <div>
            <p style={{ textAlign: "center" }}>
              <b>
                {t("Payment method")}: {selectedBank}
              </b>
            </p>
            <br />
            <br />
            {checked === "Multicaixia Express" ? (
              <div className="Multicaxia">
                <div className="col-1-frm" style={{ marginTop: "30px" }}>
                  <div
                    style={{
                      wdith: "70%",
                      flexDirection: "column",
                      marginLeft: "30px",
                    }}
                  >
                    <h5>
                      {accountType === "individual" ? "1,000 AKZ" : "5,000 AKZ"}
                    </h5>
                    <input
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      style={{ width: "100%" }}
                      type="number"
                      onInput={(event) =>
                        event.target.value.length > 9
                          ? (event.target.value = event.target.value.slice(
                              0,
                              9
                            ))
                          : event.target.value
                      }
                      placeholder={t("Enter 9 digit Number")}
                      value={expressPhno}
                      onChange={(e) => {
                        setEmptyFieldError("");
                        setLoading(false);
                        setexpressPhno(e.target.value);
                      }}
                      
                    />
                    <p style={{ color: "red" }}>{t(`${emptyFieldError}`)}</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "10px",
                    marginTop: "60px",
                  }}
                >
                  <button
                    onClick={() => {
                      setLoading(false);
                      setEmptyFieldError("");
                      setStage("4");
                    }}
                    class="btn-frm btn-primary"
                    style={{
                      width: "15%",
                      height: "40px",
                      border: "none",
                      borderRadius: "6px",
                      margin: "10px",
                    }}
                  >
                    {t("Back")}
                  </button>

                  <button
                    disabled={loading}
                    class="btn-frm btn-primary"
                    onClick={(e) => {
                      setLoading(true);
                      if (expressPhno === "") {
                        setLoading(false);
                        setEmptyFieldError(t("*Enter Phone number"));
                      } else {
                        if (expressPhno.length === 9) {
                          // TODO: setStage should be called in CreateStore func after successful response from amplify
                          // setStage("46");
                          CreateStore(e);
                        } else {
                          setLoading(false);
                          setEmptyFieldError(t("*Enter 9 digit phone number."));
                        }

                        // setEmptyFieldError("")
                        //   {expressPhno.length === 9 ? CreateStore(e) : setEmptyFieldError("*Enter 9 digit phone number.")
                        //   {emptyFieldError === ''? setEmptyFieldError(e.message) : setEmptyFieldError("") }
                        //   setLoading(false)}
                        //   //
                        // }
                      }
                    }}
                    style={{
                      width: "15%",
                      height: "40px",
                      border: "none",
                      borderRadius: "6px",
                      margin: "10px",
                      background:"#0d6efd"
                    }}
                  >
                    {loading && (
                      <div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                      </div>
                    )}
                    {!loading && (
                      <p style={{ color: "white", padding: "0", margin: "0" }}>
                        {t("Create")}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            ) : checked === "BEW" ? (
              <div className="bankAccount">
                <div className="col-1-frm">
                  <div
                    style={{
                      with: "70%",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "30px",
                    }}
                  > 
                    <p>
                    Realize o seu pagamento através da carteira
                    eletrónica BayQi. Faça o download da aplicação
                    BayQi na Play Store ou Apple Store, escolha a
                    opção "Pagar", clique sobre o logótipo da BayQi e
                    efetue o pagamento com segurança. Pode também
                    digitalizar o código QR da BayQi para concluir a
                    transação. Ao efetuar o pagamento com a carteira
                    eletrónica BayQi, não será necessário enviar-nos o
                    comprovativo de pagamento.
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                >
                  {t(`${emptyFieldError}`)}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "10px",
                    marginTop: "60px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setStage("4");
                    }}
                    class="btn-col-1-frm btn-primary"
                    style={{
                      backgroundColor: "#0C9CFB",
                      border: "none",
                      width: "30%",
                      margin: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {t("Back")}
                  </Button>

                  <Button
                    disabled={loading}
                    class="btn-col-1-frm btn-primary"
                    style={{
                      backgroundColor: "#0C9CFB",
                      border: "none",
                      width: "30%",
                      height: "40px",
                      margin: "10px",
                      color: "white !important",
                      boxShadow: "none",
                    }}
                    onClick={(e) => {
                      setLoading(true);
                      CreateStore(e);
                    }}
                  >
                    {loading && (
                      <div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                      </div>
                    )}
                    {/* {loading && <span>Creating User</span>} */}
                    {!loading && <p>{t("Create")}</p>}
                  </Button>
                </div>
              </div>
            )
             : (
              <div className="bankAccount">
                <div className="col-1-frm">
                  <div
                    style={{
                      with: "70%",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "30px",
                    }}
                  > <p>
                      Por favor, efetue o seu pagamento por transferência bancária ou
                      depósito direto na conta PagaQi com as seguintes informações:
                    </p>
                    <p>
                      <b>Banco:</b> BNI
                    </p>
                    <p>
                      <b>Número Conta:</b> 03354152310003
                    </p>
                    <p>
                      <b>IBAN:</b> AO06 005200003354152310371
                    </p>
                    <p>
                      Utilize o NOME DA LOJA como referência da transferência ou depósito
                      e envie um email para <a href=""> pagamento@bayqi.com </a>confirmando o
                      pagamento juntamente com o comprovativo.
                    </p>
                    <p>
                      Por favor, verifique as informações acima e efetue o pagamento
                      corretamente.
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                >
                  {t(`${emptyFieldError}`)}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "10px",
                    marginTop: "60px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setStage("4");
                    }}
                    class="btn-col-1-frm btn-primary"
                    style={{
                      backgroundColor: "#0C9CFB",
                      border: "none",
                      width: "30%",
                      margin: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {t("Back")}
                  </Button>

                  <Button
                    disabled={loading}
                    class="btn-col-1-frm btn-primary"
                    style={{
                      backgroundColor: "#0C9CFB",
                      border: "none",
                      width: "30%",
                      height: "40px",
                      margin: "10px",
                      color: "white !important",
                      boxShadow: "none",
                    }}
                    onClick={(e) => {
                      setLoading(true);
                      CreateStore(e);
                    }}
                  >
                    {loading && (
                      <div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                        <div
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></div>
                      </div>
                    )}
                    {/* {loading && <span>Creating User</span>} */}
                    {!loading && <p>{t("Create")}</p>}
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          // stage 3 (address)
          <div className="info-form-form" style={{ paddingLeft: "30px" }}>
            <div className="form">
              <h5>{t("Address Info")}</h5>
              {/* address */}
              <div className={``}>
                <label
                  htmlFor="addressInput"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {t("Address")}
                  <p style={{ color: "red", display: "inline" }}> *</p>
                </label>
                <br />
                <textarea
                  value={address}
                  onChange={(e) => {
                    setEmptyFieldError("");
                    setAddress(e.target.value);
                  }}
                  id="addressInput"
                  rows="5"
                  maxLength={"256"}
                  style={{ maxWidth: "92%", width: "92%", padding: "10px" }}
                />
              </div>
              <div className="grid-container-form">
                {/* Province dropdown */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("Province")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <Select
                    options={provinces}
                    onChange={({ value }) => {
                      setEmptyFieldError("");
                      setCurrProvince(value);

                      // selectInputRef.current.clearValue();
                      setCurrCities(
                        citiesOfProvinces.find(
                          (item) => item.province === value
                        ).cities
                      );
                    }}
                  />
                </div>
                {/* Cities dropdown */}
                <div className={`grid-item form-group business-input`}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {t("City")}
                    <p style={{ color: "red", display: "inline" }}> *</p>
                  </label>
                  <Select
                    // ref={selectInputRef}

                    options={currCities}
                    onChange={(value) => {
                      setEmptyFieldError("");
                      setCity(value.value);
                    }}
                  />
                </div>
              </div>

              {/* errors here */}
              {emptyFieldError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {t(`${emptyFieldError}`)}
                </p>
              )}
              {/* buttons */}
              {/* <button onClick={() => setStage("4")}>Next</button>
            <button onClick={() => setStage("2")}>Back</button> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  margin: "auto",
                  marginTop: "12px",
                  width: "30%",
                }}
              >
                <button
                  onClick={() => {
                    setCity("");
                    setCurrCities("");
                    setEmptyFieldError("");

                    setStage("2");
                  }}
                  class="btn btn-primary"
                  style={{ width: "99%", margin: "10px" }}
                >
                  {t("Back")}
                </button>
                <button
                  onClick={() => {
                    if (
                      address === "" ||
                      currProvince === "" ||
                      currCity === ""
                    ) {
                      address !== "" || currProvince !== ""
                        ? setEmptyFieldError(t("*Select Province and city"))
                        : setEmptyFieldError(t("*All fields are mandatory"));
                    } else {
                      setStage("4");
                    }
                  }}
                  class="btn btn-primary"
                  style={{ width: "99%", margin: "10px" }}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
