// import axios from "./axiosInterceptor";
import axios from "./axiosInterceptor";

export const addSeller = (sellerBody) => axios.post(`/sellers`, sellerBody);

export const updateSellerData = (body) =>
  axios.put(`/sellers?sellerId=${localStorage.getItem("uid")}`, body);


export const getSellerData = () =>
  axios.get(`/sellers/${localStorage.getItem("uid")}`);

export const getSignedInUserData = (uid) => axios.get(`/sellers/${uid}`);

export const resetPin = (body) => axios.post(`/sellers/reset-pin`, body);

export const forgotPin = (body) => axios.post(`/sellers/forgot-pin`, body);

export const changePin = (body) => axios.put(`/sellers/change-pin`, body);