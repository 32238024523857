import React, { useState } from "react";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { WhatsappShareButton } from "react-share";
import { BsWhatsapp } from "react-icons/bs";

import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import { blue } from "../../Globals/colors";
import {
  getUpdatedData,
} from "../../../api/generateLInks/generateLinks";
// import { getUpdatedData } from "../../../api/sellers/sellers";
import DeleteLinkModal from "./DeleteLinkModal";
import UpdateLinkModal from "./UpdateLinkModal";
import { useTranslation } from "react-i18next";
import AddContentModal from "./AddContentModal";


export default function GenerateLink() {
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t, i18n } = useTranslation();
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [delLinkId, setDelLinkId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [updateItem, setUpdateItem] = useState({});

  const toggleUpdateModal = (isRefresh = false) => {
    setUpdateModal(!updateModal);
    if (isRefresh) {
      updateData();
    }
  };

  const addToggle = (isRefresh = false) => {
    setAddModal(!addModal);
    if (isRefresh) {
      updateData();
    }
  };

  const toggleDeleteModal = (isRefresh = false) => {
    setDelModal(!delModal);

    if (isRefresh) {
      updateData();
    }
  };

  React.useEffect(() => {
    updateData();
  }, [limit, activePage, startDate, endDate]);

  // this gets called when an entry is created successfully, to get latest data
  const updateData = () => {
    setLoading(true);
    getUpdatedData(limit, activePage, startDate, endDate)
      .then((res) => {
        
        setTableData(res.data.data);
        setLoading(false);
        
        let num = res.data.total.noOfRecord / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((err) => {
        setLoading(false);
        // 
      });
  };

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={6}
            lg={6}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Generate Links")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  // setLimit(10);
                  // if (e.target.value === "") setStartDate(today);
                  // else setStartDate(e.target.value);
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  // setLimit(10);
                  // if (e.target.value === "") setEndDate(today);
                  // else setEndDate(e.target.value);
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <Button
              style={{ backgroundColor: blue, border: "none" }}
              onClick={addToggle}
            >
              {t("Add")}
            </Button>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Date & Time")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Product/Service")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Price")}
              </th>
              <th
                style={{
                  padding: "20px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {t("Link")}
              </th>
              <th colSpan={3} style={{ padding: "20px", fontSize: "14px" }}></th>
            </tr>
          </thead>
          {tableData.length > 0 ? (
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setUpdateItem(item.product[0]);
                    toggleUpdateModal();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {" "}
                    {new Date(item.createdAt).toLocaleDateString()}
                    {" - "}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td style={{ padding: "20px" }}>{item.product[0]?.name}</td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.product[0]?.price)} AKZ
                  </td>
                  <td style={{ padding: "20px", textAlign: "center" }}>
                    {item.link}
                  </td>

                  <td
                    onClick={(e) => e.stopPropagation()}
                    style={{ padding: "20px", textAlign: "center" }}
                  >
                    <WhatsappShareButton
                      url={item.link}
                      title={item.product[0]?.name}
                      separator=":: "
                    >
                      <BsWhatsapp size={32}></BsWhatsapp>
                    </WhatsappShareButton>
                  </td>

                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(item.link);
                    }}
                    style={{
                      padding: "20px",
                      color: "#049EFF",
                      textDecoration: "underline",
                    }}
                  >
                    {t("Copy")}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      setDelLinkId(item._id);
                      toggleDeleteModal();
                    }}
                    style={{
                      padding: "20px",
                      color: "red",
                      textDecoration: "underline",
                    }}
                  >
                    {t("Delete")}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={1000}>
                  <div className="noDataContainer">
                    <img src={noDataFound} width="200px" alt="" />
                    <p>{t("No data available")}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      )}
      {tableData.length <= 0 ? null : (
        <div>
          <Row style={{ display: "block", paddingBottom: "0px" }}>
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                >
                  <Pages
                    noOfPages={noOfPages}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <UncontrolledDropdown style={{ width: "100%" }}>
                    <DropdownToggle
                      style={{
                        backgroundColor: "#fff",
                        color: "#333",
                        borderColor: "#CED4DA",
                        fontSize: "0.9rem",
                        width: "100%",
                        margin: "auto",
                      }}
                      caret
                    >
                      <span
                        style={{
                          color: "#333",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("Items per page")}
                      </span>
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {limit}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ marginTop: "5px" }}>
                      <DropdownItem
                        onClick={() => {
                          setLimit(1);
                          setActivePage(1);
                        }}
                      >
                        1
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setLimit(5);
                          setActivePage(1);
                        }}
                      >
                        5
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setLimit(10);
                          setActivePage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      )}

      <Modal centered isOpen={updateModal} toggle={toggleUpdateModal}>
        <ModalHeader toggle={toggleUpdateModal}>
          {t("Update Link Details")}
        </ModalHeader>
        <ModalBody>
          <UpdateLinkModal toggle={toggleUpdateModal} item={updateItem} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      <Modal centered size="sm" isOpen={delModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          {t("Confirm Delete Link")}
        </ModalHeader>
        <ModalBody>
          <DeleteLinkModal
            toggle={toggleDeleteModal}
            deleteLinkId={delLinkId}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* add link modal */}
      <Modal isOpen={addModal} toggle={addToggle}>
        <ModalHeader toggle={addToggle}>{t("Add Link")}</ModalHeader>
        <ModalBody>
          <AddContentModal toggle={addToggle} updateData={updateData} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
