import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Input } from "reactstrap";
import { baseUrl } from "../../Helpers/helper";

import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateEvent } from "../../../api/events/events";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { Storage } from "aws-amplify";
import { RegionDropdown } from "react-country-region-selector";
import { useTranslation } from "react-i18next";

const EventDetailModal = ({
  toggle,
  getUpdatedEvents,
  venues,
  currEventData,
  approvedEventTypes,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const [eventName, setEventName] = useState(currEventData.eventName);
  const [path, setPath] = useState(currEventData.image);
  const [imageUrl, setImageUrl] = useState("");
  const [imgUploaded, setImgUploaded] = useState(true);

  const [eventDescription, setEventDescription] = useState(
    currEventData.eventDescription
  );
  const [startDate, setStartDate] = useState(currEventData.startDate);
  const [endDate, setEndDate] = useState(currEventData.endDate);
  const [startTime, setStartTime] = useState(
    currEventData.timing.split("-")[0].trim()
  );
  const [endTime, setEndTime] = useState(
    currEventData.timing.split("-")[1].trim()
  );
  const [entryFees, setEntryFees] = useState(currEventData.entryFees);

  const [category, setCategory] = useState({
    label: currEventData.category,
    value: currEventData.category,
  });

  const [address, setAddress] = useState(currEventData.address);
  const [province, setProvince] = useState(currEventData.province);
  const [catOptions, setCatOptions] = useState([]);

  const [options, setOptions] = useState([]);
  // inital place and id set to pre filled data
  const [eventPlace, setEventPlace] = useState(currEventData.eventPlace);

  // ticket type related states
  // ticket types
  const [types, setTypes] = useState(currEventData.ticketTypes);
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [deliveryOptions, setDeliveryOptions] = useState(null);

  // called on img upload
  const onFileChange = async (e) => {
    setImgUploaded(false);
    const filePath = URL.createObjectURL(e.target.files[0]);
    setPath(filePath);
    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
          setImgUploaded(true);
        }
      );
    } catch (error) {
      setImgUploaded(true);
      
    }
  };
  const [showDeleteText, setShowDeleteText] = useState(false);

  const deleteImage = async () => {
    // TODO: delete image from amplify too

    // 
    // const res = await Storage.remove(path.split("public/")[1]);
    // 
    setPath(null);
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            const deliveryArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
              deliveryArray.push(item._id);
            });
            setDeliveryMethod(deliveryArray);
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  React.useEffect(() => {
    getMethods();

    if (approvedEventTypes.length > 0) {
      approvedEventTypes.map((item) => {
        setCatOptions((oldArr) => [
          ...oldArr,
          { label: item.title, value: item.title },
        ]);
      });
    }

    // eventplaces state being filled with all available places data
    // venues.map((item) =>
    //   setOptions((oldArr) => [
    //     ...oldArr,
    //     { label: item.placeName, value: item._id },
    //   ])
    // );
  }, []);

  const updateEventMethod = () => {
    setLoading(true);
    setError("");
    if (
      path === currEventData.image &&
      startDate === currEventData.startDate &&
      endDate === currEventData.endDate &&
      startTime === currEventData.timing.split("-")[0].trim() &&
      endTime === currEventData.timing.split("-")[1].trim() &&
      eventName === currEventData.eventName &&
      eventDescription === currEventData.eventDescription &&
      // entryFees === currEventData.entryFees &&
      types === currEventData.ticketTypes &&
      category.value === currEventData.category &&
      eventPlace === currEventData.eventPlace &&
      deliveryMethod === currEventData.deliveryMethods
    ) {
      setError(`*${t("Change any field to update record")}.`);
      setLoading(false);
    } else if (path === null) {
      setError(`*${t("Image can not be empty")}`);
      setLoading(false);
    } else {
      let body = {};
      if (imageUrl !== "") {
        body.image = imageUrl;
      }
      if (startDate !== currEventData.startDate) {
        body.startDate = startDate;
      }
      if (endDate !== currEventData.endDate) {
        body.endDate = endDate;
      }
      if (startTime !== currEventData.timing.split("-")[0].trim()) {
        body.timing = `${startTime} - ${endTime}`;
      }
      if (endTime !== currEventData.timing.split("-")[1].trim()) {
        body.timing = `${startTime} - ${endTime}`;
      }
      if (eventName !== currEventData.eventName) {
        body.eventName = eventName;
      }
      if (eventDescription !== currEventData.eventDescription) {
        body.eventDescription = eventDescription;
      }
      if (entryFees !== currEventData.entryFees) {
        body.entryFees = entryFees;
      }
      if (category.value !== currEventData.category) {
        body.category = category.value;
      }
      if (types !== currEventData.ticketTypes) {
        body.ticketTypes = types;
      }
      if (eventPlace !== currEventData.eventPlace) {
        body.eventPlace = eventPlace;
      }
      if (address !== currEventData.address) {
        body.address = address;
      }
      if (province !== currEventData.province) {
        body.province = province;
      }

      // body.deliveryMethods = deliveryMethod;

      
      // setLoading(false);
      updateEvent(currEventData._id, body)
        .then((res) => {
          setLoading(false);
          toggle();
          getUpdatedEvents();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };
  const provinces=localStorage.getItem("province");

  return (
    <div style={{ padding: "0px 30px" }}>
      <Row>
        <Col>
          <label className="inpLblTxt">{t("Image")}</label>
        </Col>
      </Row>
      {path ? (
        <>
          <img
            alt=""
            src={path}
            onClick={deleteImage}
            width="65px"
            height="65px"
            style={{ borderRadius: "8px", cursor: "pointer" }}
            onMouseEnter={() => setShowDeleteText(true)}
            onMouseLeave={() => setShowDeleteText(false)}
          />
          <p
            style={{
              visibility: !showDeleteText ? "hidden" : "visible",
              color: "red",
              fontSize: "12px",
            }}
          >
            *{t("click to delete")}
          </p>
        </>
      ) : (
        <>
          <input
            style={{ display: "none" }}
            id="imageUpload"
            type="file"
            onChange={(e) => onFileChange(e)}
          />
          <label htmlFor="imageUpload" className="imgUploadLbl">
            <span>+</span>
            <p>{t("Add Image")}</p>
          </label>
        </>
      )}
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label className="inpLblTxt">{t("Event place")}</label>
          <br />
          {/* <Select
                          options={options}
                          value={eventPlace}
                          onChange={(value) => setEventPlace(value)}
                    /> */}
          <input
            value={eventPlace}
            onChange={(e) => setEventPlace(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Event Venue"
            className="inputField"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="inpLblTxt">
            {t("Province")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <input
              value={provinces}
             disabled
              style={{ width: "100%" }}
              placeholder={provinces}
              className="inputField"
            />
          {/* <RegionDropdown
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            country={"Angola"}
            value={province}
            onChange={(val) => {
              setProvince(val);
            }}
          /> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="inpLblTxt">
            {t("Address")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <Input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="textarea"
            style={{ width: "100%" }}
            placeholder="Address"
            // className="inputField"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label className="inpLblTxt">{t("Name")}</label>
          <br />
          <input
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Event name"
            className="inputField"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label className="inpLblTxt">{t("Description")}</label>
          <br />
          <Input
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
            type="textarea"
            style={{ width: "100%" }}
            placeholder="Description"
            className="inputField"
          />
        </Col>
      </Row>
      <Row className="d-flex">
        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("Start date")}</label>
          <br />
          <input
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="inputField"
            type="date"
            style={{ width: "100%" }}
          />
        </Col>

        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("End date")}</label>
          <br />
          <input
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="inputField"
            type="date"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="d-flex">
        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("Start time")}</label>
          <br />
          <input
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="inputField"
            type="time"
            style={{ width: "100%" }}
          />
        </Col>

        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("End time")}</label>
          <br />
          <input
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="inputField"
            type="time"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      {types.length > 0 && (
        <label className="inpLblTxt">{t("Tickets List")}</label>
      )}
      {types.length > 0 &&
        types.map((item) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "lightgray",
              margin: "0px",
              marginBottom: "4px",
            }}
          >
            <p
              style={{
                margin: "0px",
                padding: "4px",
                fontSize: "14px",
                color: "gray",
                display: "inline",
              }}
            >
              {item.ticketType}{" "}
              {new Intl.NumberFormat().format(item.ticketPrice)} AKZ{" "}
              {item.quantity && ` - ${item.quantity} Seats`}
            </p>

            <AiFillDelete
              onClick={() => {
                let filteredTypes = types.filter((curr) => curr !== item);
                setTypes(filteredTypes);
              }}
              style={{
                color: "red",
                cursor: "pointer",
                marginRight: "6px",
              }}
              size={"1.2rem"}
            />
          </div>
        ))}
      <Row className="d-flex">
        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("Ticket type")}</label>
          <br />
          <input
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="inputField"
            type="text"
            style={{ width: "100%" }}
          />
        </Col>

        <Col className="sm-col-12 md-col-6">
          <label className="inpLblTxt">{t("Ticket price")}</label>
          <br />
          <input
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="inputField"
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="inpLblTxt">{t("Total Seats")}</label>
          <br />
          <input
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="inputField"
            type="number"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <button
        onClick={() => {
          setTypes((oldArr) => [
            ...oldArr,
            {
              ticketType: type,
              ticketPrice: price,
              quantity: parseInt(quantity),
            },
          ]);
          setType("");
          setPrice("");
          setQuantity("");
          
        }}
        disabled={price === "" || type === "" || quantity === ""}
      >
        {t("add")}
      </button>

      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label className="inpLblTxt">{t("Event Type")}</label>
          <br />
          {/* <input
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            type="text"
            style={{ width: "100%" }}
            placeholder="Category"
            className="inputField"
          /> */}

          <Select
            value={category}
            onChange={(value) => setCategory(value)}
            options={catOptions}
          />
        </Col>
      </Row>

      {error && (
        <p
          style={{
            color: "red",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          {t(`${error}`)}
        </p>
      )}

      <Button
        onClick={updateEventMethod}
        disabled={loading || !imgUploaded}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading || !imgUploaded ? (
          <Spinner
            animation="border"
            style={{ color: "white", padding: "9px" }}
            size="sm"
          />
        ) : (
          t("Update")
        )}
      </Button>
    </div>
  );
};

export default EventDetailModal;
