import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Redux/auth/authSlice";
// persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const reducers = combineReducers({
  auth: authReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

//  RTK's configureStore function automatically sets up the thunk middleware by default
export const store = configureStore({
  // this will automatically call combine reducers
  reducer: persistedReducer,
});















// // older working code without persist
// import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "../Redux/auth/authSlice";

// //  RTK's configureStore function automatically sets up the thunk middleware by default
// export const store = configureStore({
//   // this will automatically call combine reducers
//   reducer: {
//     auth: authReducer,
//   },
// });
