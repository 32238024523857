import axios from "./axiosInterceptor";

export const getCategoriesListbyName = (
  debounceSearch,
  limit,
  type,
  activePage
) =>
  axios.get(
    `/categories?name=${debounceSearch}&limit=${limit}&domain=${type}&page=${activePage}`
  );

export const getApprovedEventCategories = () =>
  axios.get(`/categories?approved=approved&domain=Event&page=`);

export const getAllCategories = () => axios.get(`/categories`);

export const getCategoryByName = (search) =>
  axios.get(`/categories?name=${search}`);

export const getCategoriesList = (id) =>
  axios.get(`/categories?parent=${id}&domain=Library`);

export const getParentCategory = (parentId) =>
  axios.get(`/categories/${parentId}`);

export const getCategoriesListbyDomain = (id, domain) =>
  axios.get(`/categories?parent=${id}&domain=${domain}`);

export const getCategoriesListbyDomainShopping = (id) =>
  axios.get(`/categories?parent=${id}&domain=Shopping`);

export const updateCategory = (id, body) =>
  axios.put(`/categories/${id}`, body);

export const addCategory = (body) => axios.post(`/categories`, body);

export const deleteCategory = (id) => axios.delete(`/categories/${id}`);
