import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { TextareaAutosize } from "@material-ui/core";
import "./Products.scss";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { addProduct } from "../../../api/products/products";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

export default function AddContentModal({ toggle }) {
    const [path, setPath] = useState(null);
    const [imgPaths, setImgPaths] = useState([]);
    const { t, i18n } = useTranslation();
    // const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    var o = [];
    var c = 0;
    var [count, setCount] = useState([]);
    var [isDisabled, setIsDisabled] = useState(true);
  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [prodImages, setProdImages] = useState([]);
    const [prodCategories, setProdCategories] = useState([]);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [sku, setSku] = useState("");
    const [qty, setQty] = useState("");
    const [description, setDescription] = useState("");
    const [brand, setBrand] = useState("");
    // modal types
    const [modalType, setModalType] = useState("addProduct");
    const [productUrl, setProductUrl] = useState("");
    const [copied, setCopied] = useState(false);
    const [showDeleteText, setShowDeleteText] = useState(false);
  
    const [isDisabledDelivery, setIsDisabledDelivery] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
  
    //using s3 bucket
    const onFileChange = async (e) => {
      setLoading(true);
      const files = e.target.files;
      try {
        const result = await Storage.put(files[0].name, files[0], {
          contentType: "image/jpeg", // contentType is optional
        });
        // 
        fetchImageUrl(result);
        setLoading(false);
      } catch (error) {
        
      }
  
      for (let i = 0; i < files.length; i++) {
        const filePath = URL.createObjectURL(files[i]);
        setImgPaths((imgPaths) => [...imgPaths, filePath]);
      }
    };
  
    const fetchImageUrl = async (key) => {
      const Url = `${process.env.REACT_APP_S3_URL}/public/${key.key}`;
      setProdImages([...prodImages, Url]);
    };
  
    React.useEffect(() => {
      getCategories("");
      getMethods();
    }, []);
  
    const getCategories = (id) => {
      if (id === "") {
        id = "null";
      }
  
      const domain = localStorage.getItem("domain");
      let catDomain = "";
      if (domain == "Book") {
        catDomain = "Library";
      }
      if (domain == "Fast Food" || domain == "Bar & Restaurant") {
        catDomain = "Food and Restaurant";
      }
      getCategoriesListbyDomain(id, catDomain)
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setIsDisabled(false);
            } else {
              data.map((item) => {
                o.push({ value: item._id, label: item.title });
              });
              setOptions(o);
              c++;
              setCount([...count, c]);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    const getMethods = () => {
      getDeliveryMethods()
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setIsDisabledDelivery(true);
            } else {
              const tempArray = [];
              const deliveryArray = [];
              data.map((item) => {
                deliveryArray.push(item._id);
                tempArray.push({ value: item._id, label: item.title });
              });
              setDeliveryMethod(deliveryArray);
              setDeliveryOptions(tempArray);
              setIsDisabledDelivery(false);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    const SaveProduct = async (e) => {
      e.preventDefault();
      setError("");
      setLoading(true);
  
      if (
        title === "" ||
        prodImages.length < 1 ||
        description === "" ||
        deliveryMethod.length === 0 ||
        sku === "" ||
        prodCategories.length < 1 ||
        price === "" ||
        qty === ""
      ) {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        const productBody = {
          Name: title,
          Images: prodImages,
          Description: description,
          deliveryMethod: deliveryMethod,
          SKU: sku,
          StoreId: localStorage.getItem("uid"),
          CategoryId: prodCategories,
          Price: parseInt(price),
          Qty: parseInt(qty),
          productType: "shopping",
        };
  
        addProduct(productBody)
          .then((res) => {
            
            setProductUrl(
              `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`
            );
            // update product with the url
            updateProduct(res.data.data.insertedId, {
              url: `https://bayqi-customer-web-app.web.app/products/${res.data.data.insertedId}`,
            })
              .then((res) => {
                
                setModalType("productCreated");
                setLoading(false);
              })
              .catch((err) => 
            {});
          })
          .catch((err) => {
            setLoading(false);
            alert(err);
          });
      }
    };
  
    const onDeleteImage = async (file) => {
      file = file.split("public/")[1];
      
      // setLoading(true)
  
      try {
        await Storage.remove(file).then((res) => {
          
          // setLoading(false)  
        });
      } catch (e) {
        // setLoading(false)
        
      }
    };
  
    
      return (
        <div style={{ padding: "0px 30px" }}>
          <Row className="d-flex flex-column">
            <Col className="col-12 inpLblTxt">
              {t("Product images")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </Col>
          </Row>
  
          {path && <img alt="" src={path} width="400px" />}
  
          <div className="d-flex py-3">
            {imgPaths[0] &&
              imgPaths.map((path, index) => (
                <div className="d-flex flex-column">
                  <img
                    alt={""}
                    style={{ marginRight: "10px" }}
                    key={index}
                    src={path}
                    width={67}
                    height={67}
                    onClick={() => {
                      const newImages = imgPaths.filter((img) => img != path);
                      setImgPaths(newImages);
                      onDeleteImage(path);
                    }}
                    onMouseEnter={() => setShowDeleteText(true)}
                    onMouseLeave={() => setShowDeleteText(false)}
                  />
                </div>
              ))}
            {imgPaths.length > 0 && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  visibility: !showDeleteText ? "hidden" : "visible",
                }}
              >
                {t("*click to delete")}
              </p>
            )}
            <input
              style={{ display: "none" }}
              id="imageUpload"
              type="file"
              onChange={(e) => onFileChange(e)}
            />
            <label
              style={{ visibility: imgPaths.length >= 3 ? "hidden" : "initial" }}
              htmlFor="imageUpload"
              className="imgUploadLbl"
            >
              +
            </label>
          </div>
          <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Title")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id="Title"
              />
            </Col>
  
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Price")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">
                SKU<p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                id="Title"
              />
            </Col>
  
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Quantity")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                style={{ width: "100%" }}
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          </Row>
  
          {/* <Row className="d-flex justify-content-between">
            <Col className="col-6">
              <label className="inpLblTxt">
                Brand name<p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                className="inputField"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                id="Title"
              />
            </Col>
          </Row> */}
  
          <Row style={{ marginTop: "2%" }}>
            <Col md={12}>
              <label htmlFor="lastName" className="inpLblTxt">
                {t("Description")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <TextareaAutosize
                className="inputTxtarea"
                onChange={(e) => setDescription(e.target.value)}
                minRows={3}
              />
            </Col>
          </Row>
  
          {count.map((i) => (
            <div style={{ marginBottom: "10px" }}>
              <Select
                onChange={(e) => {
                  setProdCategories([...prodCategories, e.value]);
                  getCategories(e.value);
                }}
                options={options}
                isSearchable
                placeholder={t("Select Category...")}
              />
            </div>
          ))}
          {/* <Col>
            <Row>
              <label className="inpLblTxt">Delivery Method</label>
            </Row>
            <Row>
              <Select
                isMulti
                isSearchable
                onChange={(e) => {
                  let ids = e.map((a) => a.value);
                  setDeliveryMethod(ids);
                }}
                isDisabled={isDisabledDelivery}
                options={deliveryOptions}
                placeholder="Select Delivery Methods"
              />
            </Row>
          </Col> */}
          {error && (
            <p style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</p>
          )}
          <Button
            onClick={(e) => SaveProduct(e)}
            disabled={isDisabled || loading || isDisabledDelivery}
            style={{
              margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Save")
            )}
          </Button>
          {isDisabledDelivery && (
            <Row>
              <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
                {t(
                  "Please create a delivery option before creating a payment link*"
                )}
              </p>
            </Row>
          )}
        </div>
      );
}

