import axios from "./axiosInterceptor";
export const getEcomPaymentTransactions = (
  limit,
  selectedStatus,
  activePage,
  selectedMethod,
  startDate,
  endDate,
  transactionId,
  debounceSearch
) =>
  axios.get(
    `/transactions/history?limit=${limit}&orderStatus=${selectedStatus}&page=${activePage}&method=${selectedMethod}&sellerId=${localStorage.getItem(
      "uid"
    )}&startDate=${startDate}&endDate=${endDate}&transactionId=${debounceSearch}&transactionType=MARKET_PLACE`
  );

export const getLinkPaymentTransactions = (
  limit,
  activePage,
  selectedStatus,
  startDate,
  endDate
) =>
  axios.get(
    `/transactions/history?limit=${limit}&page=${activePage}&sellerId=${localStorage.getItem(
      "uid"
    )}&orderStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}&transactionType=PAYMENT_PER_LINK`
  );

  export const updateTransaction = (item, body) =>
  axios.put(`/orders/history?orderId=${item._id}`, body);

  export const getQrCatalogTransactions = (
    limit,
    activePage,
    startDate,
    endDate
  ) =>
    axios.get(
      `/order?limit=${limit}&page=${activePage}&sellerId=${localStorage.getItem(
        "uid"
      )}&transactionType=QR_CATALOGE_SCAN&startDate=${startDate}&endDate=${endDate}`
    );