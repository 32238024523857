import axios from "./axiosInterceptor";

export const getTotalSales = (startDate,
  endDate) =>
  axios.get(`/kpis/sales?sellerId=${localStorage.getItem("uid")}&startDate=${startDate}&endDate=${endDate}`);

export const getTotalOrders = (startDate,
  endDate) =>
  axios.get(`/kpis/orders?sellerId=${localStorage.getItem("uid")}&startDate=${startDate}&endDate=${endDate}`);

export const getTotalPayTransactions = (startDate,
  endDate) =>
  axios.get(`/kpis/pay?sellerId=${localStorage.getItem("uid")}&startDate=${startDate}&endDate=${endDate}`);

export const getSellerCaptiveBalance = (startDate,
  endDate) =>
  axios.get(
    `/kpis/seller-captive-balance?sellerId=${localStorage.getItem("uid")}&startDate=${startDate}&endDate=${endDate}`
  );

export const getSellerBalance = () =>
  axios.get(`/kpis/sellers-balance?sellerId=${localStorage.getItem("uid")}`);
