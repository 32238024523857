import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../Helpers/helper";
import { updateTransaction } from "../../../api/orders/orders";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TransactionInvoice from "./TransactionInvoice";
import { useTranslation } from "react-i18next";

const LinkPaymentDetailsModal = ({ item, toggle }) => {
  const [deliveryCode, setDeliveryCode] = useState("");
  const [status, setStatus] = useState(item.status);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const updateOrder = () => {
    const body = {};

    if (deliveryCode !== "") {
      body.deliveryCode = deliveryCode;
    }

    if (status !== item.Status) {
      body.status = status;
    }

    setLoading(true);
    updateTransaction(item, body)
      .then((res) => {
        setLoading(false);
        
        if (res.data.success === false) {
          setError(res.data.message);
        } else {
          toggle(true);
          setError("");
        }
      })
      .catch((err) => {
        
        setLoading(false);
      });
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date().toLocaleDateString()}
            {" - "}
            {new Date().toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.transactionId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerPhone}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.shippingAddress ? item.shippingAddress : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Province")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0 .8rem" }}>
            {item.customerProvince ? item.customerProvince : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.totalPrice)} AKZ
          </span>
        </Col>
      </Row>
      {item.transactionType === "PAYMENT_PER_LINK" ? (
        <Row>
          <Col md={6}>
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
                fontSize: "1rem",
              }}
            >
              {t("Estimated Delivery")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(item.refundAfter).toLocaleDateString()}
              {" - "}
              {new Date(item.refundAfter).toLocaleTimeString()}
            </span>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
          {item?.orderStatusDetail=== "REFUND"
            ? ""
            : t(
              `${item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1).toLowerCase()}`
            )
            
          }
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "left" }}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ordered Products")}
          </span>
        </Col>
        {item?.products?.map((prod, index) => {
          return (
            <Row key={index} style={{ justifyContent: "left" }}>
              <Col md={2}>
                <img
                  alt={""}
                  style={{ marginRight: "10px" }}
                  src={prod.image}
                  width={67}
                  height={67}
                />
              </Col>
              <Col md={6}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Name")}
                    </span>
                  </Col>
                  <Col>
                    <span>{prod.name}</span>
                  </Col>
                </Row>
                {item.type == "PRODUCT" && (
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Delivery Name")}
                      </span>
                    </Col>
                    <Col>
                      <span>{prod.deliveryZone.title}</span>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          );
        })}
      </Row>
      {item?.orderStatusDetail === "REFUND" || item?.orderStatusDetail === "COMPLETED"
        ? "" 
        : <Row style={{ marginTop: "1%", justifyContent: "left" }}>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Delivery Code")}
          </span>
        </Col>
        <Col md={4}>
          <input
            className="inputField"
            style={{ width: "100%" }}
            value={deliveryCode}
            onChange={(e) => setDeliveryCode(e.target.value)}
            type="number"
            id="Code"
            maxLength="8"
          />
          {error !== "" && (
            <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
              *{t(`${error}`)}
            </p>
          )}
        </Col>
          </Row>
      }
      {item?.orderStatusDetail=== "REFUND" || item?.orderStatusDetail === "COMPLETED"
        ? ""
        : <Row>
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              // margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
          </Row>
      }
      <Row>
        <Col md={6}>
          <TransactionInvoice item={item} />
        </Col>
      </Row>
    </div>
  );
};

export default LinkPaymentDetailsModal;
