import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { blue } from "../../Globals/colors";
import PinCodeModal from "./PinCodeModal";
import { useTranslation } from "react-i18next";
import { getSellerData } from "../../../api/sellers/sellers";

export default function BankTransfer() {
  const [tableData, setTableData] = useState([0, 1, 2, 3, 4, 5]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = React.useState(true);
  const [sellerInfo, setSellerInfo] = useState();
  const [transferAmount, setTransferAmount] = useState(null);
  const [bank, setBank] = useState("");
  const [error, setError] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [pinCodeModal, setPinCodeModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [body, setBody] = useState({});

  const pinCodeModalToggle = (success = false) => {
    setPinCodeModal(!pinCodeModal);
    setApiLoading(false);
    if (success === true) {
      setTransferAmount(0);
      ShowSuccessMessage();
    }
  };

  const options = [
    { value: "BANCO BNI", label: "Banco BNI" },
    { value: "Banco BFA", label: "Banco BFA" },
    { value: "Banco BAI", label: "Banco BAI" },
    { value: "BANCO BCA", label: "BANCO BCA" },
    { value: "Banco BCI", label: "Banco BCI" },
    { value: "Banco BAI", label: "Banco BAI" },
    { value: "Banco BIR", label: "Banco BIR" },
    { value: "Banco YETU", label: "Banco YETU" },
    { value: "Banco BPC", label: "Banco BPC" },
    { value: "Banco KEVE", label: "Banco KEVE" },
    { value: "Banco SOL", label: "Banco SOL" },
    { value: "Banco VALOR", label: "Banco VALOR" },
    { value: "Banco DA CHINA", label: "Banco DA CHINA" },
    { value: "STANDARD BANK", label: "STANDARD BANK" },

    { value: "Banco VTB ÁFRICA", label: "Banco VTB ÁFRICA" },
    { value: "Banco MILLENNIUM ATLÂNTICO", label: "Banco MILLENNIUM ATLÂNTICO" },
    { value: "Banco CAIXA GERAL ANGOLA", label: "Banco CAIXA GERAL ANGOLA" },
    { value: "BCS – BANCO DE CRÉDITO DO SUL", label: "BCS – BANCO DE CRÉDITO DO SUL" },


    {
      value: "BANCO COMERCIAL DO HUAMBO ",
      label: "BANCO COMERCIAL DO HUAMBO",
    },
  ];

  // const notify = (toastMessage) => toast.info(toastMessage);

  React.useEffect(() => {
    getSellerInfo();
  }, []);

  const getSellerInfo = () => {
    getSellerData()
      .then((res) => {
        setSellerInfo(res.data.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        
      });
  };

  const [successMsg, setSuccessMsg] = useState("");
  const ShowSuccessMessage = () => {
    setSuccessMsg(
      t("Transfer request sent, Please wait for admin to approve the request.")
    );

    // setTimeout(() => {
    //   setSuccessMsg("");
    // }, 4000);
  };

  const Transfer = (e) => {
    e.preventDefault();
    setApiLoading(true);
    setSuccessMsg("");
    setError("");
    if (bank === "" || transferAmount === 0) {
      setError(t("(*)fields are mandatory."));
      setApiLoading(false);
    } else if (transferAmount > sellerInfo.balanceAvailable) {
      setError(
        `${t("Amount to transfer can not be greater than Balance available")}.`
      );
      setApiLoading(false);
    } else {
      setBody({
        sellerId: localStorage?.getItem("uid"),
        availableBalance: sellerInfo?.balanceAvailable,
        bankName: bank,
        ibanNumber: sellerInfo?.bankDetails?.iban,
        amountToTransfer: parseInt(transferAmount),
        paymentStatus: "PENDING",
      });

      pinCodeModalToggle();
      // const body = {
      //   sellerId: localStorage.getItem("uid"),
      //   availableBalance: sellerInfo.balanceAvailable,
      //   bankName: bank.value,
      //   ibanNumber: sellerInfo.bankDetails.iban,
      //   amountToTransfer: transferAmount,
      //   paymentStatus: "pending",
      // };

      // axio
      //   .post(`${baseUrl}/sellers/withdraw/payment`, body)
      //   .then((res) => {
      //     
      //     setTransferAmount(0);
      //     // notify(
      //     //   "Transfer request sent, Please wait for admin to approve the request."
      //     // );
      //     setApiLoading(false);
      //     if (res.data.success === false) setError(res.data.message);
      //     else {
      //       ShowSuccessMessage();
      //       setError("");
      //     }
      //   })
      //   .catch((err) => {
      //     setError(err.message);
      //     setApiLoading(false);
      //     
      //   });
    }

    // if (loading) {
    //   return (
    //     <span className="spinnerContainer">
    //       <Spinner animation="border" variant="primary" />
    //     </span>
    //   );
    // }
  };

  return (
    <div>
      {/* <ToastContainer /> */}
      <div
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            md={3}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Bank Transfer")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2} md={3}>
            <div className="d-grid">
              <Button
                onClick={(e) => {
                  // setTransferBody()
                  Transfer(e);
                  // pinCodeModalToggle();
                }}
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
                disabled={apiLoading}
              >
                {apiLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ color: "white" }}
                  />
                ) : (
                  t("Transfer")
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={{
          marginTop: "2%",
          background: "#fff",
          width: "100%",
          padding: "2%",
          boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
          borderRadius: "5px",
        }}
      >
        <Row>
          <Col xl={6}>
            <label
              htmlFor="Amount"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Balance available")}
            </label>
            <div style={{display:"flex",position:"relative",alignItems:"center"}}>

            <input
            disabled={true}
              type="text"
              value={sellerInfo && `${sellerInfo.balanceAvailable.toLocaleString()}`}
              style={{
                marginTop: "1%",
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "35px",
                padding: "8px",
              }}
              id="Amount"
            />
            <div style={{
              position:"absolute",
              right:"2%"
            }}>AKZ</div>
            </div>
          </Col>

          <Col
            xl={6}
            style={{
              position: "relative",
            }}
          >
            <label
              htmlFor="lastName"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Select Bank")}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={options}
              
              onChange={(value) => setBank(value.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col xl={6}>
            <label
              htmlFor="Amount"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              IBAN
            </label>
            <input
              disabled={true}
              value={sellerInfo && sellerInfo.bankDetails.iban}
              style={{
                marginTop: "1%",
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "35px",
                padding: "8px",
              }}
              id="Amount"
            />
          </Col>
          <Col
            xl={6}
            style={{
              position: "relative",
            }}
          > 
            <label
              htmlFor="lastName"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Amount to Transfer")}
              <span style={{ color: "red" }}> *</span>
            </label>
            <div style={{display:"flex",position:"relative",alignItems:"center"}}>
            <input
              style={{
                marginTop: "1%",
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "35px",
                padding: "8px",
                display: "block",
              }}
              placeholder={t("Amount")}
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              value={transferAmount}
              onChange={(e) => setTransferAmount(e.target.value)}
            />
            <div style={{
              position:"absolute",
              right:"2%"
            }}>AKZ</div>
            </div>
          </Col>
          {error !== "" && (
            <p
              style={{
                color: "red",
                fontSize: "14px",
                marginTop: "8px",
              }}
            >
              {t(`${error}`)}
            </p>
          )}
          <p style={{ color: "green", fontSize: "16px", marginTop: "8px" }}>
            {t(`${successMsg}`)}
          </p>
        </Row>
      </div>

      <PinCodeModal
        toggle={pinCodeModalToggle}
        modal={pinCodeModal}
        body={body}
      />
    </div>
  );
}
