import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { TextareaAutosize } from "@material-ui/core";
import { baseUrl } from "../../Helpers/helper";
import Select from "react-select";
import { AiFillDelete } from "react-icons/ai";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getCategoriesListbyDomainShopping } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";

const ProductDetailModal = ({ toggle, item, loadProducts }) => {
  const [path, setPath] = useState(null);
  const [imgPaths, setImgPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  // const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  var o = [];
  var c = 0;
  var [count, setCount] = useState([]);
  var [isDisabled, setIsDisabled] = useState(false);
  const [catNames, setCatNames] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [cat, setCat] = useState({ value: "", label: item.categoryName });

  const [prodImages, setProdImages] = useState([]);
  const [prodCategories, setProdCategories] = useState([]);
  const [title, setTitle] = useState(item.Name);
  const [price, setPrice] = useState(item.Price);
  const [sku, setSku] = useState(item.SKU);
  const [author, setAuthor] = useState(item.Author);
  const [qty, setQty] = useState(item.Qty);
  const [province, setProvince] = useState({
    label: item.Province,
    value: item.Province,
  });
  const [brand, setBrand] = useState(item.Brand);
  const [attrs, setAttrs] = useState(item.attributes);
  const [size, setSize] = useState();
  const [color, setColor] = useState("");
  const [quantity, setQuantity] = useState();
  const [quantityAttr,setQuantityAttr]=useState('')
  const [optionPrice, setOptionPrice] = useState(0);

  const [description, setDescription] = useState(item.Description);
  // modal types
  const [modalType, setModalType] = useState("addProduct");
  const [productUrl, setProductUrl] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState("");
  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [selectDeliveryValues, setSelectDeliveryValues] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [requiredOptions, setRequiredOptions] = useState([
    { value: 'attributes', label: 'Attributes' },
    { value: 'quantity', label: 'Quantity' }
  ]);

  const [error, setError] = useState("");

  React.useEffect(() => {
    getCategories("");
    getMethods();
    setDeliveryValues();
  }, []);

  const setDeliveryValues = () => {
    const tempArray = [];

    item.deliveryData.map((item) => {
      tempArray.push({ value: item._id, label: item.title });
    });

    setSelectDeliveryValues(tempArray);
  };

  const getCategories = (id) => {
    if (id === "") {
      id = "null";
    } else {
      setIsDisabled(true);
    }

    getCategoriesListbyDomainShopping(id)
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabled(false);
            setDisableSelect(true);
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
            });
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  // checks if olderImages and images are same or not
  const checkImgArrays = () => {
    const oldArr = olderImages.sort();
    const newArr = images.sort();

    return (
      Array.isArray(oldArr) &&
      Array.isArray(newArr) &&
      oldArr.length === newArr.length &&
      oldArr.every((item, index) => item === newArr[index])
    );
  };

  const checkCategories = () => {
    if (prodCategories.length > 0) {
      const oldCat = item.CategoryId.sort();
      const newCat = prodCategories.sort();

      return (
        Array.isArray(oldCat) &&
        Array.isArray(newCat) &&
        oldCat.length === newCat.length &&
        oldCat.every((item, index) => item === newCat[index])
      );
    } else return true;
  };

  const checkDelivery = () => {
    if (deliveryMethod.length > 0) {
      const oldDelivery = item.deliveryMethod?.sort();
      const newDelivery = deliveryMethod.sort();

      return (
        Array.isArray(oldDelivery) &&
        Array.isArray(newDelivery) &&
        oldDelivery.length === newDelivery.length &&
        oldDelivery.every((item, index) => item === newDelivery[index])
      );
    } else return true;
  };

  const checkOptions = () => {
    if (attrs.length > 0) {
      const oldAttr = item.attributes.sort();
      const newAttr = attrs.sort();

      return (
        oldAttr.length === newAttr.length &&
        oldAttr.every((item, index) => _.isEqual(item, newAttr[index]))
      );
    } else {
      return false;
    }
  };

  const updateProductMethod = async (e) => {
    setLoading(true);
    setError("");
    if (
      title === item.Name &&
      price === item.Price &&
      sku === item.SKU &&
      brand === item.Brand &&
      qty === item.Qty &&
      province.value === item.Province &&
      size===item.size &&
      description === item.Description &&
      checkDelivery() &&
      checkImgArrays() &&
      checkCategories() &&
      checkOptions()
    ) {
      setError(t("*Change any field to update record."));
      setLoading(false);
    } else if (images.length < 1) {
      setError(t("*Add atleast one image"));
      setLoading(false);
    } else {
      let body = {};
      if (title !== item.Name) {
        body.Name = title;
      }
      if (price !== item.Price) {
        body.Price = price;
      }
      if (province.value !== item.Province) {
        body.Province = province.value;
      }
      if (sku !== item.SKU) {
        body.SKU = sku;
      }
      if (qty !== item.Qty) {
        body.Qty = qty;
      }
      if (description !== item.Description) {
        body.Description = description;
      }
      if (brand !== item.Brand) {
        body.Brand = brand;
      }
      if (!checkImgArrays()) {
        body.Images = images;
      }
      if (!checkCategories()) {
        body.CategoryId = prodCategories;
      }
      if (!checkDelivery()) {
        body.deliveryMethod = deliveryMethod;
      }
      body.attributes = attrs;
      updateProduct(item._id, body)
        .then((res) => {
          setLoading(false);
          toggle();
          loadProducts();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };

  // older images to compare b4 updating
  const [olderImages] = useState(item.Images);
  const [images, setImages] = useState(item.Images);
  const [showDeleteText, setShowDeleteText] = useState(false);

  //using s3 bucket
  const onFileChange = async (e) => {
    setLoading(true);

    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImages((oldUrls) => [
            ...oldUrls,
            `${process.env.REACT_APP_S3_URL}/public/${res.key}`,
          ]);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    setLoading(true);

    try {
      await Storage.remove(file).then((res) => {
        
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      
    }
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">{t("Product images")}</Col>
        </Row>

        <Row className="align-items-start flex-row">
          <Col style={{ display: "flex", flexDirection: "row" }}>
            {images.map((item) => (
              <img
                alt=""
                src={item}
                width={"65px"}
                height={"65px"}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => {
                  const newImages = images.filter((img) => img != item);
                  setImages(newImages);
                  onDeleteImage(item);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
            ))}
            {images.length < 3 && (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            )}
          </Col>
          {images.length > 0 && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              {t("*click to delete")}
            </p>
          )}
        </Row>

       
        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Title")}</label>
            <input
              className="inputField"
              style={{ width: "100%" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>

          <Col className="col-6">
            <label className="inpLblTxt">{t("Price")}</label>
            <input
              className="inputField"
              style={{ width: "100%" }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>
       

        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Brand")}</label>
            <input
              style={{ width: "100%" }}
              className="inputField"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              id="Title"
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Description")}
            </label>
            <TextareaAutosize
              className="inputTxtarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minRows={3}
            />
          </Col>
        </Row>
        {item.stockType === "ATTRIBUTES" ? 
        (
          <div>
             {attrs?.length > 0 &&
          attrs.map((item) => (
            
          <div>
            <div
              style={{
                backgroundColor: "lightgray",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              
              <p
                style={{
                  display: "inline",
                  marginBottom: "0",
                  marginLeft: "10px",
                }}
              >
                {item.size && `${t("size")}: ${item.size},`}{" "}
                {item.color && `${t("color")}: ${item.color},`}{" "}
                {item.quantity && `${t("qty")}: ${item.quantity}`}
              
              </p>
              <AiFillDelete
                onClick={() => {
                  let filteredAttrs = attrs.filter((curr) => curr !== item);
                  setAttrs(filteredAttrs);
                }}
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                size={"1.2rem"}
              />
            </div>
             <div style={{gap:"7px", backgroundColor: "#f0f0f0",marginTop: "12px",
             paddingBottom: "10px" }}>
               <div style={{ width: "90%",margin:"auto"}}>
               <Row>
               <Col className="col-6">
                 <label className="inpLblTxt">{t("Size")}</label>
                 <input
                   style={{ width: "100%" }}
                   value={size}
                   onChange={(e) => setSize(e.target.value)}
                   className="inputField"
                   id="Title"
                   type="text"
                 />
               </Col>
              
               <Col className="col-6">
                 <label className="inpLblTxt">{t("Color")}</label>
                 <p style={{ color: "red", display: "inline" }}> *</p>
                 <input
                   style={{ width: "100%" }}
                   value={color}
                   onChange={(e) => setColor(e.target.value)}
                   className="inputField"
                   id="Title"
                   type="text"
                 />
               </Col>
               </Row>
               <Row className="d-flex justify-content-between">
               <Col className="col-6">
                 <label className="inpLblTxt">{t("Quantity")}</label>
                 <p style={{ color: "red", display: "inline" }}> *</p>
                 <input
                   style={{ width: "100%" }}
                   value={quantityAttr}
                   onChange={(e) => setQuantityAttr(e.target.value)}
                   className="inputField"
                   id="Title"
                   type="number"
                 />
               </Col>
               <Col className="text-end mt-4">
                 <button
                   disabled={!size || !quantityAttr || !color}
                   onClick={() => {
                     setAttrs((prevData) => [
                       ...prevData,
                       { size,color:color,quantity:quantityAttr }
                     ]);
                     
                     setSize(0);
                     setColor("");
                     setQuantityAttr(0);
                   }}
                 >
                   {t("Add")}
                 </button>
               </Col>
               </Row>
               </div>
     
               
               </div>
               </div>
          ))}
          </div>
        )
         :(
          <div>
            <Row style={{ marginTop: "2%" }}>
            <Col className="col-12">
              <label className="inpLblTxt">{t("Quanity")}</label>
              <input
                style={{ width: "100%" }}
                className="inputField"
                value={qty}
                onChange={
                  (e)=>{
                    setQty(e.target.value)
                  }
                }
                id="Title"
              />
            </Col>
           
           </Row>
          </div>
         )
        
        }
            
    

        <label className="inpLblTxt">{t("Categories")}</label>
        <br />

        {catNames.length > 0 &&
          catNames.map((item, index) => (
            <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
              {index !== 0 && "/ "}
              {item}
            </p>
          ))}
        {/* {count.map((i) => ( */}
        <div style={{ marginBottom: "10px" }}>
          <Select
            onChange={(e) => {
              setProdCategories([...prodCategories, e.value]);
              getCategories(e.value);
              setCatNames((catNames) => [...catNames, e.label]);
              setCat(e);
            }}
            value={cat}
            isDisabled={disableSelect}
            options={options}
            isSearchable
            placeholder="Select Category..."
          />
        </div>
        {catNames.length > 0 && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => {
                getCategories("");
                setCat({ value: "", label: item.categoryName });
                setCatNames([]);
                setProdCategories([]);
                setDisableSelect(false);
                setIsDisabled(true);
              }}
            >
              <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
            </button>
          </div>
        )}
        
        {error && (
          <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
            {t(`${error}`)}
          </p>
        )}
        <Button
          onClick={(e) => updateProductMethod(e)}
          disabled={loading || isDisabled}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
};

export default ProductDetailModal;
