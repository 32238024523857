import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  sellerType: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  // don't worry about mutable code, immer does that for us automatically
  reducers: {
    signin(state, action) {
      state.isAuthenticated = true;
      state.sellerType = action.payload;
    },

    signout(state, action) {
      state.isAuthenticated = false;
      state.sellerType = "";
    },
  },
});

export const { signin, signout } = authSlice.actions;
export default authSlice.reducer;
