import React from "react";
import "../Components/SideDrawer/SideDrawer.css";
import Login from "../Pages/Login/Login";
import HeaderWithDrawer from "./HeaderWithDrawer";
import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Stats from "../Pages/Stats/Stats";
import Transactions from "../Pages/Transactions/Transactions";
import TransactionHistory from "../Pages/TransactionHistory/TransactionHistory";
import GenerateLink from "../Pages/GenerateLink/GenerateLink";
import Categories from "../Pages/Categories/Categories";
import Products from "../Pages/Products/Products";
import Definitions from "../Pages/Definitions/Definitions";
import QRCode from "../Pages/QRCode/QRCode";
import Swing from "../Pages/Swing/Swing";
import BankTransfer from "../Pages/BankTransfer/BankTransfer";
import BankTransferHistory from "../Pages/BankTransferHistory/BankTransferHistory";
import Register from "../Pages/Auth/Register";
import Event from "../Pages/Event/Event";
import EventType from "../Pages/EventType/EventType";
import Books from "../Pages/Books/Books";
// restaurant products
import RestaurantProduct from "../Pages/Restaurants/Products";
// cantinas and supermarket product
import SuperMarketCantinasProduct from "../Pages/SuperMarket & Cantinas/Products";
// shopping products
import ShoppingProducts from "../Pages/Shopping/ShoppingProducts";
// shopping center products
import AccountSetting from "../Pages/AccountSetting/AccountSetting";

// store
import { useAppSelector } from "../Redux/hooks";

const initialRoutes = [
  {
    path: "/",
    exact: true,
    name: "Stats",
    component: Stats,
  },
  {
    path: "/Transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/register",
    exact: true,
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
  },
  {
    path: "/TransactionHistory",
    exact: true,
    name: "TransactionHistory",
    component: TransactionHistory,
  },
  {
    path: "/GenerateLink",
    exact: true,
    name: "GenerateLink",
    component: GenerateLink,
  },
  // {
  //   path: "/PayPerLink",
  //   exact: true,
  //   name: "PayPerLink",
  //   component: PayPerLink,
  // },
  // {
  //   path: "/PayByCatalog",
  //   exact: true,
  //   name: "PayByCatalog",
  //   component: PayByCatalog,
  // },
  {
    path: "/QRCode",
    exact: true,
    name: "QRCode",
    component: QRCode,
  },
  {
    path: "/Swing",
    exact: true,
    name: "Swing",
    component: Swing,
  },
  {
    path: "/BankTransfer",
    exact: true,
    name: "BankTransfer",
    component: BankTransfer,
  },
  {
    path: "/BankTransferHistory",
    exact: true,
    name: "BankTransferHistory",
    component: BankTransferHistory,
  },
  // {
  //   path: "/Discount",
  //   exact: true,
  //   name: "Discount",
  //   component: Discount,
  // },
  {
    path: "/AccountSettings",
    exact: true,
    name: "AccountSettings",
    component: AccountSetting,
  },
];

function RouterApp() {
  const sellerType = useAppSelector((state) => state.auth.sellerType);

  const [routes, setRoutes] = React.useState(initialRoutes);
  React.useEffect(() => {
    if (sellerType === "Event") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        {
          path: "/Event",
          exact: true,
          name: "Event",
          component: Event,
        },
        {
          path: "/EventType",
          exact: true,
          name: "EventType",
          component: EventType,
        },
      ]);
    } else if (sellerType === "Book") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        {
          path: "/Products",
          exact: true,
          name: "Products",
          component: Books,
        },
        {
          path: "/Categories",
          exact: true,
          name: "Categories",
          component: Categories,
        },
      ]);
    } else if (sellerType === "") {
    } else if (sellerType == "Fast Food" || sellerType == "Bar & Restaurant") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        // {
        //     path: "/HouseOfOperations",
        //     exact: true,
        //     name: "HouseOfOperations",
        //     component: HouseOfOperations,
        // },
        {
          path: "/Products",
          exact: true,
          name: "Products",
          component: RestaurantProduct,
        },
        {
          path: "/Categories",
          exact: true,
          name: "Categories",
          component: Categories,
        },
      ]);
    } else if (sellerType === "Shop Center") {
      const sellerDomain = localStorage.getItem("sType");
      
      if (sellerDomain === "Pay") {
      } else if (sellerDomain === "Supermarket") {
        setRoutes((oldArr) => [
          ...oldArr,
          {
            path: "/Definitions",
            exact: true,
            name: "Definitions",
            component: Definitions,
          },
          {
            path: "/Products",
            exact: true,
            name: "Products",
            component: SuperMarketCantinasProduct,
          },
          {
            path: "/Categories",
            exact: true,
            name: "Categories",
            component: Categories,
          },
        ]);
      } else if (sellerDomain === "Fast Food" || sellerDomain === "Bar & Restaurant") {
        setRoutes((oldArr) => [
          ...oldArr,
          {
            path: "/Definitions",
            exact: true,
            name: "Definitions",
            component: Definitions,
          },
          {
            path: "/Products",
            exact: true,
            name: "Products",
            component: RestaurantProduct,
          },
          {
            path: "/Categories",
            exact: true,
            name: "Categories",
            component: Categories,
          },
        ]);
      } else if (sellerDomain === "Book") {
        setRoutes((oldArr) => [
          ...oldArr,
          {
            path: "/Definitions",
            exact: true,
            name: "Definitions",
            component: Definitions,
          },
          {
            path: "/Products",
            exact: true,
            name: "Products",
            component: Books,
          },
          {
            path: "/Categories",
            exact: true,
            name: "Categories",
            component: Categories,
          },
        ]);
      } else if (sellerDomain === "Shopping") {
        setRoutes((oldArr) => [
          ...oldArr,
          {
            path: "/Definitions",
            exact: true,
            name: "Definitions",
            component: Definitions,
          },
          {
            path: "/Products",
            exact: true,
            name: "Products",
            component: ShoppingProducts,
          },
          {
            path: "/Categories",
            exact: true,
            name: "Categories",
            component: Categories,
          },
        ]);
      }
    } else if (sellerType === "Shopping") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        {
          path: "/Products",
          exact: true,
          name: "Products",
          component: ShoppingProducts,
        },
        {
          path: "/Categories",
          exact: true,
          name: "Categories",
          component: Categories,
        },
      ]);
    } else if (sellerType == "Cantinas" || sellerType == "Supermarket") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        {
          path: "/Products",
          exact: true,
          name: "Products",
          component: SuperMarketCantinasProduct,
        },
        {
          path: "/Categories",
          exact: true,
          name: "Categories",
          component: Categories,
        },
      ]);
    } else if (sellerType === "Pay") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
      ]);
    } else {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          path: "/Definitions",
          exact: true,
          name: "Definitions",
          component: Definitions,
        },
        {
          path: "/Products",
          exact: true,
          name: "Products",
          component: Products,
        },
        {
          path: "/Categories",
          exact: true,
          name: "Categories",
          component: Categories,
        },
      ]);
    }

    return function cleanup() {
      setRoutes(initialRoutes);
    };
  }, [sellerType]);
  return (
    <BrowserRouter>
      <Switch>
        <HeaderWithDrawer>
          {routes.map((route, idx) => {
            return route.component ? (
              <PrivateRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                Component={route.component}
              />
            ) : null;
          })}
        </HeaderWithDrawer>
      </Switch>
    </BrowserRouter>
  );
}

export default RouterApp;
