import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {addDeliveryZone} from "../../../api/deliveryZones/deliveryZones"

export default function AddDeliveryModal({ toggle, getUpdatedData }) {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [cost, setCost] = useState("");
    const [longitude, setLongitude] = useState("20");
    const [latitude, setLatitude] = useState("30");
    const [radius, setRadius] = useState("500");
    const [status, setStatus] = useState("");
    const [est, setEst] = useState("");
    const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;
    const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
    const { t, i18n } = useTranslation();
  
    const addDelivery = () => {
      setError("");
      setLoading(true);
  
      if (
        title === "" ||
        cost === "" ||
        longitude === "" ||
        latitude === "" ||
        radius === "" ||
        est === ""
      ) {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        if (!isLongitude(longitude) || !isLatitude(latitude)) {
          setError(t("*Invalid coordinate(s)"));
          setLoading(false);
        } else {
          addDeliveryZone({
            title,
            longitude,
            latitude,
            radius,
            price: cost,
            est,
            status: status,
            sellerId: localStorage.getItem("uid"),
          })
            .then((res) => {
              if (res.data.success) {
                setLoading(false);
                toggle();
                getUpdatedData();
              } else {
                if (res.data.message == "Delivery Zone already exists.") {
                  setError(t("*Delivery Zone already exists."));
                  setLoading(false);
                } else {
                  setLoading(false);
                  setError(t("*Something went wrong."));
                }
              }
            })
            .catch((err) => {
              setLoading(false);
              setError(t("*Record with this title already exist."));
              
            });
        }
      }
    };
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              style={{ width: "100%" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t("Title")}
              className="inputField"
              id="Title"
            />
          </Col>
        </Row>
  
        {/* <Row className="d-flex justify-content-between">
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              Longitude<p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              placeholder="0.0000"
              className="inputField"
              id="Longitude"
              type="number"
            />
          </Col>
  
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              Latitude<p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              placeholder="0.0000"
              className="inputField"
              id="Latitude"
              type="number"
            />
          </Col>
        </Row> */}
  
        <Row>
          <Col>
            <label className="inpLblTxt">
              {t("Price")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              style={{ width: "100%" }}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder="0.00 AKZ"
              className="inputField"
              id="Cost"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
          {/* <Col>
            <label className="inpLblTxt">
              Radius (km)<p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              placeholder="0.00 Km"
              className="inputField"
              id="Radius"
              type="number"
            />
          </Col> */}
        </Row>
        <Row>
          <Col>
            <label className="inpLblTxt">
              {t("Delivery time (EST)")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <Select
              options={[
                { value: "0", label: t("Same day") },
                { value: "1", label: `1 ${t("day")}` },
                { value: "2", label: `2 ${t("days")}` },
                { value: "3", label: `3 ${t("days")}` },
                { value: "4", label: `4 ${t("days")}` },
                { value: "5", label: `5 ${t("days")}` },
              ]}
              onChange={({ value }) => setEst(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Status")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0px",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status
                    ? status === "enable" ||
                      status === "Enable" ||
                      status === "Active" ||
                      status === "approved"
                      ? t("Active")
                      : status === "disable" ||
                        status === "Disable" ||
                        status === "Inactive" ||
                        status === "pending"
                      ? t("Inactive")
                      : status
                    : t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("approved")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("pending")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {error && (
          <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
            {t(`${error}`)}
          </p>
        )}
  
        <Button
          onClick={addDelivery}
          disabled={loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
}
