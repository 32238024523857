import React, { useState } from "react";
import { Row, Col, Table, Pagination, Form, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Pages from "../../Components/Pagination/Pagination";
import noDataFound from "../../Assets/images/nodata.png";
import StatsCard from "../../Components/StatsCard/StatsCard";
import { useTranslation } from "react-i18next";
import { getPaymentCycleData } from "../../../api/paymentCycle/paymentCycle";
import { getSellerBalance,getSellerCaptiveBalance } from "../../../api/kpis/kpis";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { baseUrl } from "../../Helpers/helper";

import ModalLayout from "./ModalLayout";

const Swing = () => {
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const { t, i18n } = useTranslation();
  const [cards, setCards] = React.useState([
    // { "title": "TRAFFIC", "amount": "350,897", "increase": true, "value": 13.45, "when": "last month" },
    // { "title": "NEW USERS", "amount": "2,356", "increase": true, "value": 1.48, "when": "last week" },
    // { "title": "SALES", "amount": "234", "increase": true, "value": 20, "when": "yesterday" },
    // { "title": "PERFORMANCE", "amount": "4,965", "increase": true, "value": 30, "when": "yesterday" },
  ]);

  const toggle = () => setModal(!modal);

  const [loading, setLoading] = React.useState(false);
  const loadStatCard=async()=>{
    setLoading(true);
    try{
      const sellerBalanceResponse=await getSellerBalance();
      const captivegBalanceResponse=await getSellerCaptiveBalance();
      setCards([
          {
            title: t("Captive Balance"),
            amount: captivegBalanceResponse.data.captivegBalance ? captivegBalanceResponse.data.captivegBalance : 0,
            currency: true,
          },
          {
            title: t("Accounting Balance"),
            amount: sellerBalanceResponse.data.accountingBalance ? sellerBalanceResponse.data.accountingBalance : 0,
            currency: true,
          },
          {
            title: t("Available Balance"),
            amount: sellerBalanceResponse.data.availabaleBalance ? sellerBalanceResponse.data.availabaleBalance : 0,
            currency: true,
          },
        ]);
      setLoading(false);
    }
    catch(err){
      setLoading(false);
      
    }
  }
  
  React.useEffect(() => {
    loadStatCard()
    // getSellerBalance()
    //   .then((res) => {
    //     
    //     setCards([
    //       {
    //         title: t("Captive Balance"),
    //         amount: res.data.captivegBalance ? res.data.captivegBalance : 0,
    //         currency: true,
    //       },
    //       {
    //         title: t("Accounting Balance"),
    //         amount: res.data.accountingBalance ? res.data.accountingBalance : 0,
    //         currency: true,
    //       },
    //       {
    //         title: t("Available Balance"),
    //         amount: res.data.availabaleBalance ? res.data.availabaleBalance : 0,
    //         currency: true,
    //       },
    //     ]);
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     
    //   });
  }, []);
  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/payment-cycle/1/sellers/payment-cycle/export?limit=1000000&page=1&orderStatus=${selectedStatus}&sellerId=${localStorage.getItem(
          "uid"
        )}&page=1000000&method=${selectedMethod}&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
      
    }
  };
  React.useEffect(() => {
    setNoOfPages([]);
    setLoading(true);
    getPaymentCycleData(limit, activePage, startDate, endDate)
      .then((res) => {
        
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((error) => {
        setLoading(false);
        
      });
  }, [limit, activePage, startDate, endDate]);

  return (
    <div
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   padding: "0px 25px",
    //   backgroundColor: "#f7f7f7",
    // }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={4}
            lg={4}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Payment Cycle")}
              </span>
            </div>
          </Col>
          {/* <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              visibility: "hidden",
            }}
          >
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col> */}
          <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              visibility: "hidden",
            }}
          >
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {t("Show All")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>{t("Another Action")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>{t("Another Action")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("show all")}
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                  marginTop: "0px",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col
            xl={2}
            lg={2}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          <div className="mt-4">
            {cards.map((card) => (
              <StatsCard {...card} />
            ))}
          </div>

          <Table
            style={{
              cursor: "pointer",
              marginTop: "45px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                {/* <th
                style={{
                  padding: "20px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Period
              </th> */}
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("Closed Date")}
                </th>
                <th
                style={{
                  padding: "20px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                No of Transactions
              </th>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("Amount")}
                </th>
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("Commission")}
                </th>
                {/* <th
                style={{
                  padding: "20px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                SMS
              </th> */}
                <th
                  style={{
                    padding: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("Net Amount")}
                </th>
              </tr>
            </thead>
            {tableData?.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    // onClick={toggle}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {/* <td style={{ padding: "20px", textAlign: "center" }}>
                    {item.period ? item.period : ""}
                  </td> */}
                    <td style={{ padding: "20px", textAlign: "center" }}>
                    {new Date(item.createdAt).toLocaleDateString()}
                      {" - "}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td style={{ padding: "20px", textAlign: "center" }}>
                    {item.noOfTransactions ? item.noOfTransactions : "..."}
                  </td>
                    <td style={{ padding: "20px", textAlign: "center" }}>
                      {item.amount
                        ? `${new Intl.NumberFormat().format(item.amount)} AKZ`
                        : "..."}
                    </td>
                    <td style={{ padding: "20px", textAlign: "center" }}>
                      {item.commission
                        ? `${new Intl.NumberFormat().format(
                            item.commission
                          )} AKZ`
                        : "..."}
                    </td>
                    {/* <td style={{ padding: "20px", textAlign: "center" }}>
                    {item.sms ? `${new Intl.NumberFormat().format(item.sms)} AKZ` : ""}
                  </td> */}
                    <td style={{ padding: "20px", textAlign: "center" }}>
                      {item.netAmount
                        ? `${new Intl.NumberFormat().format(
                            item.netAmount
                          )} AKZ`
                        : item.amountToTransfer
                        ? `${new Intl.NumberFormat().format(
                            item.amountToTransfer
                          )} AKZ`
                        : "..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <div className="noDataContainer">
                      <img src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </>
      )}
      <div style={{ height: "100px" }}>
        <Row style={{ display: "block", paddingBottom: "0px" }}>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pages
                  noOfPages={noOfPages}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </div>
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      margin: "auto",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limit}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem
                      onClick={() => {
                        setLimit(1);
                        setActivePage(1);
                      }}
                    >
                      1
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(5);
                        setActivePage(1);
                      }}
                    >
                      5
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(10);
                        setActivePage(1);
                      }}
                    >
                      10
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default Swing;
