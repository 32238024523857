import axios from "./axiosInterceptor";

export const getProducts = (
  limit,
  activePage,
  startDate,
  endDate,
  searchName,
  sid
) =>
  axios.get(
    `/products?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&pname=${searchName}&store=${sid}`
  );

export const getProductsBySeller = (sid) =>
  axios.get(`/products/seller/${sid}`);

export const getProductsByName = (limit = "", activePage = "", searchName) =>
  axios.get(
    `/products?page=${activePage}&limit=${limit}&pname=${searchName}&store=${localStorage.getItem(
      "uid"
    )}`
  );

export const getProductsByStoreId = (limit = "", activePage = "") =>
axios.get(
    `/products?page=${activePage}&limit=${limit}&store=${localStorage.getItem(
      "uid"
    )}`
  );

export const updateProduct = (id, body) => axios.put(`/products/${id}`, body);

export const deleteProduct = (id) => axios.delete(`/products/delete/${id}`);

export const addProduct = (productBody) => axios.post(`/products`, productBody);
