import React from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalLayout({ data }) {
 
    const { t, i18n } = useTranslation();
    return(
    <div>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Order ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.transactionId}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Date")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {" "}
            {new Date(data.createdAt).toDateString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Payment Source")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {data.bankName}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(data.amountToTransfer)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {data.paymentStatus}
          </span>
        </Col>
      </Row>
  
      {/* <Row style={{ marginTop: "1%" }}>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            disabled={true}
            style={{ fontSize: "0.8rem" }}
            type="checkbox"
            label="Check me out"
          />
        </Form.Group>
      </Row> */}
    </div>
  );
}


