import logo from "../Assets/images/logo.png";
import onAppleImage from "../Assets/images/logo.png";
import onAndroidImage from "../Assets/images/logo.png";
import coverImg from "../Assets/images/cover.jpg";
import helpImg from "../Assets/images/logo.png";

const images = [
    { id: 1, src: `${logo}`, title: "logo", description: "company logo" },
    {
        id: 4,
        src: `${onAndroidImage}`,
        title: "on-android-app",
        description: "on-android-app",
    },
    {
        id: 5,
        src: `${onAppleImage}`,
        title: "on-apple-app",
        description: "on-apple-app",
    },
    { id: 5, src: `${coverImg}`, title: "cover-img", description: "cover-img" },
    { id: 6, src: `${helpImg}`, title: "help-img", description: "help-img" },
];

// FOR PUBLIC URLS
// const images = [
//   { id: 1, src: `${process.env.PUBLIC_URL}/`, title: 'logo', description: 'company logo' },
//   { id: 2, src: `${process.env.PUBLIC_URL}/`, title: 'norton', description: 'norton' }
// ];

function ImagesLoader(title) {
    try {
        if (!!title && title.length > 0) {
            return images.some((x) => x.title === title)
                ? images.find((x) => x.title === title).src
                : "";
        } else {
            return "";
        }
    } catch (e) {
        
    }
}

export default ImagesLoader;