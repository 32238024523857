import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { TextareaAutosize } from "@material-ui/core";
import Select from "react-select";
import { AiFillDelete } from "react-icons/ai";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

const ProductDetailModal = ({ toggle, item, loadProducts }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  var o = [];
  var c = 0;
  var [count, setCount] = useState([]);
  var [isDisabled, setIsDisabled] = useState(false);
  const [title, setTitle] = useState(item.Name);
  const [price, setPrice] = useState(item.Price);
  const [description, setDescription] = useState(item.Description);
  // modal types
  const [modalType, setModalType] = useState("addProduct");
  const [productUrl, setProductUrl] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState("");
  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [selectDeliveryValues, setSelectDeliveryValues] = useState([]);

  const [catNames, setCatNames] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [cat, setCat] = useState({ value: "", label: item.categoryName });
  const [prodCategories, setProdCategories] = useState([]);
  const [attrs, setAttrs] = useState(item.options || []);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");

  React.useEffect(() => {
    getCategories("");
    getMethods();
    setDeliveryValues();
  }, []);

  const setDeliveryValues = () => {
    const tempArray = [];

    item.deliveryData.map((item) => {
      tempArray.push({ value: item._id, label: item.title });
    });

    setSelectDeliveryValues(tempArray);
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
            });
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const getCategories = (id) => {
    if (id === "") {
      id = "null";
    } else {
      setIsDisabled(true);
    }

    const domain = localStorage.getItem("domain");
    let catDomain = "";
    if (domain == "Book") {
      catDomain = "Library";
    }
    if (domain == "Fast Food" || domain == "Bar & Restaurant") {
      catDomain = "Food and Restaurant";
    }

    getCategoriesListbyDomain(id, catDomain)
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabled(false);
            setDisableSelect(true);
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  // checks if olderImages and images are same or not
  const checkImgArrays = () => {
    const oldArr = olderImages.sort();
    const newArr = images.sort();

    return (
      Array.isArray(oldArr) &&
      Array.isArray(newArr) &&
      oldArr.length === newArr.length &&
      oldArr.every((item, index) => item === newArr[index])
    );
  };

  const checkCategories = () => {
    if (prodCategories.length > 0) {
      const oldCat = item.CategoryId.sort();
      const newCat = prodCategories.sort();

      return (
        Array.isArray(oldCat) &&
        Array.isArray(newCat) &&
        oldCat.length === newCat.length &&
        oldCat.every((item, index) => item === newCat[index])
      );
    } else return true;
  };

  const checkOptions = () => {
    if (attrs.length > 0) {
      const oldAttr = item.options.sort();
      const newAttr = attrs.sort();

      return (
        oldAttr.length === newAttr.length &&
        oldAttr.every((item, index) => _.isEqual(item, newAttr[index]))
      );
    } else {
      return false;
    }
  };

  const checkDelivery = () => {
    if (deliveryMethod.length > 0) {
      const oldDelivery = item.deliveryMethod?.sort();
      const newDelivery = deliveryMethod.sort();

      return (
        Array.isArray(oldDelivery) &&
        Array.isArray(newDelivery) &&
        oldDelivery.length === newDelivery.length &&
        oldDelivery.every((item, index) => item === newDelivery[index])
      );
    } else return true;
  };

  const updateProductMethod = async (e) => {
    setLoading(true);
    setError("");
    if (
      // Check if any field was changed
      title === item.Name &&
      price === item.Price &&
      description === item.Description &&
      checkDelivery() &&
      checkImgArrays() &&
      checkCategories() &&
      checkOptions()
    ) {
      setError(t("*Change any field to update record."));
      setLoading(false);
    } else if (images.length < 1) {
      setError(t("*Add atleast one image"));
      setLoading(false);
    } else {
      // adding fields to the payload that were changed
      let body = {};
      if (title !== item.Name) {
        body.Name = title;
      }
      if (price !== item.Price) {
        body.Price = price;
      }
      if (description !== item.Description) {
        body.Description = description;
      }
      if (!checkImgArrays()) {
        body.Images = images;
      }
      if (!checkOptions()) {
        body.options = attrs;
      }
      // since categories are not handled while updating, so sending as it is
      // body.CategoryId = item.CategoryId;
      if (!checkCategories()) {
        body.CategoryId = prodCategories;
      }
      if (!checkDelivery()) {
        body.deliveryMethod = deliveryMethod;
      }

      updateProduct(item._id, body)
        .then((res) => {
          setLoading(false);
          toggle();
          loadProducts();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };

  // older images to compare b4 updating
  const [olderImages] = useState(item.Images);
  const [images, setImages] = useState(item.Images);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [copied, setCopied] = useState(false);

  const [option, setOption] = useState("");
  const [optionPrice, setOptionPrice] = useState(0);

  //using s3 bucket
  const onFileChange = async (e) => {
    setLoading(true);

    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImages((oldUrls) => [
            ...oldUrls,
            `${process.env.REACT_APP_S3_URL}/public/${res.key}`,
          ]);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">{t("Product images")}</Col>
        </Row>

        <Row className="align-items-start flex-row">
          <Col style={{ display: "flex", flexDirection: "row" }}>
            {images.map((item) => (
              <img
                alt=""
                src={item}
                width={"65px"}
                heigth={"65px"}
                style={{ marginRight: "5px" }}
                onClick={() => {
                  const newImages = images.filter((img) => img != item);
                  setImages(newImages);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
            ))}
            {images.length < 3 && (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            )}
          </Col>
          {images.length > 0 && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              {t("*click to delete")}
            </p>
          )}
        </Row>

        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Title")}</label>
            <input
              className="inputField"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>

          <Col className="col-6">
            <label className="inpLblTxt">{t("Price")}</label>
            <input
              className="inputField"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Description")}
            </label>
            <TextareaAutosize
              className="inputTxtarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minRows={3}
            />
          </Col>
        </Row>

        {attrs.length > 0 &&
          attrs.map((item) => (
            <div
              style={{
                backgroundColor: "lightgray",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <p
                style={{
                  display: "inline",
                  marginBottom: "0",
                  marginLeft: "10px",
                }}
              >
                {t("Option")}: {item.option}
              </p>
              <p
                style={{
                  display: "inline",
                  marginBottom: "0",
                  marginLeft: "10px",
                }}
              >
                {t("Price")}: {item.price}
              </p>
              <AiFillDelete
                onClick={() => {
                  let filteredAttrs = attrs.filter(
                    (curr) => !_.isEqual(curr, item)
                  );
                  setAttrs(filteredAttrs);
                }}
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                size={"1.2rem"}
              />
            </div>
          ))}
        <Row
          className="d-flex justify-content-between pb-3"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <Col className="col-4">
            <label className="inpLblTxt">{t("Option")}</label>
            <input
              value={option}
              style={{ width: "100%" }}
              onChange={(e) => setOption(e.target.value)}
              className="inputField"
              id="Title"
            />
          </Col>
          <Col className="col-4">
            <label className="inpLblTxt">{t("Price")}</label>
            <input
              value={optionPrice}
              style={{ width: "100%" }}
              onChange={(e) => setOptionPrice(e.target.value)}
              className="inputField"
              id="Title"
              type="number"
            />
          </Col>

          <Col className="col-4 d-flex justify-content-end mt-4">
            <button
              disabled={!option}
              onClick={() => {
                setAttrs((prevData) => [
                  ...prevData,
                  { option, price: optionPrice },
                ]);
                setOption("");
                setOptionPrice(0);
              }}
            >
              {t("Add")}
            </button>
          </Col>
        </Row>

        <label className="inpLblTxt">{t("Categories")}</label>
        <br />

        {catNames.length > 0 &&
          catNames.map((item, index) => (
            <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
              {index !== 0 && "/ "}
              {item}
            </p>
          ))}

        {/* {count.map((i) => ( */}
        <div style={{ marginBottom: "1px" }}>
          <Select
            onChange={(e) => {
              setProdCategories([...prodCategories, e.value]);
              getCategories(e.value);
              setCatNames((catNames) => [...catNames, e.label]);
              setCat(e);
            }}
            value={cat}
            isDisabled={disableSelect}
            options={options}
            isSearchable
            placeholder="Select Category..."
          />
        </div>
        {catNames.length > 0 && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => {
                getCategories("");
                setCat({ value: "", label: item.categoryName });
                setCatNames([]);
                setProdCategories([]);
                setDisableSelect(false);
                setIsDisabled(false);
              }}
            >
              <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
            </button>
          </div>
        )}
        {/* <Col>
          <Row>
            <label className="inpLblTxt">Delivery Method</label>
          </Row>
          <Row>
            <Select
              isMulti
              isSearchable
              onChange={(e) => {
                setSelectDeliveryValues(e);
                let ids = e.map((a) => a.value);
                setDeliveryMethod(ids);
              }}
              isDisabled={isDisabledDelivery}
              options={deliveryOptions}
              value={selectDeliveryValues}
              placeholder="Select Delivery Methods"
            />
          </Row>
        </Col> */}
        {error && (
          <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
            {t(`${error}`)}
          </p>
        )}
        <Button
          onClick={(e) => updateProductMethod(e)}
          disabled={loading || isDisabled}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
  
};

export default ProductDetailModal;
