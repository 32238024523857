import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import AndroidDownload from "../../Assets/images/google-play-download-android-app.svg";
import IOSDownload from "../../Assets/images/app-store-download.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import logo from "../../Assets/images/BayQi-QR.png";

const MenuQRDoc = ({ qr, sellerName, toggle, modal }) => {
  const [serial, setSerial] = useState("");
  const handleExportPdf = () => {
    const doc = document.getElementById("qr-document-payment");
    // const divWidth = doc.offsetWidth;
    // const divHeight = doc.offsetHeight;
    // 
    // 
    // 
    getScreenshotOfElement(doc, (data) => {
      // 
      // $("img#captured").attr("src", "data:image/png;base64," + data);
      const pdf = new jsPDF("l", "mm");

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(data, "JPEG", 0, 0, width, height);
      pdf.save("download.pdf");
    });
  };

  const getScreenshotOfElement = (element, callback) => {
    html2canvas(element)
      .then((canvas) => {
        // var context = canvas.getContext("2d");
        // var imageData = context.getImageData(posX, posY, width, height).data;
        // var outputCanvas = document.createElement("canvas");
        // var outputContext = outputCanvas.getContext("2d");
        // outputCanvas.width = width;
        // outputCanvas.height = height;

        // var idata = outputContext.createImageData(width, height);
        // idata.data.set(imageData);
        // outputContext.putImageData(idata, 0, 0);
        // callback(outputCanvas.toDataURL().replace("data:image/jpeg;base64,", ""));

        const imageData = canvas.toDataURL("image/jpeg");
        callback(imageData);
      })
      .catch((err) => {
        
      });
  };

  const generateQrSerial = () => {
    let format = "XXXXXXQR";
    let numbers = "0123456789";
    let verificationCode = format.replace(/X/g, () => {
      return numbers.charAt(Math.floor(Math.random() * 10));
    });
    return verificationCode;
  };

  useEffect(() => {
    const ser = generateQrSerial();
    setSerial(ser);
  }, []);

  return (
    <div>
      <Modal centered size="xl" isOpen={modal} toggle={toggle}>
        <Button
          onClick={() => {
            handleExportPdf();
          }}
          type="button"
          style={{
            backgroundColor: `${blue}`,
            color: "#fff",
            border: "none",
            position: "absolute",
            top: "4px",
            right: "20px",
            width: "150px",
            // marginTop: "px",
          }}
        >
          Export PDF
        </Button>
        <ModalHeader toggle={toggle}>Download QR</ModalHeader>
        <ModalBody>
          <div>
            <div
              style={{
                backgroundColor: blue,
                textAlign: "center",
                width: "100%",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
              id="qr-document-payment"
            >
              <Row className="justify-content-center">
                <Col>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="ios dl"
                      style={{ paddingTop: "5px", width: "50%" }}
                      src={logo}
                    />
                  </Row>
                  <Row className="justify-content-center">
                    <p
                      style={{
                        color: "white",
                        marginTop: "15px",
                        marginBottom: "0px",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      PARCEIRO BAYQI
                    </p>
                    <p
                      style={{
                        color: "white",
                        // marginTop: "15px",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      {sellerName?.toUpperCase()}
                    </p>
                  </Row>
                  <Row>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "Arial",
                        // fontWeight: "bold",
                        fontSize: "30px",
                        width: "70%",
                      }}
                    >
                      SCAN PARA VER O MENU NO TELEMÓVEL
                    </p>
                  </Row>
                  <Row className="justify-content-center">
                    <p
                      style={{
                        margin: "auto",
                        color: "white",
                        fontFamily: "Arial",
                        // fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      Baixe app BayQi agora no seu telemóvel
                    </p>
                  </Row>
                  <Row
                    className="justify-content-center"
                    // style={{ marginBottom: "20px" }}
                  >
                    <Col style={{ display: "flex", justifyContent: "right" }}>
                      <img
                        alt="android dl"
                        style={{
                          paddingTop: "5px",
                          width: "60%",
                          marginBottom: "20px",
                        }}
                        src={AndroidDownload}
                      />
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "left" }}>
                      <img
                        alt="ios dl"
                        style={{
                          paddingTop: "5px",
                          width: "70%",
                          marginBottom: "20px",
                        }}
                        src={IOSDownload}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <p
                        style={{
                          color: "white",
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "0px",
                          // writingMode: "vertical-rl",
                          // textOrientation: "upright",
                          // position: "absolute",
                          // left: "40px",
                          // bottom: "50px",
                        }}
                      >
                        {`${sellerName
                          ?.toUpperCase()
                          .replace(
                            /\s/g,
                            ""
                          )}${serial}${new Date().getDate()}${new Date().getMonth()}${new Date().getFullYear()}`}
                      </p>
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "35px",
                        marginTop: "20px",
                      }}
                    >
                      SCAN QR CODE
                    </p>
                  </Row>
                  <Row className="justify-content-center">
                    <img
                      alt="qr"
                      style={{
                        width: "70%",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "30px",
                      }}
                      src={qr}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default MenuQRDoc;
