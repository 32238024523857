import React, { useState, useEffect } from "react";
import "./SideDrawer.css";
import { MdCreditCard, MdPayment } from "react-icons/md";
import { AiOutlineHistory, AiOutlineLink } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { RiFileList2Fill, RiQrCodeLine } from "react-icons/ri";
import { BiTransfer } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  TrendingUp,
  ChevronLeft,
  ChevronRight,
  Timeline,
  Category
} from "@material-ui/icons";

import { useAppSelector } from "../../Redux/hooks";
import { useTranslation } from "react-i18next";

const Sidebar = ({ children, isOpen }) => {
  const { t, i18n } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    listItemText: {
      fontSize: "13px",
      color: "#fff",
    },
    listItem: {
      "&:hover": {
        backgroundColor: "rgba(14, 75, 116, 0.4) ",
      },
    },
    icon: {
      color: "red",
    },
  }));
  const classes = useStyles();
  const [isOpenState, setIsOpen] = useState(null);
  const [routes, setRoutes] = useState([
    {
      id: 1,
      path: "/",
      name: t("Stats"),
      Icon: <TrendingUp style={{ color: "#fff" }} />,
      active: true,
    },
    {
      id: 2,
      path: "/Transactions",
      name: t("Transactions"),
      Icon: <MdCreditCard color={"white"} size={"1.3rem"} />,
      active: false,
    },
    {
      id: 3,
      path: "/TransactionHistory",
      name: t("Transaction History"),
      Icon: <AiOutlineHistory color={"white"} size={"1.3rem"} />,
      active: false,
    },
    {
      id: 4,
      path: "/GenerateLink",
      name: t("Generate Links"),
      Icon: <AiOutlineLink color={"white"} size={"1.3rem"} />,
      active: false,
    },
    // {
    //   id: 5,
    //   path: "/PayPerLink",
    //   name: "Link Paymentsss",
    //   Icon: <FaExternalLinkSquareAlt color={"white"} size={"1.3rem"} />,
    //   active: false,
    // },
    // {
    //   id: 6,
    //   path: "/PayByCatalog",
    //   name: "QR Code payments",
    //   Icon: <GiPayMoney color={"white"} size={"1.3rem"} />,
    //   active: false,
    // },
    // {
    //   id: 10,
    //   path: "/Discount",
    //   name: "Discount",
    //   Icon: <LoyaltyOutlined style={{ color: "#fff" }} size={"1.3rem"} />,
    //   active: false,
    // },
    {
      id: 11,
      path: "/QRCode",
      name: t("QR Code"),
      Icon: <RiQrCodeLine color={"white"} size={"1.3rem"} />,
      active: false,
    },
    {
      id: 12,
      path: "/Swing",
      name: t("Payment Cycle"),
      Icon: <MdPayment color={"white"} size={"1.3rem"} />,
      active: false,
    },
    {
      id: 13,
      path: "/BankTransfer",
      name: t("Bank Transfer"),
      Icon: <BiTransfer color={"white"} size={"1.3rem"} />,
      active: false,
    },
    {
      id: 14,
      path: "/BankTransferHistory",
      name: t("Bank Transfer History"),
      Icon: <Timeline style={{ color: "#fff" }} size={"1.3rem"} />,
      active: false,
    },
  ]);

  const selectTab = (index) => {
    let dupRoutes = [...routes];
    dupRoutes.forEach((element, i) => {
      if (index === i) {
        dupRoutes[i].active = true;
      } else {
        dupRoutes[i].active = false;
      }
    });
    setRoutes(dupRoutes);
  };

  const updateDimensions = () => {
    if (window.innerWidth < 900) {
      setIsOpen(false);
    } else if (window.innerWidth > 900) {
      setIsOpen(true);
    }
  };
  // const [transactionListVisibility, setTransactionListVisibility] =
  //   React.useState(false);
  // const [logisticsListVisibility, setLogisticListVisibility] =
  //   React.useState(false);
  // const [showTransactionChildren, setShowTransactionChildren] =
  //   React.useState(false);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const sellerType = useAppSelector((state) => state.auth.sellerType);

  useEffect(() => {
    if (sellerType === "Event") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          id: 9,
          path: "/Definitions",
          name: t("Delivery Zones"),
          Icon: <FaTruck color={"white"} size={"1.3rem"} />,
          active: false,
        },
        {
          id: 101,
          path: "/Event",
          name: t("Events"),
          Icon: <RiFileList2Fill color={"white"} size={"1.3rem"} />,
          active: false,
        },
        {
          id: 102,
          path: "/EventType",
          name: t("Event Type"),
          Icon: <RiFileList2Fill color={"white"} size={"1.3rem"} />,
          active: false,
        },
      ]);
    } else if (sellerType === "") {
      
    } else if (sellerType == "Fast Food" || sellerType == "Bar & Restaurant") {
      setRoutes((oldArr) => [
        ...oldArr,
        {
          id: 104,
          path: "/Definitions",
          name: t("Delivery Zones"),
          Icon: <FaTruck color={"white"} size={"1.3rem"} />,
          active: false,
        },
        // {
        //   id: 9,
        //   path: "/HouseOfOperations",
        //   name: "House Of Operations",
        //   Icon: <FaTruck color={"white"} size={"1.3rem"} />,
        //   active: false,
        // },
        {
          id: 103,
          path: "/Products",
          name: t("Products"),
          Icon: <RiFileList2Fill color={"white"} size={"1.3rem"} />,
          active: false,
        },
        {
          id: 7,
          path: "/Categories",
          name: t("Categories"),
          Icon: <Category style={{ color: "#fff" }} size={"1.3rem"} />,
          active: false,
        },
      ]);
    } else if (sellerType === "Pay") {
      setRoutes((oldArr) => [
        ...oldArr,
      {
        id: 9,
        path: "/Definitions",
        name: t("Delivery Zones"),
        Icon: <FaTruck color={"white"} size={"1.3rem"} />,
        active: false,
      }
    ]);
    } else {
      // supermarket and cantinas falls here
      setRoutes((oldArr) => [
        ...oldArr,
        {
          id: 9,
          path: "/Definitions",
          name: t("Delivery Zones"),
          Icon: <FaTruck color={"white"} size={"1.3rem"} />,
          active: false,
        },
        {
          id: 8,
          path: "/Products",
          name: t("Products"),
          Icon: <RiFileList2Fill color={"white"} size={"1.3rem"} />,
          active: false,
        },
        {
          id: 7,
          path: "/Categories",
          name: t("Categories"),
          Icon: <Category style={{ color: "#fff" }} size={"1.3rem"} />,
          active: false,
        },
      ]);
    }
  }, []);

  return (
    <div
      className="hideScrollbar"
      style={{
        display: "flex",
        backgroundColor: "#f7f7f7",
        width: "100%",
        overflowY: "scroll",
        marginTop: "30px",
        position: "absolute",
        bottom: "0",
      }}
    >
      {isOpenState ? (
        <div
          className="open-sidedrawer hideScrollbar"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: "70px",
          }}
        >
          <List>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <ChevronLeft
                onClick={() => setIsOpen(!isOpenState)}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </div>
            {routes.map((item, index) => (
              <ListItem
                disableRipple
                className={classes.listItem}
                button
                // component={((item.path) === "/Transactions") ? null : Link}
                component={Link}
                to={item.path}
                key={index}
                onClick={() => {
                  // if (item.path === "/Transactions") {
                  //   setTransactionListVisibility(!transactionListVisibility)
                  // }
                  // if (item.path === "/BayqiLogistics") {
                  //   setLogisticListVisibility(!logisticsListVisibility)
                  // }
                  window.localStorage.setItem("current", item.name);
                  selectTab(index);
                }}
                style={{
                  // backgroundColor: item.active
                  backgroundColor:
                    window.localStorage.getItem("current") === item.name
                      ? "rgba(14, 75, 116, 0.8)"
                      : "rgba(0,0,0,0)",
                  // display: showTransactionChildren ? "none" : "flex"
                  // display: item.hide ? "none" : 'flex'
                }}
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.name}
                />
                {/* {((item.name) === "Transactions" || (item.name) === "Bayqi Logistics") ? <BiChevronDown color={"white"} size={"1.3rem"} /> : null} */}
              </ListItem>
            ))}
          </List>
        </div>
      ) : (
        <div
          // previous working fine things
          // className={"side-drawer"} style={{ overflow: 'hidden' }}

          // mine
          className="side-drawer hideScrollbar"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: "70px",
          }}
        >
          <List>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2rem",
                paddingBottom: "1rem",
              }}
            >
              <ChevronRight
                onClick={() => setIsOpen(!isOpenState)}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </div>
            {routes.map((item, index) => (
              <Tooltip placement="right-end" title={item.name}>
                <ListItem
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  button
                  component={Link}
                  to={item.path}
                  key={index}
                >
                  <ListItemIcon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.Icon}
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </div>
      )}
      <div
        style={{
          width: "100%",
          backgroundColor: "#f7f7f7",
          // padding: "3% 0px 3% 0px",
          height: "100vh",
          padding: "90px 24px 0px 24px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
