import React, { useState } from "react";
import { deleteCategory } from "../../../api/categories/categories";
import { Row, Col, Spinner } from "react-bootstrap";
import { ButtonToggle } from "reactstrap";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ id, toggle }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const deleteCategoryMethod = () => {
    setLoading(true);
    deleteCategory(id)
      .then((res) => {
        if (res.data.message === "Remove product before delete category") {
          setError(`${"This category has products. Can't be deleted"}!`);
        } else {
          toggle(true);
          
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        
      });
  };
  return (
    <div>
      <Row>
        {" "}
        <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
          {t(`${error}`)}
        </p>{" "}
      </Row>
      <Row>
        <Col>
          <div style={{ display: "flex" }}>
            <ButtonToggle
              onClick={deleteCategoryMethod}
              disabled={loading}
              style={{
                margin: "20px 0px",
                backgroundColor: "#E65B65",
                borderWidth: 0,
                marginRight: "10px",
              }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "white", padding: "9px" }}
                />
              ) : (
                t("Yes")
              )}
            </ButtonToggle>

            <ButtonToggle
              onClick={toggle}
              disabled={loading}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
              }}
            >
              {t("No")}
            </ButtonToggle>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeleteModal;
