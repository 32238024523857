import React, { useState } from "react";
import { ButtonToggle } from "reactstrap";
import { Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import { blue } from "../../Globals/colors";
import { Storage } from "aws-amplify";
import { baseUrl } from "../../Helpers/helper";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateGeneratedLink } from "../../../api/generateLInks/generateLinks";

const UpdateLinkModal = ({ toggle, item }) => {
  const [title, setTitle] = useState(item.name);
  const [image, setImage] = useState(item.image);
  const [type, setType] = useState(item.type);
  const [qty, setQty] = useState(item.Qty);
  const [price, setPrice] = useState(item.price);
  const [deliveryMethod, setDeliveryMethod] = useState(item.deliveryMethods);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(item.loading);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [error, setError] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [selectDeliveryValues, setSelectDeliveryValues] = useState([]);

  React.useEffect(() => {
    getMethods();
    // setDeliveryValues();
  }, []);

  const setDeliveryValues = () => {
    const tempArray = [];

    item.deliveryData?.forEach((item) => {
      tempArray.push({ value: item._id, label: item.title });
    });

    setSelectDeliveryValues(tempArray);
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            const tempArray2 = [];
            data.forEach((item) => {
              tempArray.push({ value: item._id, label: item.title });
              tempArray2.push( item._id );
            });
            setDeliveryMethod(tempArray2);
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const onFileChange = async (e) => {
    setLoading(true);

    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImage(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    setLoading(true);

    try {
      await Storage.remove(file).then((res) => {
        
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      
    }
  };

  const updateLink = () => {
    setError("");
    setLoading(true);
    let body;

    if (
      type === "" ||
      title === "" ||
      price === "" ||
      image === "" ||
      (type === "product" && qty === "") ||
      (type === "product" && deliveryMethod.length === 0)
    ) {
      setError(t("*All fields are mandatory."));
      setLoading(false);
    } else {
      if (type === "product") {
        body = {
          type,
          name: title,
          price,
          Qty: qty,
          image: image,
          deliveryMethods: deliveryMethod,
        };
      } else {
        body = {
          type,
          name: title,
          price,
          image: image,
          deliveryMethods: deliveryMethod,
        };
      }

      updateGeneratedLink(item, body)
        .then((res) => {
          
          toggle(true);
        })
        .catch((err) => {
          
        });
    }
  };

  return (
    <div style={{ padding: "0px 30px" }}>
      <Row className="d-flex flex-column">
        <Col className="col-12 inpLblTxt">{t("Images")}</Col>
      </Row>

      <Row className="align-items-start flex-row">
        <Col style={{ display: "flex", flexDirection: "row" }}>
          {image !== "" && (
            <img
              alt=""
              src={image}
              width={"65px"}
              height={"65px"}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                onDeleteImage(image);
                setImage("");
              }}
              onMouseEnter={() => setShowDeleteText(true)}
              onMouseLeave={() => setShowDeleteText(false)}
            />
          )}
          {image === "" && (
            <>
              <input
                style={{ display: "none" }}
                id="imageUpload"
                type="file"
                onChange={(e) => onFileChange(e)}
              />
              <label htmlFor="imageUpload" className="imgUploadLbl">
                <span>+</span>
                <p>{t("Add Image")}</p>
              </label>
            </>
          )}
        </Col>
        {image !== "" && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              visibility: !showDeleteText ? "hidden" : "visible",
            }}
          >
            *{t("click to delete")}
          </p>
        )}
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-6">
          <label className="inpLblTxt">
            {t("Type")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          {/* <Select
            value={{
              value: type,
              label:
                type === "service"
                  ? t("Service")
                  : type === "product"
                  ? t("Product")
                  : type,
            }}
            style={{ width: "100%" }}
            options={[
              { value: "product", label: t("Product") },
              { value: "service", label: t("Service") },
            ]}
            onChange={({ value }) => {
              if (value === "service") {
                setQty("");
              }
              setType(value);
            }}
          /> */}
          <input  style={{ paddingLeft:"5px", width: "100%" }} className="inputField" type="text" placeholder="Product" disabled />
        </Col>
        {type === "product" && (
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Quantity")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{ width: "100%" }}
              className="inputField"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-6">
          <label className="inpLblTxt">
            {t("Title")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            style={{ width: "100%" }}
            className="inputField"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="Title"
          />
        </Col>
        <Col className="col-6">
          <label className="inpLblTxt">
            {t("Price")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            style={{ width: "100%" }}
            className="inputField"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            id="Price"
          />
        </Col>
      </Row>
      {/* <Row>
        <label className="inpLblTxt">
          Delivery Method<p style={{ color: "red", display: "inline" }}> *</p>
        </label>
        <Select
          isMulti
          isSearchable
          onChange={(e) => {
            setSelectDeliveryValues(e);
            let ids = e.map((a) => a.value);
            setDeliveryMethod(ids);
          }}
          isDisabled={isDisabledDelivery}
          options={deliveryOptions}
          value={selectDeliveryValues}
          placeholder="Select Delivery Methods"
        />
      </Row> */}

      {<p style={{ color: "red", fontSize: "12px" }}>{t(`${error}`)}</p>}

      <Row>
        <Col md={6}>
          <Button
            onClick={() => updateLink()}
            disabled={loading}
            style={{
              // margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateLinkModal;
