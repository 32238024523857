import axios from "./axiosInterceptor";

export const getLatestTransactions = (startDate, endDate) =>
  axios.get(
    `/transactions/history?limit=25&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );

export const getTransactionHistory = (
  limit,
  activePage,
  debounceSearch,
  startDate,
  endDate
) =>
  axios.get(
    `/transactions/history?limit=${limit}&page=${activePage}&transactionId=${debounceSearch}&orderStatus=COMPLETED&transactionType=&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );
