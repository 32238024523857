import React, { useState } from "react";
import { Row, Col, Table, Pagination, Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonToggle,
} from "reactstrap";
import { TextareaAutosize } from "@material-ui/core";
import { blue } from "../../Globals/colors";
import Select from "react-select";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import ProductDetailModal from "./ProductDetailModal";
import { v4 as uuid } from "uuid";
import { provinces } from "../../DummyData";
import CreatableSelect from "react-select/creatable";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import { addProduct } from "../../../api/products/products";
import { deleteProduct } from "../../../api/products/products";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { getProductsByName } from "../../../api/products/products";
import { updateProduct } from "../../../api/products/products";
import AddContentModal from "./AddContentModal";
import DeleteModal from "./DeleteModal";
import Pages from "../../Components/Pagination/Pagination";

export default function ShoppingProducts() {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [currItem, setCurrItem] = useState(null);
  const [show, setShow] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName] = useDebounce(searchName, 1000);
  const { t, i18n } = useTranslation();
  function handleShow() {
    setShow(true);
  }
  // delte modal related
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const [deleteProductId, setDeleteProductId] = useState("");

  // pagination
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);

  // for no. of pages
  // React.useEffect(() => {
  //   setNoOfPages([]);
  //   setLoading(true);
  //   getProductsByName(searchName,limit,activePage)
  //     .then((res) => {
  //       setLoading(false);
  //       let num = res.data.data.length / 10;
  //       if (num < 1) setNoOfPages([1]);
  //       else {
  //         num = Math.ceil(num);
  //         const tempArray = [];
  //         for (let i = 0; i < num; i++) {
  //           tempArray.push(i + 1);
  //         }
  //         setNoOfPages(tempArray);
  //       }
  //     })
  //     .catch((err) => {
  //       
  //     });
  // }, [activePage, debouncedSearchName,limit]);

  const addToggle = () => {
    setAddModal(!addModal);
    loadProducts();
  };

  // this is for data
  React.useEffect(() => {
    loadProducts();
  }, [activePage, debouncedSearchName,limit]);

  const loadProducts = () => {
    const sid = localStorage.getItem("uid");
    setLoading(true);
    getProductsByName(limit, activePage, searchName)
      .then((res) => {
        const { success, message, data } = res.data;
     
          setTableData(data);
          setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((error) => {
        
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }
  
  
  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <Col
            md={8}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Products")}
              </span>
            </div>
          </Col>
          {/* search by name */}
          <Col md={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Search by Name")}
            </span>
            <input
              type="search"
              class="form-control "
              placeholder={t("Search")}
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
          </Col>
          <Col md={2}>
            <Button
              style={{
                backgroundColor: blue,
                border: "none",
                marginTop: "24px",
              }}
              type="button"
              onClick={() => setAddModal(true)}
            >
              {t("Add")}
            </Button>
          </Col>
        </Row>
      </div>

      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Product")}
            </th>

            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Image")}</th>

            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Price")}</th>
            {/* <th style={{ padding: "20px", fontSize: "14px" }}>{t("QTY")}</th> */}
            <th
              style={{ padding: "20px", fontSize: "14px", textAlign: "center" }}
            >
              {t("Description")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Delete")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.slice(0, limit).map((item, index) => (
            <tr
              key={index}
              onClick={() => {
                setCurrItem(item);
                toggle();
              }}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ padding: "20px" }}>{item.Name}</td>
              <td style={{ padding: "20px" }}>
                {item.Images && (
                  <img
                    src={item.Images[0]}
                    alt="product"
                    width={"45px"}
                    height={"45px"}
                  />
                )}
              </td>
              <td style={{ padding: "20px" }}>
                {new Intl.NumberFormat().format(item.Price)} AKZ
              </td>

              {/* <td style={{ padding: "20px" }}>{item.Qty}</td> */}
              <td style={{ padding: "20px", textAlign: "center" }}>
                {item.Description}
              </td>
              {/* <td style={{ padding: "20px" }}>{item.SKU}</td> */}
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteProductId(item._id);
                  toggleDeleteModal();
                }}
                style={{
                  padding: "20px",
                  textDecoration: "underline",
                  color: "red",
                }}
              >
                {t("delete")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Pagination */}
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  {/* No of products on page toggler */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          marginTop: "0",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem
                          onClick={() => {
                            setLimit(1);
                            setActivePage(1);
                          }}
                        >
                          1
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(5);
                            setActivePage(1);
                          }}
                        >
                          5
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(10);
                            setActivePage(1);
                          }}
                        >
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
      {/* delete product modal */}
      <Modal size="sm" centered isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          {t("Delete Product?")}
        </ModalHeader>
        <ModalBody>
          <DeleteModal
            id={deleteProductId}
            toggle={toggleDeleteModal}
            loadProducts={loadProducts}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* product detail modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Product Details")}</ModalHeader>
        <ModalBody>
          <ProductDetailModal
            item={currItem}
            toggle={toggle}
            loadProducts={loadProducts}
          />
          {/* <ModalLayout item={currItem} /> */}
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* Add product */}
      <Modal isOpen={addModal} toggle={addToggle}>
        <ModalBody>
          <div className="header-addProduct">
            <h4>{t("Add product")}</h4>

            <AiFillCloseCircle
              onClick={addToggle}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "red",
                cursor: "pointer",
              }}
              size={"1.5rem"}
            />
          </div>
          <hr />

          <AddContentModal toggle={addToggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
