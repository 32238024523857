import React, { useState } from "react";
import { Row, Col, Table, Pagination, Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { Input } from "reactstrap";
import { TextareaAutosize } from "@material-ui/core";
import { blue } from "../../Globals/colors";
// import "./Products.scss";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { getCategoriesList } from "../../../api/categories/categories";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";
import { updateProduct } from "../../../api/products/products";

const BookDetailModal = ({ toggle, item, loadProducts }) => {
  // 
  const [path, setPath] = useState(null);
  const [imgPaths, setImgPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  // const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  var o = [];
  var c = 0;
  var [count, setCount] = useState([]);
  var [isDisabled, setIsDisabled] = useState(false);
  var files = [];

  const [prodImages, setProdImages] = useState([]);
  const [prodCategories, setProdCategories] = useState([]);
  const [title, setTitle] = useState(item.Name);
  const [price, setPrice] = useState(item.Price);
  const [sku, setSku] = useState("");
  const [author, setAuthor] = useState(item.Author);
  const [qty, setQty] = useState(item.Qty);
  const [description, setDescription] = useState(item.Description);
  const [type, setType] = useState(item.BookType);
  const [book, setBook] = useState(item.Book);

  const [catNames, setCatNames] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [cat, setCat] = useState({ value: "", label: item.categoryName });

  const [shortDescription, setShortDescription] = useState(
    item.ShortDescription
  );
  const [longDescription, setLongDescription] = useState(item.LongDescription);
  // modal types
  const [modalType, setModalType] = useState("addProduct");
  const [productUrl, setProductUrl] = useState("");

  const [deliveryOptions, setDeliveryOptions] = useState("");
  const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [selectDeliveryValues, setSelectDeliveryValues] = useState([]);

  const [error, setError] = useState("");

  React.useEffect(() => {
    getCategories("");
    getMethods();
    setDeliveryValues();
  }, []);

  const setDeliveryValues = () => {
    const tempArray = [];

    item.deliveryData.map((item) => {
      tempArray.push({ value: item._id, label: item.title });
    });

    setSelectDeliveryValues(tempArray);
  };

  const getMethods = () => {
    getDeliveryMethods()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabledDelivery(true);
          } else {
            const tempArray = [];
            data.map((item) => {
              tempArray.push({ value: item._id, label: item.title });
            });
            setDeliveryOptions(tempArray);
            setIsDisabledDelivery(false);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  const getCategories = (id) => {
    if (id === "") {
      id = "null";
    } else {
      setIsDisabled(true);
    }

    getCategoriesList()
      .then((res) => {
        const { success, message, data } = res.data;
        if (success) {
          if (data.length === 0) {
            setIsDisabled(false);
            setDisableSelect(true);
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  // checks if olderImages and images are same or not
  const checkImgArrays = () => {
    const oldArr = olderImages.sort();
    const newArr = images.sort();

    return (
      Array.isArray(oldArr) &&
      Array.isArray(newArr) &&
      oldArr.length === newArr.length &&
      oldArr.every((item, index) => item === newArr[index])
    );
  };

  const checkCategories = () => {
    if (prodCategories.length > 0) {
      const oldCat = item.CategoryId.sort();
      const newCat = prodCategories.sort();

      return (
        Array.isArray(oldCat) &&
        Array.isArray(newCat) &&
        oldCat.length === newCat.length &&
        oldCat.every((item, index) => item === newCat[index])
      );
    } else return true;
  };

  const checkDelivery = () => {
    if (deliveryMethod.length > 0) {
      const oldDelivery = item.deliveryMethod?.sort();
      const newDelivery = deliveryMethod.sort();

      return (
        Array.isArray(oldDelivery) &&
        Array.isArray(newDelivery) &&
        oldDelivery.length === newDelivery.length &&
        oldDelivery.every((item, index) => item === newDelivery[index])
      );
    } else return true;
  };

  const updateProductMethod = async (e) => {
    setLoading(true);
    setError("");
    if (
      title === item.Name &&
      price === item.Price &&
      shortDescription === item.ShortDescription &&
      longDescription === item.LongDescription &&
      author === item.Author &&
      // qty === item.Qty &&
      type === item.BookType &&
      description === item.Description &&
      type === "printBook" &&
      qty === item.Qty &&
      type === "ebook" &&
      book === item.Book &&
      checkDelivery() &&
      checkImgArrays() &&
      checkCategories()
    ) {
      setError(`${t("*Change any field to update record")}.`);
      setLoading(false);
    } else if (images.length < 1) {
      setError(t("*Add atleast one image"));
      setLoading(false);
    } else {
      let body = {};
      if (title !== item.Name) {
        body.Name = title;
      }
      if (price !== item.Price) {
        body.Price = price;
      }
      if (author !== item.Author) {
        body.Author = author;
      }
      if (shortDescription !== item.ShortDescription) {
        body.ShortDescription = shortDescription;
      }
      if (longDescription !== item.LongDescription) {
        body.LongDescription = longDescription;
      }
      if (description !== item.Description) {
        body.Description = description;
      }
      if (!checkImgArrays()) {
        body.Images = images;
      }
      if (!checkCategories()) {
        body.CategoryId = prodCategories;
      }
      if (!checkDelivery()) {
        body.deliveryMethod = deliveryMethod;
      }
      if (type !== item.BookType) {
        body.BookType = type;

        if (type === "printBook") {
          body.Book = undefined;
        } else if (type === "eBook") {
          body.Qty = undefined;
        }
      }
      if (type === "eBook" && book !== item.Book) {
        body.Book = book;
      }
      if (type === "printBook" && qty !== item.Qty) {
        body.Qty = qty;
      }

      updateProduct(item._id, body)
        .then((res) => {
          setLoading(false);
          toggle();
          loadProducts();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };

  // older images to compare b4 updating
  const [olderImages] = useState(item.Images);
  const [images, setImages] = useState(item.Images);
  const [showDeleteText, setShowDeleteText] = useState(false);

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    setLoading(true);

    try {
      await Storage.remove(file).then((res) => {
        
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      
    }
  };

  //using s3 bucket
  const onFileChange = async (e) => {
    setLoading(true);

    try {
      await Storage.put(e.target.files[0].name, e.target.files[0]).then(
        (res) => {
          
          setImages((oldUrls) => [
            ...oldUrls,
            `${process.env.REACT_APP_S3_URL}/public/${res.key}`,
          ]);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const onBookUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const fileKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
    try {
      await Storage.put(fileKey, file).then((res) => {
        setBook(res.key);
        setLoading(false);
      });
    } catch (error) {
      
      setLoading(false);
    }
  };

  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex flex-column">
          <Col className="col-12 inpLblTxt">{t("Product images")}</Col>
        </Row>

        <Row className="align-items-start flex-row">
          <Col style={{ display: "flex", flexDirection: "row" }}>
            {images.map((item) => (
              <img
                alt={""}
                src={item}
                width={"65px"}
                height={"65px"}
                style={{ marginRight: "5px" }}
                onClick={() => {
                  const newImages = images.filter((img) => img != item);
                  setImages(newImages);
                  onDeleteImage(item);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
            ))}
            {images.length < 3 && (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            )}
          </Col>
          {images.length > 0 && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              {t("*click to delete")}
            </p>
          )}
        </Row>

        {/* {path && <img src={path} width="400px" />}

        <div className="d-flex py-3">
          {imgPaths[0] &&
            imgPaths.map((path, index) => (
              <div className="d-flex flex-column">
                <img
                  style={{ marginRight: "10px" }}
                  key={index}
                  src={path}
                  width={67}
                  height={67}
                />
              </div>
            ))}
          <input
            style={{ display: "none" }}
            id="imageUpload"
            type="file"
            onChange={(e) => onFileChange(e)}
          />
          <label
            style={{ visibility: imgPaths.length >= 3 ? "hidden" : "initial" }}
            htmlFor="imageUpload"
            className="imgUploadLbl"
          >
            +
          </label>
        </div> */}
        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Title")}</label>
            <input
              className="inputField"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="Title"
            />
          </Col>

          <Col className="col-6">
            <label className="inpLblTxt">{t("Price")}</label>
            <input
              className="inputField"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="Title"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-6">
            <label className="inpLblTxt">{t("Author")}</label>
            <input
              className="inputField"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Col>

          {type === "printBook" && (
            <Col className="col-6">
              <label className="inpLblTxt">{t("Quantity")}</label>
              <input
                className="inputField"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                id="Title"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </Col>
          )}
        </Row>

        <Row className="d-flex justify-content-between mt-1">
          <Col className="col-6">
            <label className="inpLblTxt">
              {t("Book Type")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <Select
              value={{
                value: type,
                label:
                  type === "eBook"
                    ? "E-Book"
                    : type === "printBook"
                    ? "Print Book"
                    : type,
              }}
              options={[
                { label: "E-book", value: "eBook" },
                { label: "Print Book", value: "printBook" },
              ]}
              onChange={({ value }) => setType(value)}
            />
          </Col>

          {type === "eBook" && (
            <Col className="col-6">
              <label className="inpLblTxt">
                {t("Upload Book")}
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
              <input
                type="file"
                value={book}
                onChange={(e) => onBookUpload(e)}
              />
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Short description")}
            </label>

            <Input
              type="textarea"
              maxLength={600}
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label htmlFor="lastName" className="inpLblTxt">
              {t("Long Description")}
            </label>
            <TextareaAutosize
              className="inputTxtarea"
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
              minRows={5}
            />
          </Col>
        </Row>

        <label className="inpLblTxt">{t("Categories")}</label>
        <br />

        {catNames.length > 0 &&
          catNames.map((item, index) => (
            <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
              {index !== 0 && "/ "}
              {item}
            </p>
          ))}
        {/* {count.map((i) => ( */}
        <div style={{ marginBottom: "10px" }}>
          <Select
            // isDisabled={true}
            onChange={(e) => {
              setProdCategories([...prodCategories, e.value]);
              getCategories(e.value);
              setCatNames((catNames) => [...catNames, e.label]);
              setCat({ value: e.value, label: "Select..." });
            }}
            value={cat}
            isDisabled={disableSelect}
            options={options}
            isSearchable
            placeholder="Select Category..."
          />
        </div>
        {catNames.length > 0 && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => {
                getCategories("");
                setCat({ value: "", label: item.categoryName });
                setCatNames([]);
                setProdCategories([]);
                setDisableSelect(false);
                setIsDisabled(false);
              }}
            >
              <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
            </button>
          </div>
        )}
        {/* {item.BookType === "printBook" && (
          <Col>
            <Row>
              <label className="inpLblTxt">Delivery Method</label>
            </Row>
            <Row>
              <Select
                isMulti
                isSearchable
                onChange={(e) => {
                  setSelectDeliveryValues(e);
                  let ids = e.map((a) => a.value);
                  setDeliveryMethod(ids);
                }}
                isDisabled={isDisabledDelivery}
                options={deliveryOptions}
                value={selectDeliveryValues}
                placeholder="Select Delivery Methods"
              />
            </Row>
          </Col>
        )} */}
        {error && (
          <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
            {t(`${error}`)}
          </p>
        )}
        <Button
          onClick={(e) => updateProductMethod(e)}
          disabled={loading || isDisabled}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    )
};

export default BookDetailModal;
