import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Spinner,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import EventDetailModal from "./EventsDetailModal";
import { AiFillCloseCircle} from "react-icons/ai";
import { blue } from "../../Globals/colors";
import Pages from "../../Components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import {
  getEventVenues,
  getSellerEvents,
  getSellerEventsByActivePage
} from "../../../api/events/events";
import { getApprovedEventCategories } from "../../../api/categories/categories";
import AddModal from "./AddModal";
import DeleteEventModal from "./DeleteEventModal";


export default function Event() {
  const [venuesData, setVenuesData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [activeTab, setActiveTab] = useState("venues");
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [loading, setLoading] = useState(false);
  const [approvedEventTypes, setApprovedEventTypes] = useState([]);
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    getApprovedEventCategories()
      .then((res) => {
        setApprovedEventTypes(res.data.data);
      })
      .catch((err) => {
        
      });
}, []);

  // add venue modal
  const [addEventModal, setAddEventModal] = useState(false);
  const toggleEventModal = () => setAddEventModal(!addEventModal);

  // add event modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // raw venues Data
  const [venues, setVenues] = useState();

  // delete event modal related
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const toggleDeleteEventModal = () => setDeleteEventModal(!deleteEventModal);

  const [currEventId, setCurrEventId] = useState("");
  const [currEventData, setCurrEventData] = useState(null);

  // delete venue modal related
  const [deleteVenueModal, setDeleteVenueModal] = useState(false);
  const toggleDeleteVenueModal = () => setDeleteVenueModal(!deleteVenueModal);

  const [currVenueId, setCurrVenueId] = useState("");
  const [currVenueData, sertCurrVenueData] = useState("");

  // event detail modal related
  const [eventDetailModal, setEventDetailModal] = useState(false);
  const toggleEventDetailModal = () => setEventDetailModal(!eventDetailModal);

  const [venueDetailModal, setVenueDetailModal] = useState(false);
  const toggleVenueDetailModal = () => setVenueDetailModal(!venueDetailModal);

  // events places Pagination
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);

  // this effect is only determinig total no of pages
  React.useEffect(() => {
    updateVenues();
  }, [activePage]);
  const updateVenues = () => {
    getEventVenues().then((res) => {
      setVenues(res.data.result);
      let num = res.data.total / limitEvent;
      if (num < 1) setNoOfPagesEvent([1]);
      else {
        num = Math.ceil(num);
        const tempArray = [];
        for (let i = 0; i < num; i++) {
          tempArray.push(i + 1);
        }
        setNoOfPagesEvent(tempArray);
        
      }
    });
  };

  // get event Venues data
  // React.useEffect(() => {
  //   setNoOfPages([]);
  //   setLoading(true);
  //   getEventVenues(10, activePage)
  //     .then((res) => {
  //       setVenuesData(res.data.result);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       
  //     });
  // }, [activePage]);

  // get updated venues data
  const getUpdatedVenuesData = () => {
    // setNoOfPages([]);
    // TODO: here i will have to set noOfPages again
    setLoading(true);
    getEventVenues(limit, activePage)
      .then((res) => {
        setVenuesData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        
      });
  };

  // events pagination
  const [activeEventPage, setActiveEventPage] = useState(1);
  const [limitEvent, setLimitEvent] = useState(10);
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPagesEvent, setNoOfPagesEvent] = useState([]);

  // this effect is only determinig total no of event pages
  React.useEffect(() => {
    setLoading(true);
    getSellerEvents(limitEvent,activeEventPage)
    .then((res) => {
      setEventsData(res.data.result);
      setLoading(false);
      let num = res.data.total / limitEvent;
      if (num < 1) setNoOfPagesEvent([1]);
      else {
        num = Math.ceil(num);
        const tempArray = [];
        for (let i = 0; i < num; i++) {
          tempArray.push(i + 1);
        }
        setNoOfPagesEvent(tempArray);
        
      }
    });
  }, [activeEventPage,limitEvent]);

  // get events data
  React.useEffect(() => {
    setNoOfPagesEvent([]);
    setLoading(true);
    getSellerEventsByActivePage(activeEventPage,limitEvent)
      .then((res) => {
        setEventsData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [activeEventPage,limitEvent]);

  // get updated events data
  const getUpdatedEvents = () => {
    // setNoOfPagesEvent([]);
    // TODO: here i will have to set noOfPagesEvent again
    setLoading(true);
    getSellerEventsByActivePage(activeEventPage)
      .then((res) => {
        setEventsData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // 
      });
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
  
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <Col
            xl={5}
            lg={5}
            md={3}
            style={{
              padding: "0px",
              paddingLeft: "34px",
              margin: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                {t("Events")}
              </span>
            </div>
          </Col>
          <Col sm={2}>
            <Button
              onClick={toggle}
              style={{
                backgroundColor: blue,
                border: "none",
                marginBottom: "10px",
              }}
            >
              {t("Add")}
            </Button>
          </Col>
        </Row>
      </div>
      {/* Table */}
      {eventsData.length > 0 && (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Event Venue")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Image")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Description")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Start date")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("End date")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Timings")}
              </th>
              {/* <th style={{ padding: "20px", fontSize: "14px" }}>Entry Fee</th> */}
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Category")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {eventsData.slice(0, limitEvent).map((item, index) => (
              <tr
                onClick={() => {
                  setCurrEventId(item._id);
                  toggleEventDetailModal();
                  setCurrEventData(item);
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>{item.eventPlace}</td>
                <td style={{ padding: "20px" }}>
                  <img alt="" src={item.image} height={"40px"} width={"40px"} />
                </td>
                <td style={{ padding: "20px" }}>{item.eventName}</td>
                <td style={{ padding: "20px" }}>{item.eventDescription}</td>
                <td style={{ padding: "20px" }}>
                  {new Date(item.startDate).toDateString()}
                </td>
                <td style={{ padding: "20px" }}>
                  {new Date(item.endDate).toDateString()}
                </td>
                <td style={{ padding: "20px" }}>{item.timing}</td>
                {/* <td style={{ padding: "20px" }}>
                                        {new Intl.NumberFormat().format(item.entryFees)} AKZ
                                    </td> */}
                <td style={{ padding: "20px" }}>{item.category}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrEventId(item._id);
                    toggleDeleteEventModal();
                  }}
                  style={{
                    padding: "20px",
                    color: "red",
                    textDecoration: "underline",
                  }}
                >
                  {t("delete")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {/* below the table content */}
      {eventsData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {/* pagination */}
              <div>
                <Pages
                  noOfPages={noOfPagesEvent}
                  activePage={activeEventPage}
                  setActivePage={setActiveEventPage}
                />
              </div>

              {/* items per page toggler */}
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      marginTop: "-20px",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limitEvent}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem onClick={() => {
                            setLimitEvent(1);
                            setActivePage(1);
                          }}>
                      1
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                            setLimitEvent(5);
                            setActivePage(1);
                          }}>
                      5
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                            setLimitEvent(10);
                            setActivePage(1);
                          }}>
                      10
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {/* add event modal */}
      <Modal isOpen={modal} toggle={toggle} className="position-relative">
        <AiFillCloseCircle
          onClick={toggle}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />

        <ModalHeader toggle={toggle}>{t("Add event")}</ModalHeader>
        <ModalBody>
          <AddModal
            toggle={toggle}
            venues={venues}
            getUpdatedEvents={getUpdatedEvents}
            approvedEventTypes={approvedEventTypes}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
      {/* delete event modal */}
      <Modal
        centered
        size="sm"
        isOpen={deleteEventModal}
        toggle={toggleDeleteEventModal}
      >
        <ModalHeader toggle={toggleDeleteEventModal}>
          {t("Delete event")}?
        </ModalHeader>
        <ModalBody>
          <DeleteEventModal
            toggle={toggleDeleteEventModal}
            currEventId={currEventId}
            getUpdatedEvents={getUpdatedEvents}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
      {/* event detail/update modal */}
      <Modal
        isOpen={eventDetailModal}
        toggle={toggleEventDetailModal}
        className="position-relative"
      >
        <AiFillCloseCircle
          onClick={toggleEventDetailModal}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />

        <ModalHeader toggle={toggleEventDetailModal}>
          {t("Event details")}
        </ModalHeader>
        <ModalBody>
          <EventDetailModal
            toggle={toggleEventDetailModal}
            venues={venues}
            getUpdatedEvents={getUpdatedEvents}
            currEventData={currEventData}
            approvedEventTypes={approvedEventTypes}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>

    //   </Tab>
    // </Tabs>
  );
}
