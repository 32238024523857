import React, { useState } from "react";
import { Row, Col, Table, Modal, Spinner, Pagination } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import "./Categories.scss";
import { Storage } from "aws-amplify";
import { baseUrl } from "../../Helpers/helper";
import { useAppSelector } from "../../Redux/hooks";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { getSellerData } from "../../../api/sellers/sellers";
import { getCategoriesListbyDomain } from "../../../api/categories/categories";
import { getParentCategory } from "../../../api/categories/categories";
import { updateCategory } from "../../../api/categories/categories";

const UpdateCategoryModal = ({ toggle, item }) => {
  const [path, setPath] = useState(null);
  const [imageUrl, setImageUrl] = useState(item.icon);
  const { t, i18n } = useTranslation();
  // all attributes data
  const [attrData, setAttrData] = useState([]);
  const onFileChange = async (e) => {
    const filePath = URL.createObjectURL(e.target.files[0]);
    setPath(filePath);

    try {
      const file = e.target.files[0];
      const imageKey = uuid() + file.name.replace(/\s/g, "-").toLowerCase();
      await Storage.put(imageKey, file).then((res) => {
        
        setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
      });
    } catch (error) {
      
    }
  };

  const [title, setTitle] = useState(item.title);
  // categories related
  const o = [];
  let c = 0;
  const [count, setCount] = useState([]);
  const [options, setOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [prodCategories, setProdCategories] = useState([]);
  const [currCatId, setCurrCatId] = useState(null);

  const [catNames, setCatNames] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [cat, setCat] = useState({ value: "", label: "Select..." });

  // cat api loading boolean
  const [updatingCategory, setUpdatingCategory] = useState(false);
  // cat api error message
  const [apiError, setApiError] = useState("");

  const [sellerDomain, setSellerDomain] = useState(null);
  const [parentCatName, setParentCatName] = useState("");

  const [loading, setLoading] = useState(false);
  const [showDeleteText, setShowDeleteText] = useState(false);

  React.useEffect(() => {
    getCategories("");
  }, []);

  const getCategories = (id) => {
    const sellerDomain = localStorage.getItem("domain");
    if (id === "") {
      id = "null";
    }
    let domain = "";
    if (sellerDomain === "Book") {
      domain = "Library";
    } else if (
      sellerDomain == "Fast Food" ||
      sellerDomain == "Bar & Restaurant"
    ) {
      domain = "Food and Restaurant";
    } else if (sellerDomain == "Cantinas" || sellerDomain == "Supermarket") {
      domain = "Supermarket and Cantinas";
    } else if (sellerDomain == "Shopping") {
      domain = "Shopping";
    } else {
      domain = sellerDomain;
    }
    getCategoriesListbyDomain(id, domain)
      .then((res) => {
        const { success, message, data } = res.data;
        
        if (success) {
          if (data.length === 0) {
            setDisableSelect(true);
            setIsDisabled(false);
          } else {
            data.map((item) => {
              o.push({ value: item._id, label: item.title });
            });
            setOptions(o);
            c++;
            setCount([...count, c]);
            
          }
        }
      })
      .catch((ex) => {
        
      });
  };

  React.useEffect(() => {
    getParentCategory(item.parentId)
      .then((res) => {
        setParentCatName(res.data.data[0].title);
      })
      .catch((err) => {
        
      });
  }, []);

  React.useEffect(() => {
    getSellerData()
      .then((res) => setSellerDomain(res.data.data[0].businessCategory))
      .catch((err) => {
        
      });
}, []);

  const updateCategoryMethod = () => {
    setApiError("");
    setUpdatingCategory(true);

    if (imageUrl === "" || title === "") {
      setApiError(`(*) ${t("fields are mandatory")}.`);
      setUpdatingCategory(false);
    } else {
      let body = {};

      if (imageUrl !== item.icon) {
        body.icon = imageUrl;
      }
      if (title !== item.title) {
        body.title = title;
      }

      // TODO: test behavior

      if (sellerDomain === "Book") {
        body.businessDomain = "Library";
      } else if (
        sellerDomain == "Fast Food" ||
        sellerDomain == "Bar & Restaurant"
      ) {
        body.businessDomain = "Food and Restaurant";
      } else if (sellerDomain == "Cantinas" || sellerDomain == "Supermarket") {
        body.businessDomain = "Supermarket and Cantinas";
      } else if (sellerDomain == "Shopping") {
        body.businessDomain = "Shopping";
      } else {
        body.businessDomain = sellerDomain;
      }

      if (Object.getOwnPropertyNames(body).length > 0) {
        
        updateCategory(item._id, body)
          .then((res) => {
            setUpdatingCategory(false);
            
            toggle(true);
          })
          .catch((err) => {
            setUpdatingCategory(false);
            setApiError(err.message);
            
          });
      }
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    
    setLoading(true);

    try {
      await Storage.remove(file).then((res) => {
        
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      
    }
  };

  return (
    <>
      <Row>
        <Col className="col-4">
          {!imageUrl ? (
            <>
              <input
                style={{ display: "none" }}
                id="imageUpload"
                type="file"
                multiple
                onChange={(e) => onFileChange(e)}
              />
              <label htmlFor="imageUpload" className="imgUploadLbl">
                <span>+</span>
                <p>
                  {t("Add Icon")}
                  <p
                    style={{
                      color: "red",
                      display: "inline",
                    }}
                  >
                    {" "}
                    *
                  </p>
                </p>
              </label>
            </>
          ) : (
            <>
              <img
                htmlFor="imgUpload"
                src={imageUrl}
                width={65}
                height={65}
                style={{ borderRadius: "8px" }}
                onClick={() => {
                  onDeleteImage(imageUrl);
                  setImageUrl("");
                  setShowDeleteText(false);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
              {/* TODO: show an edit btn beside the img to edit the current uploaded image */}
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  visibility: !showDeleteText ? "hidden" : "visible",
                }}
              >
                *{t("click to delete")}
              </p>
            </>
          )}
        </Col>
        <Col>
          <label
            htmlFor="lastName"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Title")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "4px 8px",
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Col>
      </Row>
      {/* <Row>
        <label
          style={{
            display: "block",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            marginTop: "5px",
          }}
        >
          Choose parent category (if any)
        </label>
      </Row> */}
      {/* <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {catNames.length > 0 &&
            catNames.map((item, index) => (
              <p
                style={{
                  display: "inline",
                  color: "gray",
                  fontSize: "12px",
                  marginBottom: "0",
                }}
              >
                {index !== 0 && "/ "}
                {item}
              </p>
            ))}
        </div>
        <div style={{ marginBottom: "1px" }}>
          <Select
            onChange={(e) => {
              setProdCategories([...prodCategories, e.value]);
              getCategories(e.value);
              setCurrCatId(e.value);
              setCatNames((catNames) => [...catNames, e.label]);
              setCat(e);
            }}
            value={cat}
            isDisabled={disableSelect}
            options={options}
            isSearchable
            placeholder="Select Category..."
          />
        </div>
        {catNames.length > 0 && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => {
                getCategories("");
                setCat({ value: "", label: "Select..." });
                setCatNames([]);
                setProdCategories([]);
                setDisableSelect(false);
                setIsDisabled(true);
              }}
            >
              <i style={{ fontSize: "13px", color: "gray" }}>reset</i>
            </button>
          </div>
        )}
      </Row> */}

      {apiError && (
        <p style={{ color: "red", fontSize: "12px" }}>{t(`${apiError}`)}</p>
      )}

      <ButtonToggle
        onClick={updateCategoryMethod}
        className="tglBtn"
        disabled={
          updatingCategory
          // || (!disableSelect && catNames.length > 0)
        }
      >
        <p style={{ color: "#fff", margin: "0px" }}>
          {updatingCategory ? (
            <Spinner animation="border" style={{ color: "#fff" }} size="sm" />
          ) : (
            t("Save")
          )}
        </p>
      </ButtonToggle>
    </>
  );
};

export default UpdateCategoryModal;
