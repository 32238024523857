import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Form,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ButtonToggle,
  ModalBody,
} from "reactstrap";
import { blue } from "../../Globals/colors";
import { useTranslation } from "react-i18next";
import {addDeliveryZone} from "../../../api/deliveryZones/deliveryZones"
import { deleteDeliveryZone } from "../../../api/deliveryZones/deliveryZones";
import { getDeliveryZones } from "../../../api/deliveryZones/deliveryZones";
import { updateDeliveryZone } from "../../../api/deliveryZones/deliveryZones";
import UpdateZoneModal from "./UpdateZoneModal";
import AddDeliveryModal from "./AddDeliveryModal";
import DeleteZoneModal from "./DeleteZoneModal";
import Pages from "../../Components/Pagination/Pagination";

export default function Definitions() {
  const [tableData, setTableData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const { t, i18n } = useTranslation();
  // details modal
  const [updateModal, setUpdateModal] = useState(false);
  const [updateZoneItem, setUpdateZoneItem] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [noOfPages, setNoOfPages] = useState([]);
  const [limit, setLimit] = useState(10);

  const toggleUpdateModal = (refresh = false) => {
    setUpdateModal(!updateModal);
    if (refresh) {
      setLoading(true);
      getDeliveryZones()
        .then((res) => {
          setLoading(false);
          setTableData(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          
        });
    }
  };
  const [loading, setLoading] = useState(false);

  // create delivery zone modal
  const [addModal, setAddModal] = useState(false);
  const toggleAddModal = () => setAddModal(!addModal);

  // curr item id
  const [currZoneId, setCurrZoneId] = useState("");

  // delete zone modal related
  const [deleteZoneModal, setDeleteZoneModal] = useState(false);
  const toggleDeleteZoneModal = () => setDeleteZoneModal(!deleteZoneModal);

  React.useEffect(() => {
    setLoading(true);
    getDeliveryZones(limit,activePage)
      .then((res) => {
        setTableData(res.data.data);
        let num = res.data.count / limit; 
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        
      });
  }, [limit,activePage]);

  const getUpdatedData = () => {
    setLoading(true);
    getDeliveryZones()
      .then((res) => {
        setLoading(false);
        setTableData(res.data.data);
        
      })
      .catch((err) => {
        setLoading(false);
        
      });
  };

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }
  return (
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={5}
            lg={5}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Delivery Zones")}
              </span>
            </div>
          </Col>
          <Col sm={2}>
            <Button
              onClick={toggleAddModal}
              style={{
                backgroundColor: blue,
                border: "none",
                paddingRight: "30px",
                paddingLeft: "30px",
              }}
            >
              {t("Add")}
            </Button>
          </Col>
        </Row>
      </div>

      {/* if no data found */}
      {/* {tableData.length <= 0 && (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>No data available</p>
        </div>
      )} */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Date")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Title")}
              </th>
              {/* <th style={{ padding: "20px", fontSize: "14px" }}>
                    Coordinates
                  </th> */}
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Price")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Delivery time (Est.)")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Status")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                onClick={() => {
                  setUpdateZoneItem(item);
                  toggleUpdateModal();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  {new Date(item.createdAt).toDateString()}
                </td>
                <td style={{ padding: "20px" }}>{item.title}</td>
                {/* <td style={{ padding: "20px" }}>
                      ({item.latitude}, {item.longitude})
                    </td> */}
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.price)} AKZ
                </td>
                <td style={{ padding: "20px" }}>
                  {" "}
                  {item.est > 0
                    ? `${item.est} ${item.est > 1 ? t("days") : t("day")}`
                    : t("Same day")}
                </td>

                <td style={{ padding: "20px" }}>
                  {item.status === "approved" ? t("Active") : t("Inactive")}
                </td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrZoneId(item._id);
                    toggleDeleteZoneModal();
                  }}
                  style={{
                    padding: "20px",
                    color: "red",
                    textDecoration: "underline",
                  }}
                >
                  {t("Delete")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}
      <div style={{ height: "50px" }}></div>
      {tableData.length > 0 && (
             <div style={{ height: "100px" }}>
             <Row>
               <Col
                 style={{
                   display: "flex",
                   flexDirection: "row",
                   justifyContent: "flex-end",
                   alignItems: "center",
                 }}
                 xl={12}
                 lg={12}
                 md={12}
                 sm={12}
                 xs={12}
               >
                 {/* pagination */}
                 <div>
                   <Pages
                     noOfPages={noOfPages}
                     activePage={activePage}
                     setActivePage={setActivePage}
                   />
                 </div>
   
                 {/* items per page toggler */}
                 <div
                   style={{
                     marginLeft: "10px",
                   }}
                 >
                   <UncontrolledDropdown style={{ width: "100%" }}>
                     <DropdownToggle
                       style={{
                         backgroundColor: "#fff",
                         color: "#333",
                         borderColor: "#CED4DA",
                         fontSize: "0.9rem",
                         width: "100%",
                         marginTop: "-20px",
                       }}
                       caret
                     >
                       <span
                         style={{
                           color: "#333",
                           fontSize: "0.7rem",
                         }}
                       >
                         {t("Items per page")}
                       </span>
                       <span
                         style={{
                           marginLeft: "5px",
                           fontWeight: "bold",
                           marginRight: "5px",
                         }}
                       >
                         {limit}
                       </span>
                     </DropdownToggle>
                     <DropdownMenu style={{ marginTop: "5px" }}>
                       <DropdownItem
                         onClick={() => {
                           setLimit(1);
                           setActivePage(1);
                         }}
                       >
                         1
                       </DropdownItem>
                       <DropdownItem
                         onClick={() => {
                           setLimit(5);
                           setActivePage(1);
                         }}
                       >
                         5
                       </DropdownItem>
                       <DropdownItem
                         onClick={() => {
                           setLimit(10);
                           setActivePage(1);
                         }}
                       >
                         10
                       </DropdownItem>
                     </DropdownMenu>
                   </UncontrolledDropdown>
                 </div>
               </Col>
             </Row>
           </div>
          )}

      {/* delete zone modal */}
      <Modal
        centered
        size="sm"
        isOpen={deleteZoneModal}
        toggle={toggleDeleteZoneModal}
      >
        <ModalHeader toggle={toggleDeleteZoneModal}>
          {t("Confirm Delete Zone")}
        </ModalHeader>
        <ModalBody>
          <DeleteZoneModal
            toggle={toggleDeleteZoneModal}
            currZoneId={currZoneId}
            getUpdatedData={getUpdatedData}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* details modal */}
      <Modal
        centered
        isOpen={updateModal}
        toggle={() => {
          toggleUpdateModal();
        }}
      >
        <ModalHeader>{t("Update Delivery Zone")}</ModalHeader>
        <ModalBody>
          <UpdateZoneModal toggle={toggleUpdateModal} item={updateZoneItem} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* add delivery modal */}
      <Modal
        isOpen={addModal}
        toggle={toggleAddModal}
        className="position-relative"
      >
        <AiFillCloseCircle
          onClick={toggleAddModal}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />

        <ModalHeader toggle={toggleAddModal}>
          {t("Add Delivery Zone")}
        </ModalHeader>
        <ModalBody>
          <AddDeliveryModal
            toggle={toggleAddModal}
            getUpdatedData={getUpdatedData}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
