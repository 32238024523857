import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_PATH = "/sellers/1";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASEURL}${BASE_PATH}`,
});
instance.interceptors.request.use(
	async (config) => {
		// Add authorization token to the request headers
		try {
			const session = await Auth.currentSession();
			const token = session.getIdToken().getJwtToken();
			config.headers.Authorization = `Bearer ${token}`;
		  
		  } catch (error) {
			console.error("An error occurred:", error.message);
		  }
		

		return config;
	},
	(error) => {
		// Handle request error here.
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
