import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Form,
  Spinner,
  ToastBody,
} from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Button from "react-bootstrap/Button";
import { baseUrl } from "../../Helpers/helper";
import Pages from "../../Components/Pagination/Pagination";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { getBankTransferHistory } from "../../../api/withdrawls/withdrawls";
import ModalLayout from "./ModalLayout";


export default function BankTransferHistory() {
  const [tableData, setTableData] = useState([]);
  const [currItem, setCurrItem] = useState(null);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(false);
  const { t, i18n } = useTranslation();

  // api filters
  const [limit, setLimit] = useState(10);

  // const today = `${
  //   new Date().getMonth() + 1
  // }/${new Date().getDate()}/${new Date().getFullYear()}`;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/wallet/1/sellers/withdraw/payment/exportCSV?limit=10&page=1&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
          "uid"
        )}`,
        ""
      );
    } catch (e) {
      
    }
  };

  // this effect only geting/updating total no of pages for pagination
  // React.useEffect(() => {
  //   axio
  //     .get(
  //       `${baseUrl}/sellers/withdraw/payment/history?limit=&page=&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
  //         "uid"
  //       )}`
  //     )
  //     .then((res) => {
  //       let num = res.data.total / 10;
  //       if (num < 1) setNoOfPages([1]);
  //       else {
  //         num = Math.ceil(num);
  //         for (let i = 0; i < num; i++) {
  //           setNoOfPages((oldArray) => [...oldArray, i + 1]);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       
  //     });
  // }, [activePage, startDate, endDate]);

  // this effect is fetching data
  React.useEffect(() => {
    setLoading(true);
    setNoOfPages([]);
    getBankTransferHistory(limit, activePage, startDate, endDate)
      .then((res) => {
        
        setNoOfPages([]);
        setTableData(res.data.data);
        let num = res.data.count / limit; 
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        
      });
  }, [limit, activePage, startDate, endDate]);
  return (
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={6}
            lg={6}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Bank Transfer History")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("show all")}
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  marginTop: "0px",
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  // setLimit(10);
                  // if (e.target.value === "") setStartDate(today);
                  // else setStartDate(e.target.value);
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  // setLimit(10);
                  // if (e.target.value === "") setEndDate(today);
                  // else setEndDate(e.target.value);
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* if no data found */}

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Order ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Payment Source")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Net Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("TAX")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setCurrItem(item);
                      toggle();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td style={{ padding: "20px" }}>
                      {new Date(item.createdAt).toDateString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item.bankName}</td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.amountToTransfer)}{" "}
                      AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.netAmount)}{" "}
                      AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.iva)}{" "}
                      AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                    {t(
                        item?.paymentStatus === "COMPLETED"
                          ? t("Completed")
                          : item?.paymentStatus === "PENDING"
                          ? t("Pending")
                          : item?.paymentStatus
                      )}
                    
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={20}>
                    <div className="noDataContainer">
                      <img src={noDataFound} width="200px" alt=" no data" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      )}
      {/* below data table content */}

      {tableData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {/* pagination */}
              <div>
                <Pages
                  noOfPages={noOfPages}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </div>

              {/* items per page toggler */}
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      marginTop: "-20px",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limit}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem
                      onClick={() => {
                        setLimit(1);
                        setActivePage(1);
                      }}
                    >
                      1
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(5);
                        setActivePage(1);
                      }}
                    >
                      5
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(10);
                        setActivePage(1);
                      }}
                    >
                      10
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout data={currItem} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
