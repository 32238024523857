import axios from "./axiosInterceptor";

export const addGenerateLink = (body) =>
  axios.post(`/sellers/generate-links`, body);

  export const deleteGenerateLink = (deleteLinkId) =>
  axios.delete(`/sellers/generate-links?id=${deleteLinkId}`);

  export const updateGeneratedLink = (item, body) =>
  axios.put(`/sellers/generate-links?id=${item._id}`, body);

  export const getUpdatedData = (limit, activePage, startDate, endDate) =>
  axios.get(
    `/sellers/generate-links?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );