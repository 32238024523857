import axios from "./axiosInterceptor";

export const getQrData = (limit, activePage) =>
  axios.get(
    `/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=QR&sellerId=${localStorage.getItem(
      "uid"
    )}`
  );

export const getDirectPaymentTransactions = (
  limit,
  activePage,
  selectedMethod,
  startDate,
  endDate
) =>
  axios.get(
    `/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=Direct Pay&sellerId=${localStorage.getItem(
      "uid"
    )}&paymentMethod=${selectedMethod}&paymentStatus=COMPLETED&startDate=${startDate}&endDate=${endDate}`
  );

export const getQrPaymentTransactions = (
  limit,
  activePage,
  selectedMethod,
  startDate,
  endDate
) =>
  axios.get(
    `/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=QR Pay&sellerId=${localStorage.getItem(
      "uid"
    )}&paymentMethod=${selectedMethod}&paymentStatus=COMPLETED&startDate=${startDate}&endDate=${endDate}`
  );
