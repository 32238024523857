import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { PinInput } from "react-input-pin-code";
import { useTranslation } from "react-i18next";
import { updateSellerData } from "../../../api/sellers/sellers";

const PinCodeModal = ({ toggle, modal, body }) => {
  const [pinCodeValues, setPinCodeValues] = useState(["", "", "", ""]);
  const [pinCode, setPinCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();

  const transfer = (e) => {
    e.preventDefault();
    setLoading(true);
    // setSuccessMsg("");
    setError("");

    body.pinCode = pinCode;
    
    updateSellerData(body)
      .then((res) => {
        
        // setTransferAmount(0);
        // notify(
        //   "Transfer request sent, Please wait for admin to approve the request."
        // );
        setLoading(false);
        if (res.data.success === false) setError(res.data.message);
        else {
          // ShowSuccessMessage();
          setPinCode("");
          setPinCodeValues(["", "", "", ""]);
          toggle(true);
          setError("");
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        
      });

    // if (loading) {
    //   return (
    //     <span className="spinnerContainer">
    //       <Spinner animation="border" variant="primary" />
    //     </span>
    //   );
    // }
  };

  const handlePinCodeOnChange = (values) => {
    const value = values.join("");

    setPinCode(value);
  };

  return (
    <div>
      <Modal centered size="sm" isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>{t("Enter PIN Code")}</ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col md="8">
              <PinInput
                mask
                length={4}
                values={pinCodeValues}
                validBorderColor={"rgb(204,204,204)"}
                onChange={(value, index, values) => {
                  setPinCodeValues(values);
                  handlePinCodeOnChange(values);
                }}
              />
              {error !== "" && (
                <p
                  style={{ color: "red", fontSize: "14px", textAlign: "left" }}
                >
                  {t(`*${error}`)}
                </p>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md="6">
              <Button
                onClick={(e) => {
                  transfer(e);
                }}
                disabled={loading}
                style={{
                  // margin: "20px 0px",
                  marginTop: "20px",
                  backgroundColor: "#049EFF",
                  borderWidth: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ color: "white", padding: "9px" }}
                    size="sm"
                  />
                ) : (
                  t("Confirm")
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
        {/* <div style={{ height: "5px", width: "100%" }} /> */}
      </Modal>
    </div>
  );
};

export default PinCodeModal;
