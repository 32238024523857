import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../Helpers/helper";
import { getLinkPaymentTransactions } from "../../../api/orders/orders";
import { Row, Col, Table, Form, Spinner } from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import TransactionDetailsModal from "./TransactionDetailsModal";
import LinkPaymentDetailsModal from "./LinkPaymentDetailsModal";
import { useTranslation } from "react-i18next";

const LinkPaymentTransactions = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [detailsModalItem, setDetailsModalItem] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  
  const { t, i18n } = useTranslation();

  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh === true) {
      LoadOrders();
    }
  };
  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/orders/1/transactions/history/export?orderStatus=${selectedStatus}&sellerId=${localStorage.getItem(
          "uid"
        )}&page=1000000&startDate=${startDate}&endDate=${endDate}&transactionType=PAYMENT_PER_LINK`,
        ""
      );
    } catch (e) {
      
    }
  };

  React.useEffect(() => {
    LoadOrders();
  }, [limit, activePage, selectedStatus, startDate, endDate]);

  const LoadOrders = async () => {
    const sid = localStorage.getItem("uid");
    setLoading(true);
    getLinkPaymentTransactions(limit, activePage, selectedStatus, startDate, endDate)
      .then((res) => {
        

        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.count / limit;
        if (num < 1) {
          setNoOfPages([1]);
        } else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((ex) => {
        
      });
  };

  return (
    <div
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   padding: "0px 25px",
    //   backgroundColor: "#f7f7f7",
    // }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={5}
            lg={5}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Transactions")}
              </span>
            </div>
          </Col>
          {/* <Col
            xl={3}
            lg={3}
            style={{
              visibility: "hidden",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Order Status")}
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  marginTop: "4px",
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {selectedStatus
                  ? selectedStatus?.charAt(0).toUpperCase() +
                    selectedStatus?.slice(1).toLowerCase()
                  : "Show All"}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setSelectedStatus("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("COMPLETED")}>
                  {t("Completed")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col> */}
          <Col xl={2} lg={2}>
          <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                  marginTop: "0px",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
            </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Province")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Address")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Net Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setDetailsModalItem(item);
                    toggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {" "}
                    {new Date(item.createdAt).toLocaleDateString()}
                    {" - "}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td style={{ padding: "20px" }}>{item.transactionId}</td>
                  <td style={{ padding: "20px" }}>{`${item.customerName}`}</td>
                  <td style={{ padding: "20px" }}>
                    {item.customerProvince ? item.customerProvince : "NaN"}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.shippingAddress ? item.shippingAddress : "NaN"}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.totalPrice)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.commission)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.netAmount)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                       {t(
                        item?.status === "COMPLETED"
                          ? t("Completed")
                          : item?.status === "PENDING"
                          ? t("Pending")
                          : item?.status
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          margin: "auto",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setLimit(1)}>
                          1
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(5)}>
                          5
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      ) : (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <LinkPaymentDetailsModal item={detailsModalItem} toggle={toggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default LinkPaymentTransactions;
