import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Table, Modal, Spinner, Pagination } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";

import "../Categories/Categories.scss";
import { Storage } from "aws-amplify";
import { baseUrl } from "../../Helpers/helper";
import { useTranslation } from "react-i18next";
import { getSellerData } from "../../../api/sellers/sellers";
import { addCategory } from "../../../api/categories/categories";
import { getApprovedEventCategories } from "../../../api/categories/categories";
import { getCategoryByName } from "../../../api/categories/categories";
import AddEventTypeModal from "./AddEventTypeModal";

// const allowedSellerTypes = ["Fast Food", "Bar & Restaurant", "Cantinas"];

const EventType = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);
  const [businessCategory, setBusinessCategory] = useState("");
  const { t, i18n } = useTranslation();
  // search field
  const [search, setSearch] = useState("");

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      alert(row.title);
    },
  };

  React.useEffect(() => {
    getSellerData()
      .then((res) => {
        setBusinessCategory(res.data.data[0].businessCategory);
        // 
      })
      .catch((err) => {
        
      });
}, []);
  React.useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    setLoading(true);
    getApprovedEventCategories();
    axios
      // categories?approved=approved&domain=Event&page=
      .get(`${baseUrl}/categories?approved=approved&domain=Event&page=`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setTableData(response.data.data);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        
      });
  };

  // search cat By Value
  const searchByValue = () => {
    if (search !== "") {
      getCategoryByName(search)
        .then((res) => {
          const { data } = res.data;
          
          res.data.data.map((item) => {});
          setTableData(res.data.data);
          setSearch("");
        })
        .catch((err) => {
          
          setSearch("");
        });
    }
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }
  // 
  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Event type")}
              </span>
            </div>
          </Col>

          <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={toggle}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Add")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}

      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("ID")}</th>
            {/* <th style={{ padding: "20px", fontSize: "14px" }}>Domain</th> */}
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Category Name")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Status")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr
              // onClick={() => {
              //   alert("row");
              // }}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ padding: "20px" }}>
                {item.publicID && item.publicID}
              </td>
              {/* <td style={{ padding: "20px" }}>
                {item.businessDomain && item.businessDomain}
              </td> */}
              <td style={{ padding: "20px" }}>{item.title && item.title}</td>
              <td style={{ padding: "20px" }}>
                {item.isApproved && item.isApproved}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div style={{ height: "100px" }}>
        <Row style={{ display: "block", paddingBottom: "0px" }}>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Pagination */}
                {/* <Pagination>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <Pagination.Item
                      onClick={() => setActivePage(item)}
                      active={activePage === item}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                </Pagination> */}
              </div>
              {/* No of products on page toggler */}
              {/* <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      Items per page
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      1
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
            </Col>
          </div>
        </Row>
      </div>

      <Modal show={modal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Add Event type")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <AddEventTypeModal toggle={toggle} getCategories={getCategories} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EventType;
