import React, { useState } from "react";
import {
  Spinner,
} from "react-bootstrap";
import {
  ButtonToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  deleteEvent,
} from "../../../api/events/events";

export default function DeleteEventModal({ toggle, currEventId, getUpdatedEvents }) {
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const deleteEventMethod = () => {
      setLoading(true);
      deleteEvent(currEventId)
        .then((res) => {
          setLoading(false);
          toggle();
          getUpdatedEvents();
          
        })
        .catch((err) => {
          setLoading(false);
          
        });
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={deleteEventMethod}
          disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Yes")
          )}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={toggle}
          disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
          }}
        >
          {t("No")}
        </ButtonToggle>
      </div>
    );
}


