// Provinces
const provinces = [
  { value: "Bengo", label: "Bengo" },
  { value: "Benguela", label: "Benguela" },
  { value: "Bié", label: "Bié" },
  { value: "Cabinda", label: "Cabinda" },
  { value: "Cunene", label: "Cunene" },
  { value: "Huambo", label: "Huambo" },
  { value: "Huíla", label: "Huíla" },
  { value: "Kuando Kubango", label: "Kuando Kubango" },
  { value: "Kwanza Norte", label: "Kwanza Norte" },
  { value: "Kwanza Sul", label: "Kwanza Sul" },
  { value: "Luanda", label: "Luanda" },
  { value: "Lunda Norte", label: "Lunda Norte" },
  { value: "Lunda Sul", label: "Lunda Sul" },
  { value: "Malange", label: "Malange" },
  { value: "Moxico", label: "Moxico" },
  { value: "Namibe", label: "Namibe" },
  { value: "Uíge", label: "Uíge" },
  { value: "Zaire", label: "Zaire" },
];

const citiesOfProvinces = [
  {
    province: "Bengo",
    cities: [
      { value: "Ambriz", label: "Ambriz" },
      { value: "Dande", label: "Dande" },
      { value: "Dembos", label: "Dembos" },
      { value: "Bula Atumba", label: "Bula Atumba" },
      { value: "Nambuangongo", label: "Nambuangongo" },
      {
        value: "Pango Aluquém (Pango Aluquêm)",
        label: "Pango Aluquém (Pango Aluquêm)",
      },
    ],
  },
  {
    province: "Benguela",
    cities: [
      { value: "Balombo", label: "Balombo" },
      { value: "Baía Farta", label: "Baía Farta" },
      { value: "Benguela", label: "Benguela" },
      { value: "Bocoio", label: "Bocoio" },
      { value: "Caimbambo", label: "Caimbambo" },
      { value: "Catumbela", label: "Catumbela" },
      { value: "Chongoroi", label: "Chongoroi" },
      { value: "Cubal", label: "Cubal" },
      { value: "Ganda", label: "Ganda" },
      { value: "Lobito", label: "Lobito" },
    ],
  },
  {
    province: "Bié",
    cities: [
      { value: "Andulo", label: "Andulo" },
      { value: "Camacupa", label: "Camacupa" },
      { value: "Catabola", label: "Catabola" },
      { value: "Chinguar", label: "Chinguar" },
      { value: "Chitembo", label: "Chitembo" },
      { value: "Cuemba", label: "Cuemba" },
      { value: "Cunhinga", label: "Cunhinga" },
      { value: "Cuito (Kuito)", label: "Cuito (Kuito)" },
      { value: "Nharêa", label: "Nharêa" },
    ],
  },
  {
    province: "Cabinda",
    cities: [
      { value: "Belize", label: "Belize" },
      { value: "Buco-Zau", label: "Buco-Zau" },
      { value: "Cabinda", label: "Cabinda" },
      { value: "Cacongo", label: "Cacongo" },
    ],
  },
  {
    province: "Cunene",
    cities: [
      { value: "Cahama", label: "Cahama" },
      { value: "Cuanhama (Kwanhama)", label: "Cuanhama (Kwanhama)" },
      { value: "Curoca", label: "Curoca" },
      { value: "Cuvelai", label: "Cuvelai" },
      { value: "Namacunde", label: "Namacunde" },
      { value: "Ombadja", label: "Ombadja" },
    ],
  },
  {
    province: "Huambo",
    cities: [
      { value: "Bailundo", label: "Bailundo" },
      { value: "Cachiungo (Catchiungo)", label: "Cachiungo (Catchiungo)" },
      { value: "Caála", label: "Caála" },
      { value: "Ecunha (Ekunha)", label: "Ecunha (Ekunha)" },
      { value: "Huambo", label: "Huambo" },
      { value: "Londuimbali", label: "Londuimbali" },
      { value: "Longonjo (Longongo)", label: "Longonjo (Longongo)" },
      { value: "Mungo", label: "Mungo" },
      {
        value: "Chicala-Cholohanga (Tchicala-Tcholoanga)",
        label: "Chicala-Cholohanga (Tchicala-Tcholoanga)",
      },
      { value: "Chinjenje (Tchindjenje)", label: "Chinjenje (Tchindjenje)" },
      { value: "Ucuma", label: "Ucuma" },
    ],
  },
  {
    province: "Huíla",
    cities: [
      { value: "Caconda", label: "Caconda" },
      { value: "Cacula", label: "Cacula" },
      { value: "Caluquembe", label: "Caluquembe" },
      { value: "Gambos", label: "Gambos" },
      { value: "Chibia", label: "Chibia" },
      { value: "Chicomba", label: "Chicomba" },
      { value: "Chipindo", label: "Chipindo" },
      { value: "Humpata", label: "Humpata" },
      { value: "Jamba", label: "Jamba" },
      { value: "Cuvango (Kuvango)", label: "Cuvango (Kuvango)" },
      { value: "Lubango", label: "Lubango" },
      { value: "Matala", label: "Matala" },
      { value: "Quilengues", label: "Quilengues" },
      { value: "Quipungo", label: "Quipungo" },
    ],
  },
  {
    province: "Kuando Kubango",
    cities: [
      { value: "Calai", label: "Calai" },
      { value: "Cuangar", label: "Cuangar" },
      { value: "Cuchi", label: "Cuchi" },
      {
        value: "Cuito Cuanavate (Kuito Kuanavale)",
        label: "Cuito Cuanavate (Kuito Kuanavale)",
      },
      { value: "Drirco", label: "Drirco" },
      { value: "Nancova (Nankova)", label: "Nancova (Nankova)" },
      { value: "Mavinga", label: "Mavinga" },
      { value: "Menongue", label: "Menongue" },
      { value: "Rivungo", label: "Rivungo" },
    ],
  },
  {
    province: "Kwanza Norte",
    cities: [
      { value: "Ambaca", label: "Bolongongo" },
      { value: "Banga", label: "Bolongongo" },
      { value: "Bolongongo", label: "Bolongongo" },
      { value: "Cambambe", label: "Cambambe" },
      { value: "Cazengo", label: "Cazengo" },
      { value: "Golungo Alto", label: "Golungo Alto" },
      { value: "Ngonguembo (Gonguembo)", label: "Ngonguembo (Gonguembo)" },
      { value: "Lucala", label: "Lucala" },
      { value: "Quiculungo", label: "Quiculungo" },
      { value: "Samba Caju", label: "Samba Caju" },
    ],
  },
  {
    province: "Kwanza Sul",
    cities: [
      { value: "Amboim", label: "Amboim" },
      { value: "Cassongue", label: "Cassongue" },
      { value: "Conda", label: "Conda" },
      { value: "Ebo", label: "Ebo" },
      { value: "Libolo", label: "Libolo" },
      { value: "Mussende", label: "Mussende" },
      { value: "Porto Amboim", label: "Porto Amboim" },
      { value: "Quibala", label: "Quibala" },
      { value: "Quilenda", label: "Quilenda" },
      { value: "Seles", label: "Seles" },
      { value: "Sumbe", label: "Sumbe" },
      { value: "Cela", label: "Cela" },
    ],
  },
  {
    province: "Luanda",
    cities: [
      { value: "Belas", label: "Belas" },
      { value: "Cacuaco", label: "Cacuaco" },
      { value: "Cazenga", label: "Cazenga" },
      { value: "Icolo e Bengo", label: "Icolo e Bengo" },
      { value: "Luanda", label: "Luanda" },
      { value: "Quiçama (Quissama)", label: "Quiçama (Quissama)" },
      { value: "Kilamba Kiax", label: "Kilamba Kiax" },
      { value: "Talatona", label: "Talatona" },
      { value: "Viana", label: "Viana" },
    ],
  },
  {
    province: "Lunda Norte",
    cities: [
      { value: "Cambulo", label: "Cambulo" },
      { value: "Capenda Camulemba", label: "Capenda Camulemba" },
      { value: "Caungula", label: "Caungula" },
      { value: "Chitato (Tchitato)", label: "Chitato (Tchitato)" },
      { value: "Cuango", label: "Cuango" },
      { value: "Cuilo", label: "Cuilo" },
      { value: "Lubalo", label: "Lubalo" },
      { value: "Lucapa", label: "Lucapa" },
      { value: "Xá Muteba", label: "Xá Muteba" },
      { value: "Lóvua", label: "Lóvua" },
    ],
  },
  {
    province: "Lunda Sul",
    cities: [
      { value: "Cacolo", label: "Cacolo" },
      { value: "Dala", label: "Dala" },
      { value: "Muconda", label: "Muconda" },
      { value: "Saurimo", label: "Saurimo" },
    ],
  },
  {
    province: "Malange",
    cities: [
      { value: "Cacuso", label: "Cacuso" },
      { value: "Clandula", label: "Clandula" },
      { value: "Cambundi Catembo", label: "Cambundi Catembo" },
      { value: "Cangandala", label: "Cangandala" },
      { value: "Cahombo (Caombo)", label: "Cahombo (Caombo)" },
      {
        value: "Kiwaba Nzoji (Cuaba Nzogo)",
        label: "Kiwaba Nzoji (Cuaba Nzogo)",
      },
      {
        value: "Kunda dya Baze (Cunda-Diaza)",
        label: "Kunda dya Baze (Cunda-Diaza)",
      },
      { value: "Luquembo", label: "Luquembo" },
      { value: "Malange", label: "Malange" },
      { value: "Marimba", label: "Marimba" },
      { value: "Massango", label: "Massango" },
      { value: "Mucari", label: "Mucari" },
      { value: "Quela", label: "Quela" },
      { value: "Quirima", label: "Quirima" },
    ],
  },
  {
    province: "Moxico",
    cities: [
      { value: "Alto Zambeze", label: "Alto Zambeze" },
      { value: "Bundas", label: "Bundas" },
      { value: "Camanongue", label: "Camanongue" },
      { value: "Cameia (Lumeji)", label: "Cameia (Lumeji)" },
      { value: "Luau", label: "Luau" },
      { value: "Luacano (Lucano)", label: "Luacano (Lucano)" },
      { value: "Luchazes", label: "Luchazes" },
      { value: "Léua", label: "Léua" },
      { value: "Moxico", label: "Moxico" },
    ],
  },
  {
    province: "Namibe",
    cities: [
      { value: "Bibala", label: "Bibala" },
      { value: "Camacuio (Camulo)", label: "Camacuio (Camulo)" },
      { value: "Moçâmedes (Namibe)", label: "Moçâmedes (Namibe)" },
      { value: "Tômbwa (Tômbua)", label: "Tômbwa (Tômbua)" },
      { value: "Virei", label: "Virei" },
    ],
  },
  {
    province: "Uíge",
    cities: [
      { value: "Alto Cauale", label: "Alto Cauale" },
      { value: "Ambuíla", label: "Ambuíla" },
      { value: "Bembe", label: "Bembe" },
      { value: "Buengas", label: "Buengas" },
      { value: "Bungo", label: "Bungo" },
      { value: "Damba", label: "Damba" },
      { value: "Milunga", label: "Milunga" },
      { value: "Mucaba", label: "Mucaba" },
      { value: "Negage", label: "Negage" },
      { value: "Puri", label: "Puri" },
      { value: "Quimbele", label: "Quimbele" },
      { value: "Dange-Quitexe", label: "Dange-Quitexe" },
      { value: "Pombo", label: "Pombo" },
      { value: "Songo", label: "Songo" },
      { value: "Uíge", label: "Uíge" },
      { value: "Maquela do Zombo", label: "Maquela do Zombo" },
    ],
  },
  {
    province: "Zaire",
    cities: [
      { value: "Cuimba", label: "Cuimba" },
      { value: "M'Banza Kongo", label: "M'Banza Kongo" },
      { value: "Noqui", label: "Noqui" },
      { value: "N'Zeto", label: "N'Zeto" },
      { value: "Soyo", label: "Soyo" },
      { value: "Tomboco", label: "Tomboco" },
    ],
  },
];

export { provinces, citiesOfProvinces };
