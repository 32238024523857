import React, { useState } from "react";
import "./Transactions.scss";
import {Tabs, Tab } from "react-bootstrap";
import { useAppSelector } from "../../Redux/hooks";
import EcomPaymentTransactions from "./EcomPaymentTransactions";
import LinkPaymentTransactions from "./LinkPaymentTransactions";
import QrPaymentTransactions from "./QrPaymentTransactions";
import QrCatalogTransactions from "./QrCatalogTransactions";
import DirectPaymentTransactions from "./DirectPaymentTransactions";
import { useTranslation } from "react-i18next";

export default function Transactions() {
  const sellerType = useAppSelector((state) => state.auth.sellerType);
  const accountType = localStorage.getItem("accountType");
  const [activeTab, setActiveTab] = useState("QRPayment");
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-3"
      >
        <Tab eventKey="QRPayment" title={t("QR Payment")}>
          <QrPaymentTransactions />
        </Tab>
        {(sellerType === "Fast Food" || sellerType === "Bar & Restaurant") && (
          <Tab eventKey="QRCatalog" title={t("QR Catalog")}>
            <QrCatalogTransactions />
          </Tab>
        )}
        {accountType !== "individual" && (
          <Tab eventKey="DirectPayment" title={t("Direct Payment")}>
            <DirectPaymentTransactions />
          </Tab>
        )}
        {(localStorage.getItem("domain") !== "Pay") && (
        <Tab eventKey="Marketplace" title={t("Marketplace")}>
          <EcomPaymentTransactions />
        </Tab>
        )}
        <Tab eventKey="LinkPayment" title={t("Link Payment")}>
          <LinkPaymentTransactions />
        </Tab>
      </Tabs>
    </div>
  );
}
