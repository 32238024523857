import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../Helpers/helper";
import { getQrCatalogTransactions } from "../../../api/orders/orders";
import { Row, Col, Table, Form, Spinner } from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";

import QrCatalogDetails from "./QrCatalogDetails";
import { useTranslation } from "react-i18next";

const QrCatalogTransactions = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  // const [value, setValue] = useState(null);
  // const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t, i18n } = useTranslation();
  const [detailsModalItem, setDetailsModalItem] = useState({});

  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh === true) {
      LoadOrders();
    }
  };

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/orders/1/order/export?limit=1000000&page=1&sellerId=${localStorage.getItem(
          "uid"
        )}&transactionType=QR_CATALOGE_SCAN&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
      
    }
  };

  React.useEffect(() => {
    LoadOrders();
  }, [limit, activePage, startDate, endDate]);

  const LoadOrders = async () => {
    setLoading(true);
    getQrCatalogTransactions(limit, activePage, startDate, endDate)
      .then((res) => {
        const { success, message, data, count } = res.data;
        // 
        // if (success) {
        //   
        //   setTableData(data);
        // }
        
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((ex) => {
        
      });
  };

  return (
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={6}
            lg={6}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Transactions")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("show all")}
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                  marginTop: "0px",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Net Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Table No")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setDetailsModalItem(item);
                    toggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {" "}
                    {new Date(item.createdAt).toLocaleDateString()}
                    {" - "}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td style={{ padding: "20px" }}>{item.transactionId}</td>
                  <td
                    style={{ padding: "20px" }}
                  >{`${item.customerDetail?.firstName} ${item.customerDetail?.surName}`}</td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.amount)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.commission)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.netAmount)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>{item.tableNo}</td>
                  <td style={{ padding: "20px" }}>
                        {t(
                        item?.status === "COMPLETED"
                          ? t("Completed")
                          : item?.status === "PENDING"
                          ? t("Pending")
                          : item?.status
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          margin: "auto",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setLimit(1)}>
                          1
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(5)}>
                          5
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      ) : (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" alt="" />
          <p>{t("No data available")}</p>
        </div>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <QrCatalogDetails item={detailsModalItem} toggle={toggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default QrCatalogTransactions;
