import React, { useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { getSellerData } from "../../../api/sellers/sellers";
import Security from "./Security";
import { useTranslation } from "react-i18next";
import EditInformation from "./EditInformation";

const AccountSetting = () => {
  const [activeTab, setActiveTab] = useState("info");
  const [sellerInfo, setSellerInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setLoading(true);
    getSellerData()
      .then((response) => {
        
        setSellerInfo(response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        
        setLoading(false);
      });
  }, []);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
      className="mb-3"
    >
      <Tab eventKey="info" title={t("Information")}>
        {loading ? (
          <span className="spinnerContainer">
            <Spinner animation="border" variant="primary" />
          </span>
        ) : (
          <EditInformation user={sellerInfo} />
        )}
      </Tab>
      <Tab eventKey="security" title={t("Security")}>
        <Security />
      </Tab>
    </Tabs>
  );
};

export default AccountSetting;
