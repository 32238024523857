import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import hero_image from "../../Assets/images/hero-section-img.jpg"
import image1 from "../../Assets/images/img-1.jpg"
import image2 from "../../Assets/images/img-2.jpg"
import image3 from "../../Assets/images/img-3.jpg"
import google from "../../Assets/images/google.webp";
import appstore from "../../Assets/images/appstore.webp"
import Footer from "./Footer";
import Header from "./Header";
const handleLinkClick = (event) => {
  event.preventDefault();
  window.open("'../../Assets/images/img-1.jpg'", '_blank');
};
const LandingPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header />
      <div className={"landing-page-container"}>
        <div
          style={{ backgroundImage: `url(${hero_image})` }}
          className={"cover-img"}
        >
          <div className={"cover-img-content"}>
            <h3>{t("BayQi Empresa, comece a vender e a receber")}</h3>
            <h3>{t("pagamentos de forma rápida e segura.")}</h3>
            <p>{t("Controle o seu negócio e receba pagamentos em tempo real.")}</p>
            <div className={"cover-img-apps d-flex flex-row flex-wrap"}>
              <Link to={"/"}>
                <div
                  style={{
                    backgroundImage: `url(${appstore})`,
                    height: "6em",
                    width: "12em",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    margin: "0 1.5em 0.2em 0",
                    cursor: "pointer",
                  }}
                ></div>
              </Link>
              <Link to={"/"}>
                <div
                  style={{
                    backgroundImage: `url(${google})`,
                    height: "6em",
                    width: "12em",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                  }}
                ></div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={"landing-page-cards-wrap"}>
        <div className="container spacing">
          <h1 className="">Simplifique a forma como o seu negócio recebe pagamento, através do Gateway de Pagamento BayQi</h1>
          <div className="row height-fix align-items-start">
            {/* <div className="col col-lg-4 col-md-12 col-sm-12">
              <div className="card card-1">
                <img src={image1} alt="" />
                <h3>{t("Large Enterprise")}</h3>
                <p>
                  {t(
                    "Your customers can pay however they like,online or in person"
                  )}
                </p>
              </div>
            </div> */}
            <div class="card" style={{width: "23rem",height:"100%"}}>
              <img class="card-img-top" src={image1} alt="Card cap"></img>
              <div class="card-body">
              <h3>{t("Grandes Empresas ")}</h3>
                <p class="card-text">{t(
                    "Seus clientes podem pagar como quiserem, on-line ou pessoalmente."
                  )}</p>
              </div>
            </div>
            {/* <div className="col col-lg-4 col-md-12 col-sm-12">
              <div className="card card-2">
              <img src={image2} alt="" />
                <h3>{t("Small and Medium-sized businesses (SMBs)")}</h3>
                <p>
                  {t(
                    "BayQi offers a simple platform with the tools and partnerships you need."
                  )}
                </p>
              </div>
            </div> */}
            <div class="card" style={{width: "23rem",height:"100%"}}>
              <img class="card-img-top" src={image2} alt="Card cap"></img>
              <div class="card-body">
              <h3>{t("Pequenas e Medias Empresas")}</h3>
                <p class="card-text">{t(
                    " BayQi tem as ferramentas e parcerias que está procura em uma plataforma simples."
                  )}</p>
              </div>
            </div>
            {/* <div className="col col-lg-4 col-md-12 col-sm-12">
              <div className="card card-3">
              <img src={image3} alt="" />
                <h3>{t("Micro Entrepreneur Individual (MEI)")}</h3>
                <p>
                  {t("Create a Payment Link and sell safely with BayQi.")}
                </p>
              </div>
            </div> */}
            <div class="card" style={{width: "23rem",height:"100%"}}>
              <img class="card-img-top" src={image3} alt="Card cap"></img>
              <div class="card-body">
                <h3>{t("Microempreendedor Individual (MEI)")}</h3>
                <p class="card-text"> {t("Crie Link de Pagamento e venda em segurança.")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default LandingPage;
