import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  Input
} from "reactstrap";
import {  RegionDropdown } from "react-country-region-selector";
import {  AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";
import {
  addEvent,
} from "../../../api/events/events";
import { getDeliveryMethods } from "../../../api/deliveryZones/deliveryZones";

export default function AddModal({ toggle, venues, getUpdatedEvents, approvedEventTypes }) {
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");
    const [options, setOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const [eventPlaceId, setEventPlaceId] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
  
    const [eventPlace, setEventPlace] = useState("");
  
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [entryFees, setEntryFees] = useState("");
    const [category, setCategory] = useState("");
    const [province, setProvince] = useState("");
    const [address, setAddress] = useState("");
  
    const [path, setPath] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [imgUploaded, setImgUploaded] = useState(true);
  
    const [catOptions, setCatOptions] = useState([]);
  
    // ticket type related states
    // ticket types
    const [types, setTypes] = useState([]);
    const [type, setType] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  
    const [isDisabledDelivery, setIsDisabledDelivery] = useState(true);
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [deliveryOptions, setDeliveryOptions] = useState(null);
    const [showDeleteText, setShowDeleteText] = useState(false);
  
    // called on img upload
    const onFileChange = async (e) => {
      setImgUploaded(false);
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
      try {
        await Storage.put(e.target.files[0].name, e.target.files[0]).then(
          (res) => {
            
            setImageUrl(`${process.env.REACT_APP_S3_URL}/public/${res.key}`);
            setImgUploaded(true);
          }
        );
      } catch (error) {
        setImgUploaded(true);
      }
    };
  
    const deleteImage = async () => {
      await Storage.remove(imageUrl.split("public/")[1]);
      setPath(null);
      setImageUrl("");
    };
    const provinces=localStorage.getItem("province");
    // setProvince(provinces);
    // 
  
    const addEventMethod = () => {
      setLoading(true);
      setError("");
      if (
        imageUrl === "" ||
        eventPlace === "" ||
        eventName === "" ||
        eventDescription === "" ||
        startDate === "" ||
        endDate === "" ||
        startTime === "" ||
        endTime === "" ||
        // province === "" ||
        address === "" ||
        // entryFees == "" ||
        types.length <= 0 ||
        category === ""
      ) {
        setError(`(*) ${t("fields are mandatory")}.`);
        setLoading(false);
      } else {
        const body = {
          image: imageUrl,
          sellerId: localStorage.getItem("uid"),
          eventPlace,
          eventName,
          eventDescription,
          startDate,
          endDate,
          timing: `${startTime} - ${endTime}`,
          // entryFees,
          ticketTypes: types,
          category,
          province:provinces,
          address,
          // deliveryMethod,
        };
        
        
        
        addEvent(body)
          .then((res) => {
            
            setLoading(false);
            toggle();
            getUpdatedEvents();
          })
          .catch((err) => {
            
            setError(err.message);
            setLoading(false);
          });
      }
    };
  
    const getMethods = () => {
      getDeliveryMethods()
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setIsDisabledDelivery(true);
            } else {
              const tempArray = [];
              const deliveryArray = [];
              data.map((item) => {
                tempArray.push({ value: item._id, label: item.title });
                deliveryArray.push(item._id);
              });
              setDeliveryMethod(deliveryArray);
              setDeliveryOptions(tempArray);
              setIsDisabledDelivery(false);
            }
          }
        })
        .catch((ex) => {
          
        });
    };
  
    React.useEffect(() => {
      getMethods();
  
      if (approvedEventTypes.length > 0) {
        approvedEventTypes.map((item) => {
          setCatOptions((oldArr) => [
            ...oldArr,
            { label: item.title, value: item.title },
          ]);
        });
      }
  
      // venues.map((item) =>
      //     setOptions((oldArr) => [
      //         ...oldArr,
      //         { label: item.placeName, value: item._id },
      //     ])
      // );
    }, []);
   
  
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row>
          <Col>
            <label className="inpLblTxt">
              {t("Image")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
          </Col>
        </Row>
        <Row className="align-items-start">
          <Col>
            {!path ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            ) : (
              <>
                <img
                  alt=""
                  htmlFor="imgUpload"
                  src={path}
                  onClick={deleteImage}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  onMouseEnter={() => setShowDeleteText(true)}
                  onMouseLeave={() => setShowDeleteText(false)}
                />
                <p
                  style={{
                    visibility: !showDeleteText ? "hidden" : "visible",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  *{t("click to delete")}
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              {t("Event Type")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <Select
              options={catOptions}
              onChange={({ value }) => setCategory(value)}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              {t("Event Venue")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            {/* <Select
                          options={options}
                          onChange={({ value }) => setEventPlaceId(value)}
                      /> */}
            <input
              value={eventPlace}
              onChange={(e) => setEventPlace(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Event Venue"
              className="inputField"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="inpLblTxt">
              {t("Province")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              value={provinces}
             disabled
              style={{ width: "100%" }}
              placeholder={provinces}
              className="inputField"
            />
            {/* <RegionDropdown
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              country={"Angola"}
              value={province}
              onChange={(val) => {
                setProvince(val);
              }}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="inpLblTxt">
              {t("Address")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="textarea"
              style={{ width: "100%" }}
              placeholder="Address"
              // className="inputField"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              {t("Name")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Event name"
              className="inputField"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              {t("Description")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <Input
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              type="textarea"
              style={{ width: "100%" }}
              placeholder="Description"
              // className="inputField"
            />
          </Col>
        </Row>
        <Row className="d-flex">
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("Start date")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="inputField"
              type="date"
              style={{ width: "100%" }}
            />
          </Col>
  
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("End date")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="inputField"
              type="date"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="d-flex">
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("Start time")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="inputField"
              type="time"
              style={{ width: "100%" }}
            />
          </Col>
  
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("End time")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="inputField"
              type="time"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
  
        {types.length > 0 && (
          <label className="inpLblTxt">{t("Tickets List")}</label>
        )}
        {types &&
          types.map((item) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "lightgray",
                margin: "0px",
                marginBottom: "4px",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "4px",
                  fontSize: "14px",
                  color: "gray",
                  display: "inline",
                }}
              >
                {item.ticketType}{" "}
                {new Intl.NumberFormat().format(item.ticketPrice)} AKZ{" "}
                {item.quantity && ` - ${item.quantity} Seats`}
              </p>
  
              <AiFillDelete
                onClick={() => {
                  let filteredTypes = types.filter((curr) => curr !== item);
                  setTypes(filteredTypes);
                }}
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                size={"1.2rem"}
              />
            </div>
          ))}
        <Row className="d-flex">
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("Ticket type")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="inputField"
              type="text"
              style={{ width: "100%" }}
            />
          </Col>
  
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("Ticket price")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="inputField"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
  
        <Row>
          <Col className="sm-col-12 md-col-6">
            <label className="inpLblTxt">
              {t("Total Seats")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="inputField"
              type="number"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
  
        <Row>
          <Col
            md={12}
            style={{
              textAlign: "end",
              marginTop: "6px",
              marginRight: "20px",
            }}
          >
            <button
              style={{
                paddingRight: "6px",
                paddingLeft: "6px",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTypes((oldArr) => [
                  ...oldArr,
                  { ticketType: type, ticketPrice: price, quantity:quantity },
                ]);
                setType("");
                setPrice("");
                setQuantity("");
                
              }}
              disabled={price === "" || type === ""|| quantity === ""}
            >
              {t("Add")}
            </button>
          </Col>
        </Row>
  
        {/* <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label className="inpLblTxt">
              Entry Fee<p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <br />
            <input
              value={entryFees}
              onChange={(e) => setEntryFees(e.target.value)}
              type="number"
              style={{ width: "100%" }}
              placeholder="1,000 AKZ"
              className="inputField"
            />
          </Col>
        </Row> */}
  
        {error && (
          <p
            style={{
              color: "red",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            {t(`${error}`)}
          </p>
        )}
  
        <Button
          onClick={addEventMethod}
          disabled={loading || !imgUploaded}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading || !imgUploaded ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
}

