import React , { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsWhatsapp } from 'react-icons/bs';
import {TfiYoutube} from 'react-icons/tfi';
import {AiOutlineInstagram} from 'react-icons/ai';
import {FiFacebook} from 'react-icons/fi';
import privacy from "../../Assets/documents/privacy.pdf"
import terms from "../../Assets/documents/terms.pdf"
import Register from "../Auth/Register";

export default function Footer() {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const createAccount = () => {
      setOpen(true);
    };
    return (
      <div>
        <div>
          <footer className="site-footer">
            <div className="footer-container">
              <div className="row footer-div">
                <div className="col-sm-12 col-md-6">
                  <h6>{t("About")}</h6>
                  <p className="text-left">
                    {t(
                      "BayQi esta autorizada pelo Banco Nacional como uma Instituição de Pagamentos através da sociedade PagaQi - Sociedade Prestadora de Serviços de Pagamentos com o número de registro 423 e sede registrada no endereço Rua Feliciano Castilho, N 218/220, Vila Alice, Luanda – Angola"
                    )}
                  </p>
                </div>
  
                <div className="col-xs-6 col-md-3">
                  <h6>{t("Contact Us")}</h6>
                  <ul className="footer-links">
                    <li>
                      <span>
                        <i className="fa fa-phone-square"></i> +244 944 090 618
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-envelope"></i> apoiocliente@bayqi.com
                      </span>
                    </li>
                  </ul>
                </div>
  
                <div className="col-xs-6 col-md-3">
                
                {open && <Register open={open} setOpen={setOpen}/>}
                  <h6>{t("Quick Links")}</h6>
                  <ul className="footer-links">
                    <li>
                      <Link  onClick={createAccount} to="/register">
                        <i className="fa fa-user-circle"></i>{" "}
                        {t("Create Account")} 
                      </Link>
                    </li>
                    <li>
                      <Link onClick={createAccount} to={"/"}>
                        <i className="fa fa-sign-in"></i> {t("Login")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"/"}>
                        <i className="fa fa-question-circle"></i> {t("Help")}
                      </Link>
                    </li> */}
                    <li>
                      {/* <Link onClick={handleLinkClick} to={"/"}>
                        <i className="fa fa-question-circle"></i> {t("Privacy Policy (PDF File)")}
                        
                        {t("Privacy Policy (PDF File)")}
                        
                      </Link> */}
                      <a href = {privacy} target = "_blank">{t("Privacy Policy")}</a>
                    </li>
                    <li>
                      {/* <Link to={"/"}>
                        <i className="fa fa-question-circle"></i> {t("Terms and Conditions ( PDF File)")}
                      </Link> */}
                      <a href = {terms} target = "_blank">{t("Terms and Conditions")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
            </div>
            <div className="footer-container-bottom">
              <div className="row footer-div">
                <div className="col-md-8 col-sm-6 col-xs-12">
                  <p className="copyright-text">
                  Copyright © 2023 BayQi. All rights reserved
                  </p>
                </div>
  
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <ul className="social-icons">
                    <li>
                      <a
                        className="facebook"
                        href="https://www.facebook.com/bayqi/"
                        target="blank"
                      >
                        {/* <i className="fa fa-facebook"></i> */}
                        <FiFacebook/>
                      </a>
                    </li>
                    <li>
                      <a
                        className="instagram"
                        href="https://www.instagram.com/oficialbayqi/?hl=pt"
                        target="blank"
                      >
                        {/* <i className="fa fa-instagram"></i> */}
                        <AiOutlineInstagram/>
                      </a>
                    </li>
                    <li>
                      <a
                        className="youtube"
                        href="https://www.youtube.com/channel/UCTkhgki5Zc3vRbUS3gnJIAg?view_as=subscriber"
                        target="blank"
                      >
                        {/* <i className="fa fa-youtube"></i> */}
                        <TfiYoutube/>
                      </a>
                    </li>
                    <li>
                      <a
                        className="whatsapp"
                        href="https://api.whatsapp.com/send/?phone=244939725027&text&type=phone_number&app_absent=0"
                        target="blank"
                      >
                        {/* <i className="fa fa-whatsapp"></i> */}
                        <BsWhatsapp/>
                        
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
}
