import React from "react";
import Router from "./App/Navigation/Router";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { useAppDispatch } from "./App/Redux/hooks";
import { signout } from "./App/Redux/auth/authSlice";
import { getSellerData } from "./api/sellers/sellers";

Amplify.configure(awsconfig);

function App({ history }) {
  const dispatch = useAppDispatch();

  const logOutUser = async () => {
    try {
      await Auth.signOut();
      dispatch(signout());
      localStorage.clear();
    } catch (error) {
      
    }
  };

  const checkSellerDisabled = async () => {
    const sellerId = localStorage.getItem("uid");

    if (!sellerId) {
      return false;
    }

    const response = await getSellerData();

    if (response.data.data[0]?.status !== "ACTIVE") {
      await logOutUser();
      return true;
    }
  };

  React.useEffect(() => {
    checkSellerDisabled();
  }, []);

  return <Router />;
}

export default App;
