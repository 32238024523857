import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../Helpers/helper";
import { updateTransaction } from "../../../api/orders/orders";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TransactionInvoice from "./TransactionInvoice";
import { useTranslation } from "react-i18next";

const TransactionDetailsModal = ({ item, toggle }) => {
  const { t, i18n } = useTranslation();
  const [deliveryCode, setDeliveryCode] = useState("");
  const [status, setStatus] = useState(item.orderStatusDetail.orderStatus);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ticketStatus, setTicketStatus] = useState(item.ticketStatus);

  const updateOrder = () => {
    const body = {};

    if (deliveryCode !== "") {
      body.deliveryCode = deliveryCode;
    }

    if (status !== item.orderStatusDetail.orderStatus) {
      body.status = status;
    }
    if(ticketStatus !== item.ticketStatus){
      body.ticketStatus=ticketStatus;
    }
   

    setLoading(true);
    updateTransaction(item, body)
      .then((res) => {
        setLoading(false);
        
        if (res.data.success === false) {
          setError(res.data.message);
        } else {
          toggle(true);
          setError("");
        }
      })
      .catch((err) => {
        
        setLoading(false);
      });
  };
  const customStyles = {
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "12px",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "12px",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "12px",
      };
    },
  };
  
  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{item.transactionId}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerPhone}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.shippingAddress ? item.shippingAddress : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Province")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0 .8rem" }}>
            {item.customerProvince ? item.customerProvince : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.totalPrice)} AKZ
          </span>
        </Col>
      </Row>
      {item.transactionType === "MARKET_PLACE" && item.refundAfter && (
          <Row>
            <Col md={6}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#333",
                  fontSize: "1rem",
                }}
              >
                {t("Estimated Delivery")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {new Date(item.refundAfter).toLocaleDateString()} {" - "}
                {new Date(item.refundAfter).toLocaleTimeString()}
              </span>
            </Col>
          </Row>
        )}

      {/* <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Estimated Delivery")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.totalPrice)} AKZ
          </span>
        </Col>
      </Row> */}
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
          {item?.orderStatusDetail=== "REFUND"
            ? item?.orderStatusDetail?.orderStatus
            : t(
              `${item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1).toLowerCase()}`
            )
          }
          </span>
        </Col>
      </Row>
      <Row>
        {/* <Col >
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ticket Status")}
          </span>
        </Col>
        <Col >
            <Select
              options={[
                { value: "USED", label: "Used" },
                { value: "UNUSED", label: "Unused" },
              ]}
              onChange={({ value }) => setTicketStatus(value === "USED" ? "USED" : "UNUSED")}
            />
           
          </Col> */}

<Row>
  <Col md={6}>
    <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
      {t("Ticket Status")}
    </span>
  </Col>
  <Col>
    {ticketStatus === "UNUSED" ? (
      <Select
        styles={customStyles}
        value={ticketStatus}
        options={[
          { value: "USED", label: "Used" },
          { value: "UNUSED", label: "Unused" }
        ]}
        onChange={({ value }) => setTicketStatus(value === "USED" ? "USED" : "UNUSED")}
      />
    ) : (
      <span>{ticketStatus}</span>
    )}
  </Col>
  {/* <Col md={2} className={{ padding: "0px" }}>
    {ticketStatus.value !== item.ticketStatus && (
      <button
        type="button"
        style={{
          backgroundColor: "#049EFF",
          color: "#fff",
          border: "none",
          fontSize: "12px",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: "bold",
        }}
        onClick={updateStatus}
      >
        {loading ? (
          <Spinner
            size="sm"
            style={{ color: "white" }}
            animation="border"
          />
        ) : (
          t("Update")
        )}
      </button>
    )}
  </Col> */}
          </Row>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "left" }}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ordered ")}
          </span>
        </Col>
        {item.products.map((prod, index) => {
          return (
            <Row key={index} style={{ justifyContent: "left" }}>
              <Col md={2}>
                <img
                  alt={""}
                  style={{ marginRight: "10px" }}
                  src={prod.image}
                  width={67}
                  height={67}
                />
              </Col>
              <Col md={6}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Name")}
                    </span>
                  </Col>
                  <Col>
                    <span>{prod.name}</span>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Col>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Delivery Name")}
                    </span>
                  </Col>
                  <Col>
                    {prod?.deliveryZone?.title}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Row>
      {item?.status === "REFUND" || item?.status === "COMPLETED" 
        ? ""      
        : <Row style={{ marginTop: "1%", justifyContent: "left" }}>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Delivery Code")}
          </span>
        </Col>
        <Col md={4}>
          <input
            className="inputField"
            style={{ width: "100%" }}
            value={deliveryCode}
            onChange={(e) => setDeliveryCode(e.target.value)}
            type="number"
            id="Code"
            maxLength="8"
          />
          {error !== "" && (
            <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
              *{t(`${error}`)}
            </p>
          )}
        </Col>
        </Row>
      }
      { item?.status === "REFUND" || item?.status === "COMPLETED" 
        ? ""
        : <Row>
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              // margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
          </Row>
      }
      {  item?.ticketStatus === "USED"
        ? ""
        : <Row>
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              // margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
          </Row>
      }
      <Row>
        <Col md={6}>
          <TransactionInvoice item={item} />
        </Col>
      </Row>
    </div>
  );
};

export default TransactionDetailsModal;
