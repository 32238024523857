import React from "react";
import "./StatsCard.scss";

const StatsCard = (props) => {
  return (
    <>
      <div className="statsCard">
        <h5 className="myh5"> {props.title}</h5>
        <span className="h2 font-weight-bold mb-0">
          {new Intl.NumberFormat().format(props.amount)}{props.currency? " AKZ" : ""}
        </span>
        {/* <p
          className="text-muted text-sm mt-3 mb-0"
          style={{ position: "absolute", bottom: "15px" }}
        >
          <span style={{ marginRight: "2px" }}>
            {props.increase ? (
              <AiOutlineArrowUp
                style={{
                  color: "#198754",
                  fontSize: "18px",
                  marginTop: "-5px",
                  stroke: "#198754",
                  strokeWidth: "40px",
                }}
              />
            ) : (
              <AiOutlineArrowDown
                style={{
                  fontSize: "18px",
                  marginTop: "-5px",
                  color: "#DC3545",
                  stroke: "#DC3545",
                  strokeWidth: "40px",
                }}
              />
            )}
          </span>
          <span
            style={{
              marginRight: '5px', color: props.increase ? "#198754" : "#DC3545"
            }}>
            {props.value}</span>
          <span className="text-nowrap">{props.when}</span>
        </p> */}
      </div>
    </>
  );
};

export default StatsCard;
